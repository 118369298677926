import { ExternalSource } from "src/gql/types";
import { CalendarSourceType } from "src/types/Calendar";

export function convertSourceType(sourceType: CalendarSourceType): ExternalSource {
    switch (sourceType) {
        case CalendarSourceType.GOOGLE:
            return ExternalSource.Google;
        case CalendarSourceType.APPLE:
            return ExternalSource.Apple;
    }
}