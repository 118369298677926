import React, { useState, useEffect } from "react";
import styles from "./MySettingsView.module.css";
import Layout from "../layout/Layout";
import composeIconBlack from "../../assets/icons/composeIconBlack.svg";
import CalendarModificationScreen, { CalendarAuthParams } from "../calendar/CalendarModificationScreen";

type MySettingsViewProps = {
    onBack: () => void;
    calendarAuthParams?: CalendarAuthParams;
    clearCalendarAuthParams?: () => void;
};

const MySettingsView: React.FC<MySettingsViewProps> = ({ onBack, calendarAuthParams, clearCalendarAuthParams }) => {
    const [showMainSettingsView, setShowMainSettingsView] = useState<boolean>(true);
    const [showCalendarChangeView, setShowCalendarChangeView] = useState<boolean>(false);

    useEffect(() => {
        if (calendarAuthParams) {
            setShowCalendarChangeView(true);
            setShowMainSettingsView(false);
        }
    }, [calendarAuthParams])

    const handleShowCalendarView = () => {
        setShowMainSettingsView(false);
        setShowCalendarChangeView(true);
    }

    const handleHideShowCalendarView = () => {
        setShowMainSettingsView(true);
        setShowCalendarChangeView(false);
    }

    return (
        <>
            {showMainSettingsView && <Layout
                navbarConfig={{ title: "My Settings", onBack }}
            >
                <div className={styles.settingsContainer}>
                    <div className={styles.settingsRow}>
                        <div className={styles.settingName}>
                            Calendar Sync
                        </div>
                        <img src={composeIconBlack} className={styles.composeIcon} onClick={handleShowCalendarView} />
                    </div>
                </div>
            </Layout>
            }
            {showCalendarChangeView &&
                <CalendarModificationScreen
                    onContinue={handleHideShowCalendarView}
                    calendarAuthParams={calendarAuthParams}
                    clearCalendarAuthParams={clearCalendarAuthParams}
                />}
        </>
    );
};

export default MySettingsView;
