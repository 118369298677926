import React, { useState, useEffect } from "react";
import styles from "./NameInputContainer.module.css"; // Ensure styles are imported
import ErrorUnit from "./ErrorUnit";
import TextInputField, { TextInputFieldStyle, TextInputFieldType } from "./TextInputField";

interface NameInputContainerProps {
    firstName: string,
    lastName: string,
    firstNameSetter: (value: string) => void;
    lastNameSetter: (value: string) => void;
    onNameValidChange: (isValid: boolean) => void;
    style?: TextInputFieldStyle;
}

const NameInputContainer: React.FC<NameInputContainerProps> = ({
    firstName,
    lastName,
    firstNameSetter,
    lastNameSetter,
    onNameValidChange,
    style = TextInputFieldStyle.DEFAULT,
}) => {
    const [hasEnteredName, setHasEnteredName] = useState<boolean>(false);
    const [nameFocused, setNameFocused] = useState<boolean>(false);
    const [isValidFields, setIsValidFields] = useState({
        firstName: false,
        lastName: false,
    });

    useEffect(() => {
        onNameValidChange(Object.values(isValidFields).every(Boolean));
    }, [isValidFields]);


    return (
        <div className={styles.nameFields}>
            <div className={styles.nameFieldInputs}>
                <TextInputField
                    type={TextInputFieldType.NAME}
                    placeholder="First name"
                    style={style}
                    value={firstName}
                    onChange={(value) => {
                        setHasEnteredName(true);
                        firstNameSetter(value);
                    }}
                    onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, firstName: isValid }))}
                    disableError={true}
                    setElementIsFocused={setNameFocused}
                />
                <TextInputField
                    type={TextInputFieldType.NAME}
                    placeholder="Last name"
                    style={style}
                    value={lastName}
                    onChange={(value) => {
                        setHasEnteredName(true);
                        lastNameSetter(value);
                    }}
                    onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, lastName: isValid }))}
                    disableError={true}
                    setElementIsFocused={setNameFocused}
                />
            </div>
            <ErrorUnit
                errorStr={
                    hasEnteredName && (
                        !isValidFields["firstName"] || !isValidFields["lastName"])
                        ? "Please enter your full name, with more than 2 characters each."
                        : null
                }
                isFocused={nameFocused}
            />
        </div>
    );
}

export default NameInputContainer;