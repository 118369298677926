import React, { useState, useEffect, useCallback } from "react";
import CaretakerRequestConfirmationView from "../events/CaretakerRequestConfirmationView";
import { DigestItem, User, Event as DigestEventType, useCreateCaretakerRequestMutation, CaretakerRequest, CaretakerDetailsInput, Digest } from "../../gql/types";
import { useDigestView } from "./DigestViewContext";
import { CaretakerRequestDetails } from "../events/types";
import { useShareWithOverlay, ShareStatus } from "../action/share/shareContentWithOverlay";
import { getHostUrl } from "src/platform/host";
import { convertToISO8601 } from "src/time/convert";
import { updateDigestWithNewEvent } from "./DigestUpdater";


const DigestViewCaretakerRequestConfirmationViewWrapper: React.FC<{ user: User, digest: Digest | null, setDigest: (digest: Digest) => void }> = ({ user, digest, setDigest }) => {
    const { caretakerConfirmationConfig, hideCaretakerConfirmation } = useDigestView();
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const { shareContentWithOverlay, showOverlayWithSpinner, OverlayComponent } = useShareWithOverlay();

    const [createCaretakerRequestMutation] = useCreateCaretakerRequestMutation();

    const createCaretakerRequest = useCallback(
        async (requestDetails: CaretakerRequestDetails, item?: DigestItem, event?: DigestEventType): Promise<CaretakerRequest | null> => {
            const { selectedCaretaker, startDate, endDate, timezone, message } = requestDetails;

            const updatedStartDate = convertToISO8601(startDate, timezone)
            const updatedEndDate = convertToISO8601(endDate, timezone)

            const caretakerInput = {
                contactInfo: selectedCaretaker.contactInfo,
                name: selectedCaretaker.name,
                userID: selectedCaretaker.userID,
            } as CaretakerDetailsInput;

            try {
                const variables = {
                    input: {
                        caretakers: [caretakerInput],
                        timeRange: {
                            startDate: updatedStartDate,
                            endDate: updatedEndDate,
                        },
                        message,
                        requestContext: item && event ? {
                            contextHeader: {
                                topTitle: item.itemHeader?.topTitle,
                                mainTitle: item.itemHeader?.mainTitle,
                                description: item.itemHeader?.description,
                                attentionLevel: item.attentionLevel,
                            },
                            eventDetails: {
                                title: event.title,
                                eventID: event.eventID,
                                description: event.description,
                                startDate: event.startDate,
                                endDate: event.endDate,
                            },
                        }
                            : undefined,
                    },
                };

                console.log('Mutation Variables:', JSON.stringify(variables, null, 2));

                const { data } = await createCaretakerRequestMutation({
                    variables: variables,
                });

                if (data?.createCaretakerRequest && event && digest) {
                    const updatedEvent = {
                        ...event,
                        caretakerRequests: [data.createCaretakerRequest],
                    }
                    const updatedDigest = updateDigestWithNewEvent(digest, updatedEvent)
                    setDigest(updatedDigest);
                }

                if (data?.createCaretakerRequest) {
                    return data.createCaretakerRequest;
                }
            } catch (error) {
                console.error('Failed to create caretaker request:', error);
            }

            return null;
        },
        [createCaretakerRequestMutation],
    );


    useEffect(() => {
        setIsVisible(caretakerConfirmationConfig.isVisible);
    }, [caretakerConfirmationConfig.isVisible]);

    const handleHideRequest = () => {
        hideCaretakerConfirmation(true);
    };

    const handleRequestConfirm = async (requestDetails: CaretakerRequestDetails, item?: DigestItem, event?: DigestEventType) => {
        showOverlayWithSpinner("");
        let shareStatus = ShareStatus.STARTED;
        let err: string | undefined | null = null;
        try {
            const response = await createCaretakerRequest(requestDetails, item, event);
            if (response) {
                console.log("Caretaker request created successfully:", response);
                const shareDetails = {
                    title: response.message ?? "Could you take care of my child at this time?",
                    text: response.message ?? "Could you take care of my child at this time?",
                    url: getHostUrl() + `/r/v/${response.accessToken}`,
                    dialogTitle: "Share Caretaker Request",
                };
                const { status, errorMessage } = await shareContentWithOverlay(shareDetails);
                shareStatus = status;
                err = errorMessage;
                if (err && err.length > 0) {
                    console.error(err);
                }

                // if (caretakerConfirmationConfig.onClose) {
                //     caretakerConfirmationConfig.onClose(response, event);
                // }
                // Additional success handling
            } else {
                console.warn("Caretaker request creation returned null. Check the input or server.");
            }
        } catch (error) {
            console.error("Error creating caretaker request:", error);
            // Error handling logic
        } finally {
            hideCaretakerConfirmation(false); // Close the confirmation modal
        }
    };

    return (
        <>
            {isVisible &&
                caretakerConfirmationConfig.event &&
                caretakerConfirmationConfig.item &&
                caretakerConfirmationConfig.caretakerRequestDetails && <CaretakerRequestConfirmationView
                    event={caretakerConfirmationConfig.event}
                    digestItem={caretakerConfirmationConfig.item}
                    user={user}
                    onBack={handleHideRequest}
                    requestDetails={caretakerConfirmationConfig.caretakerRequestDetails}
                    onConfirm={handleRequestConfirm}
                />}
        </>
    );
};

export default DigestViewCaretakerRequestConfirmationViewWrapper;
