import React, { useEffect, useState } from "react";
import styles from "./ExistingLoginScreen.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../layout/Layout";
import ActionButton from "../button/ActionButton";
import { applyActionCode } from "firebase/auth";
import { auth } from "src/config/firebase";
import { isNative } from "src/platform/platform";

const EmailVerified: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState(false);
    const [title, setTitle] = useState<string>("Verifying...");

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const oobCode = params.get("oobCode"); // Firebase verification code
        const mode = params.get("mode"); // Should be 'verifyEmail'

        if (!oobCode || mode !== "verifyEmail") {
            setError("Invalid verification link or already verified.");
            setTitle("Verification Failed");
            setLoading(false);
            return;
        }

        // Apply the email verification action code

        applyActionCode(auth, oobCode)
            .then(() => {
                setSuccess(true);
                setLoading(false);
                setTitle("Verification Successful");
            })
            .catch((err) => {
                console.error("Email verification error:", err);
                setError("Verification failed or link expired.");
                setTitle("Verification Failed");
                setLoading(false);
            });
    }, [location.search]);

    const handleRedirect = () => {
        if (isNative()) {
            navigate("/auth/login");
            return;
        }
        window.location.href = "https://plii.app/auth/login"
    };

    return (
        <Layout
            navbarConfig={{ title: title, onBack: () => navigate("/auth/login") }}
        >
            <div className={styles.verifyEmailContainer}>
                <div className={styles.instructions}>
                    {!loading && success &&
                        <>
                            You can now sign in with your new account.
                        </>
                    }
                    {!loading && !success &&
                        <>
                            {error}
                        </>
                    }
                    {loading &&
                        <>
                            Please wait...
                        </>}
                </div>
                <ActionButton onClick={handleRedirect} className={styles.formLoginButton} disabled={loading}>
                    {loading ? "Verifying..." : "Back to Log In"}
                </ActionButton>
            </div>
        </Layout>
    );
};

export default EmailVerified;
