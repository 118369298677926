import { SyncType, TaskStatusType } from "../gql/types"; // Adjust path as needed

export const generateMockSyncStatus = (syncType: SyncType) => {
    // const randomStatus =
    //     Object.values(TaskStatusType)[Math.floor(Math.random() * Object.keys(TaskStatusType).length)];

    const now = new Date();
    const createdAt = new Date(now.getTime() - Math.floor(Math.random() * 60000)).toISOString(); // Created within the last minute
    const completedAt = Math.random() > 0.5 ? new Date(now.getTime() - Math.floor(Math.random() * 30000)).toISOString() : createdAt; // Sometimes equal to createdAt

    return {
        __typename: "SyncStatus",
        type: syncType,
        status: TaskStatusType.NotScheduled,
        createdAt,
        completedAt,
        syncRange: Math.random() > 0.5
            ? {
                __typename: "SyncRange",
                start: new Date(now.getTime() - 86400000).toISOString(), // 1 day ago
                end: now.toISOString(),
            }
            : null, // Sometimes syncRange is null
    };
};