import React, { useState, useEffect } from "react";
import styles from "./TextInpuField.module.css"; // Ensure styles are imported
import ErrorUnit from "./ErrorUnit";

export enum TextInputFieldType {
    NAME,
    EMAIL,
    PASSWORD,
}

export enum TextInputFieldStyle {
    DEFAULT,
    LOGIN,
}

interface TextInputFieldProps {
    type: TextInputFieldType;
    placeholder: string;
    value: string;
    className?: string;
    onChange: (value: string) => void;
    onValidChange?: (isValid: boolean) => void;
    errorStr?: string;
    allowOnlyAlphabets?: boolean;
    style?: TextInputFieldStyle;
    disableError?: boolean;
    setElementIsFocused?: (isFocused: boolean) => void;
}

const TextInputField: React.FC<TextInputFieldProps> = ({
    type,
    placeholder,
    value,
    onChange,
    onValidChange,
    errorStr,
    className,
    allowOnlyAlphabets,
    disableError,
    setElementIsFocused,
    style = TextInputFieldStyle.DEFAULT,
}) => {
    const [isFocused, setIsFocused] = useState(false);
    const [internalError, setInternalError] = useState<string | null>(null);

    const validateInput = (input: string) => {
        switch (type) {
            case TextInputFieldType.NAME:
                return input.length > 1 ? null : "Must be at least 2 characters.";
            case TextInputFieldType.EMAIL:
                return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(input) ? null : "Invalid email format.";
            case TextInputFieldType.PASSWORD:
                return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(input)
                    ? null
                    : "Must be 8+ characters, including a number & a special character.";
            default:
                return null;
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;

        if (allowOnlyAlphabets || type == TextInputFieldType.NAME) {
            newValue = newValue.replace(/[^a-zA-Z\s]/g, ""); // 🔥 Allow only letters & spaces
        }

        onChange(newValue);
        setInternalError(validateInput(newValue))
    };

    useEffect(() => {
        const computedError = errorStr || validateInput(value);
        if (value.length > 0) {
            setInternalError(computedError);
        }
        onValidChange?.(!computedError); // 🔥 Notify parent about validity
    }, [value, errorStr]);

    const styleClassName = style == TextInputFieldStyle.LOGIN ? styles.loginInput : styles.inputField;
    const errorStyleName = style == TextInputFieldStyle.LOGIN ? styles.loginInputError : styles.inputError;
    let inputType = "text";

    switch (type) {
        case TextInputFieldType.NAME:
            inputType = "text";
            break;
        case TextInputFieldType.EMAIL:
            inputType = "email";
            break;
        case TextInputFieldType.PASSWORD:
            inputType = "password";
            break;
    }

    return (
        <div className={styles.inputWrapper}>
            <input
                type={inputType}
                placeholder={placeholder}
                value={value}
                onChange={handleChange}
                onFocus={() => { setIsFocused(true); if (setElementIsFocused) { setElementIsFocused(true); } }}
                onBlur={() => { setIsFocused(false); if (setElementIsFocused) { setElementIsFocused(false); } }}
                spellCheck={false}
                className={`${styleClassName} ${internalError && !isFocused ? errorStyleName : ""} ${className}`}
            />
            {!disableError && <ErrorUnit isFocused={isFocused} errorStr={internalError} />}
        </div>
    );
};

export default TextInputField;
