import React, { useState, useEffect } from 'react';
import OnboardingStep, { OnboardingContext } from './OnboardingStep';
import ProfilePictureUpload, { FlowType } from '../profile/ProfilePictureUpload';
import { useNavigate } from 'react-router-dom';
import { fetchWithAuth } from '../../request/backend';

interface ProfilePictureStepProps {
    onboardingContext: OnboardingContext;
    onNext: (profileURL: string) => void;
    onSkip?: () => void;
    existingValue?: string;
}

const ProfilePictureStep: React.FC<ProfilePictureStepProps> = ({
    onboardingContext,
    onNext,
    onSkip,
    existingValue,
}) => {
    const navigate = useNavigate();
    const [dataURL, setDataURL] = useState<string>('');
    const [profilePictureURL, setProfilePictureURL] = useState<string>(existingValue || '');
    const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state

    // Update button state whenever count changes
    useEffect(() => {
        setButtonEnabled(profilePictureURL != '' || dataURL != ''); // Ensure button is enabled only for valid counts
    }, [profilePictureURL, dataURL]);


    const handleContinue = async () => {
        let updatedProfileURL: string | null = null;
        try {
            updatedProfileURL = await handleComplete(dataURL);
        } catch (err: any) {
            console.error("An error occurred:", err); // Detailed error logging
            const errorMsg = `Upload failed: ${err.message}`;
            navigate(`?error=true&message=${encodeURIComponent(errorMsg)}`);
        } finally {
            console.log("Function `handleComplete` finished."); // Log exit point
        }
        if (!updatedProfileURL) {
            return;
        }
        onNext(updatedProfileURL);
    };

    const handleComplete = async (croppedImage: string | null): Promise<string> => {
        // User chose to move forward with the existing picture
        if (dataURL === '' && profilePictureURL !== '') {
            return profilePictureURL;
        }
        console.log("Function `handleComplete` called."); // Log entry point
        if (!croppedImage) {
            console.error("No image provided. Redirecting with error message.");
            throw new Error('No image provided. Please upload an image.');
        }

        if (!croppedImage.startsWith('data:image/')) {
            console.error('Unexpected format detected. Input:', croppedImage);
            throw new Error('Invalid file.');
        }

        const base64Index = croppedImage.indexOf(';base64,');
        console.log("Base64 index found at:", base64Index); // Debug the index
        if (base64Index === -1) {
            console.error('Base64 marker not found in the Data URL:', croppedImage);
            throw new Error('Invalid file.');
        }

        const mimeType = croppedImage.substring(5, base64Index);
        console.log("Extracted MIME type:", mimeType); // Log extracted MIME type

        const base64Data = croppedImage.split('base64,')[1];
        if (!base64Data) {
            console.error('Base64 data missing in the Data URL:', croppedImage);
            throw new Error('Invalid file.');
        }
        console.log("Base64 data extracted successfully. Length:", base64Data.length);

        // Convert base64 to binary data
        console.log("Converting base64 data to binary data...");
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Uint8Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        console.log("Binary data conversion complete.");

        // Create Blob
        const blob = new Blob([byteNumbers], { type: mimeType });
        console.log("Blob created. Blob size:", blob.size, "MIME type:", blob.type);

        // Prepare FormData
        const formData = new FormData();
        formData.append('file', blob, 'profile-picture.jpg'); // Use a meaningful filename
        console.log("FormData prepared with file.");

        // Send the image to the backend
        console.log("Sending image to backend...");
        const response = await fetchWithAuth('/api/upload/image/profile', {
            method: 'POST',
            body: formData,
        });

        console.log("Upload response received. Status:", response.status);
        if (!response.ok) {
            const message = await response.text();
            console.error("Error response from server:", message);
            throw new Error(message || 'Upload failed');
        }

        const { url } = await response.json();
        console.log('Uploaded profile picture URL:', url);
        setProfilePictureURL(url);
        return url;
    };

    const handleSetDataURL = (newDataURL: string | null) => {
        if (!newDataURL) {
            setDataURL('');
            return;
        }
        setDataURL(newDataURL);
    }

    return (
        <OnboardingStep
            onboardingContext={onboardingContext}
            onContinue={handleContinue}
            title="Would you like to upload your profile picture? Plii works best when it feels face-to-face!"
            buttonEnabled={buttonEnabled}
            subtitle=""
            onSkip={onSkip}
        >
            <ProfilePictureUpload
                onComplete={handleSetDataURL}
                flowType={FlowType.Onboarding}
                buttonText="Take a Photo"
                initialImage={existingValue}
            />
        </OnboardingStep>
    );
};

export default ProfilePictureStep;
