import React from "react";
import styles from "./ProfileMenuRow.module.css";
import noUser from "../../assets/no-user.png";
import moreIcon from "../../assets/icons/moreIcon.svg";

type ProfileMenuRowProps = {
    profilePictureUrl?: string; // Optional profile picture URL
    name: string; // Display name
    altText?: string; // Alt text for the image
    onClick?: () => void;
};

const ProfileMenuRow: React.FC<ProfileMenuRowProps> = ({ profilePictureUrl, name, altText, onClick }) => {
    return (
        <div className={styles.profileMenuRowContainer} onClick={onClick}>
            <div className={styles.leftContainer}>
                <img
                    src={profilePictureUrl || noUser}
                    alt={altText || name}
                    className={styles.profilePicture}
                />
                <div className={styles.name}>{name}</div>
            </div>
            <div className={styles.rightContainer}>
                <img src={moreIcon} />
            </div>
        </div>
    );
};

export default ProfileMenuRow;
