import React from "react";
import styles from "./Spinner.module.css"; // Ensure correct path

interface SpinnerProps {
    size?: number;
    className?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = 24, className = '' }) => {
    return (
        <div
            style={{
                width: size,
                height: size,
            }}
            className={`${styles.spinner} ${className}`} // Apply the CSS class
        />
    );
};

export default Spinner;
