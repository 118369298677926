import React, { useState } from "react";
import styles from "./DigestListView.module.css";
import { DigestSectionType as SectionType, DigestSection as DigestSectionType, AttentionLevelType, Digest } from "../../gql/types";
import DigestCard from "./DigestCard";
import { useDigestView } from "./DigestViewContext";

interface DigestListViewProps {
    digest: Digest | null;
}

const DigestListView: React.FC<DigestListViewProps> = ({ digest }) => {
    // Find the EVENTS section
    const eventsSection: DigestSectionType = digest
        ? digest.sections.find(
            section => section.sectionType === SectionType.Events
        ) || { sectionType: SectionType.Events, items: [], sectionTitle: "" }
        : { sectionType: SectionType.Events, items: [], sectionTitle: "" };

    // State for filtering
    const [filter, setFilter] = useState<AttentionLevelType | null>(null);

    // Filtered items based on the selected filter
    const filteredItems = filter
        ? eventsSection.items.filter(item => item.attentionLevel === filter)
        : eventsSection.items;

    return (
        <div className={styles.digestListView}>
            {/* Filter Buttons */}
            <div className={styles.filterContainer}>
                <button
                    className={`${styles.filterButton} ${filter === null ? styles.activeFilter : ""}`}
                    onClick={() => setFilter(null)}
                >
                    All
                </button>
                <button
                    className={`${styles.filterButton} ${filter === AttentionLevelType.Informational ? styles.activeFilter : ""}`}
                    onClick={() => setFilter(AttentionLevelType.Informational)}
                >
                    Regular
                </button>
                <button
                    className={`${styles.filterButton} ${filter === AttentionLevelType.ActionRequired ? styles.activeFilter : ""}`}
                    onClick={() => setFilter(AttentionLevelType.ActionRequired)}
                >
                    Action Required
                </button>
            </div>

            <div className={styles.digestListViewContent}>
                {filteredItems.map((item, index) => (
                    <div key={index} className={styles.listItem}>
                        <DigestCard item={item} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default DigestListView;
