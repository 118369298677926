import { ActionType } from '../action/types'; // Adjust import based on your setup

type AdditionalContext = {
    UserAction?: {
        [eventID: string]: string[];
    };
};

/**
 * Sanitizes the UserAction map by converting string action types to ActionType enum values.
 * @param context - The AdditionalContext containing UserAction.
 * @returns A sanitized map of event IDs to arrays of ActionType values.
 */
export const sanitizeActionTypes = (context: AdditionalContext): { [eventID: string]: ActionType[] } => {
    const userActionMap = context.UserAction || {};

    return Object.entries(userActionMap).reduce((acc, [eventID, actions]) => {
        const sanitizedActions = actions
            .map((action) => {
                const normalizedAction = action.toUpperCase(); // Normalize to uppercase
                return Object.values(ActionType).includes(normalizedAction as ActionType)
                    ? (normalizedAction as ActionType)
                    : ActionType.INVALID;
            })
            .filter((action) => action !== ActionType.INVALID); // Filter out invalid actions

        if (sanitizedActions.length > 0) {
            acc[eventID] = sanitizedActions;
        }

        return acc;
    }, {} as { [eventID: string]: ActionType[] });
};