import React, { useState, useEffect } from 'react';
import styles from './CalendarComponents.module.css';
import { CalendarSourceType } from '../../types/Calendar';
import { CalendarPlugin } from 'calendar-plugin';
import { useAddExternalCalendarsMutation, useGetCalendarConnectionsQuery, useGetCalendarsListQuery } from 'src/gql/types';
import Layout from '../layout/Layout';
import ActionButton from '../button/ActionButton';
import { convertSourceType } from './convertSourceType';

interface CalendarListSelectionStepProps {
    source: CalendarSourceType;
    account: string;
    label?: string;
    onNext: () => void;
}

const calendarTypes = [
    { value: 'personal', label: 'Personal' },
    { value: 'work', label: 'Work' },
    { value: 'family', label: 'Family' },
];

const CalendarListSelectionView: React.FC<CalendarListSelectionStepProps> = ({
    source,
    account,
    label,
    onNext,
}) => {
    const [calendars, setCalendars] = useState<any[]>([]);
    const [selectedCalendars, setSelectedCalendars] = useState<{ id: string; type: string }[]>([]);
    const [buttonEnabled, setButtonEnabled] = useState(false);

    // Fetch calendars for the account or use mock data
    const { loading, error, data } = useGetCalendarsListQuery({
        skip: source == CalendarSourceType.APPLE,
        variables: {
            calendar: {
                source: convertSourceType(source),
                identifier: account,
            },
        },
        fetchPolicy: 'network-only',
    });

    // Fetch calendars for the account or use mock data
    const { loading: userCalendarsLoading, error: userCalendarsError, data: userCalendarsData } = useGetCalendarConnectionsQuery(
        {
            skip: source !== CalendarSourceType.APPLE,
            fetchPolicy: 'network-only',
        });

    const [addExternalCalendars, { loading: savingCalendars }] = useAddExternalCalendarsMutation({
        onCompleted: () => {
            console.log('Calendars saved successfully.');
            onNext(); // Proceed to the next step
        },
        onError: (err) => {
            console.error('Failed to save calendars:', err);
        },
    });

    useEffect(() => {
        const fetchCalendars = async () => {
            if (source === 'Apple') {
                try {
                    const response = await CalendarPlugin.getCalendars();
                    console.log('Fetched Apple calendars:', response.calendars);
                    if (userCalendarsData?.getCalendarConnections) {
                        type CalendarConnection = {
                            identifier: string; // Ensure this matches your GraphQL query result type
                        };

                        // Extract synced accounts from the connected calendars
                        const syncedAccounts = userCalendarsData.getCalendarConnections.map(
                            (connection: CalendarConnection) => connection.identifier
                        );

                        // Filter out Apple calendars with matching inferred emails
                        const filteredCalendars = response.calendars.filter((calendar: any) => {
                            // Check if the calendar has an inferred email that matches a synced email
                            const inferredEmail = calendar.inferredEmail; // Ensure this field is populated in `CalendarPlugin`
                            return !inferredEmail || !syncedAccounts.includes(inferredEmail);
                        });

                        setCalendars(
                            filteredCalendars.map((calendar: any) => ({
                                id: calendar.id,
                                name: calendar.sourceType === 'Local'
                                    ? `Device - ${calendar.title || 'Unnamed Calendar'}`
                                    : calendar.title || 'Unnamed Calendar',
                            }))
                        );
                    } else {
                        // If no connected calendars, just map all Apple calendars
                        setCalendars(
                            response.calendars.map((calendar: any) => ({
                                id: calendar.id,
                                name: calendar.title,
                            }))
                        );
                    }
                } catch (error) {
                    console.error('Error fetching device calendars:', error);
                }
            } else if (data?.getUserExternalCalendarList) {
                console.log('Fetched external calendars:', data.getUserExternalCalendarList);
                setCalendars(data.getUserExternalCalendarList);
            }
        };

        fetchCalendars();
    }, [data, userCalendarsData, source]);

    useEffect(() => {
        if (error) {
            console.error('Error fetching external calendar list:', error);
            if (error.networkError) console.error('Network Error Details:', error.networkError);
            error.graphQLErrors?.forEach((graphQLError, index) =>
                console.error(`GraphQL Error ${index + 1}:`, graphQLError.message)
            );
        }
    }, [error]);

    useEffect(() => {
        setButtonEnabled(selectedCalendars.some((calendar) => calendar.type));
    }, [selectedCalendars]);

    const handleCalendarSelection = (calendarId: string, checked: boolean) => {
        setSelectedCalendars((prev) => {
            if (checked) {
                const updatedSelections = [...prev, { id: calendarId, type: 'personal' }];
                setButtonEnabled(true); // Enable the button immediately after selection
                return updatedSelections;
            }
            const updatedSelections = prev.filter((calendar) => calendar.id !== calendarId);
            setButtonEnabled(updatedSelections.some((calendar) => calendar.type)); // Update button state
            return updatedSelections;
        });
    };

    const handleTypeChange = (calendarId: string, type: string) => {
        setSelectedCalendars((prev) =>
            prev.map((calendar) =>
                calendar.id === calendarId ? { ...calendar, type } : calendar
            )
        );
    };

    const handleSave = async () => {
        try {
            const calendarInput = {
                source: convertSourceType(source),
                identifier: account,
                label: label ? label : '',
            };
            await addExternalCalendars({
                variables: {
                    calendar: calendarInput,
                    entries: selectedCalendars,
                },
            });
        } catch (err) {
            console.error('Error saving calendars:', err);
        }
    };

    const accountName = label ? label : account;

    return (
        <Layout
            navbarConfig={{ title: "Calendar Selection" }}
            footer={
                <ActionButton
                    onClick={handleSave}
                    disabled={!buttonEnabled}
                    hasDataLoad={true}
                >
                    Confirm
                </ActionButton>
            }
        // title={`Choose calendars for ${accountName} to sync and categorize them.`}
        // subtitle=""
        // onboardingContext={onboardingContext}
        // buttonEnabled={buttonEnabled}
        // onContinue={handleSave}
        // onSkip={onSkip}
        >
            <div className={styles.instructions}>
                {loading && <>Loading calendars...</>}
                {error && <>Error loading calendars. Please try again.</>}
                {!loading && !error && <>
                    Choose calendars for {accountName} to sync and categorize them.
                </>}
            </div>
            {!loading && !error && (
                <div className={styles.container}>
                    <div className={styles.scrollableContent}>
                        {calendars.map((calendar, index) => {
                            const isSelected = selectedCalendars.some((c) => c.id === calendar.id);
                            const selectedType =
                                selectedCalendars.find((c) => c.id === calendar.id)?.type || '';
                            // Check if this is the last row
                            const isLastRow = index === calendars.length - 1;

                            return (
                                <div key={calendar.id} className={`${styles.calendarRow} ${isLastRow ? styles.lastRow : ''}`}>
                                    <div className={styles.calendarInfo}>
                                        <label className={styles.checkboxLabel}>
                                            <input
                                                type="checkbox"
                                                className={styles.checkboxInput}
                                                checked={isSelected}
                                                onChange={(e) =>
                                                    handleCalendarSelection(calendar.id, e.target.checked)
                                                }
                                            />
                                            {calendar.name}
                                        </label>
                                    </div>
                                    {isSelected && (
                                        <select
                                            className={styles.typeDropdown}
                                            value={selectedType}
                                            onChange={(e) =>
                                                handleTypeChange(calendar.id, e.target.value)
                                            }
                                        >
                                            {calendarTypes.map((type) => (
                                                <option key={type.value} value={type.value}>
                                                    {type.label}
                                                </option>
                                            ))}
                                        </select>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default CalendarListSelectionView;
