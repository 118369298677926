import React from "react";
import CaretakerSelectionActionSheet from "./CaretakerSelectionActionSheet";
import { ActionType } from "./types";
import { CaretakerDetails } from "../../gql/types";
import NavigationActionSheet from "./NavigationActionSheet";

// Define preload data types for each action type
type PreloadData = {
    caretakers?: {
        loader?: () => Promise<CaretakerDetails[]>; // Lazy loader for caretakers
        onAddCaretaker?: (name: string, contactInfo: string) => Promise<CaretakerDetails | null>;
    };
    navigationOptions?: ActionType[];
    // Add other preload data types here as needed
};

// Define additional data types based on action type
export type ActionSheetData = {
    [ActionType.CARETAKER]: CaretakerDetails | undefined;
    [ActionType.CARETAKER_VIEW]: undefined;
    [ActionType.INVALID]: undefined;
    [ActionType.NAVIGATION]: undefined;
    // Add other action types and their data as needed
};

type ActionSheetFactoryProps<T extends keyof ActionSheetData> = {
    actionType: T; // Array of action types to render
    isVisible: boolean;
    onClose: () => void;
    setActionType: (actionType: T) => void;
    onSelect: (actionType: T, actionValue: string, additionalData: ActionSheetData[T]) => void;
    preloadData?: PreloadData;
};


const ActionSheetFactory = <T extends keyof ActionSheetData>({
    actionType,
    isVisible,
    onClose,
    onSelect,
    setActionType,
    preloadData,
}: ActionSheetFactoryProps<T>) => {
    if (!isVisible || actionType === ActionType.INVALID) return null;

    switch (actionType) {
        case ActionType.CARETAKER:
            return (
                <CaretakerSelectionActionSheet
                    key={actionType}
                    isVisible={true}
                    onClose={() => onClose()}
                    onSelect={(value, additionalData) => onSelect(actionType, value, additionalData as ActionSheetData[T])}
                    preloadData={preloadData?.caretakers}
                />
            );
        case ActionType.NAVIGATION:
            return (
                <NavigationActionSheet
                    isVisible={true}
                    onClose={onClose}
                    preloadData={{
                        navigationOptions: preloadData?.navigationOptions,
                    }}
                    onNavigate={(nextActionType) => setActionType(nextActionType as T)}
                />
            );
        default:
            return null;
    }
};

export default ActionSheetFactory;
