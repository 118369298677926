import React from "react";
import Select, { SingleValue } from "react-select";
import styles from "./ActionSheetDropdown.module.css";
import { StylesConfig } from "react-select";
import noUser from "../../assets/no-user.png";

export interface DropdownOption {
    value: string; // Unique ID
    label: string; // Display name
    profilePictureUrl?: string; // Optional profile picture
    topDivider?: boolean; // should have distinction from top
    icon?: string; // Optional icon, use this or profilePicture, not both
}

export type ActionSheetDropdownProps = {
    options: DropdownOption[];
    value: string | null;
    placeholder?: string;
    onChange: (selectedValue: string) => void;
};

const dropdownStyles: StylesConfig<DropdownOption, false> = {
    control: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? "#2F1C0A" : "#FBEFDA",
        border: "1.5px solid #2F1C0A",
        borderRadius: "3rem",
        outline: "none",
        width: "100%",
        boxShadow: "none",
        padding: "0.5rem 0.5rem",
    }),
    input: (base) => ({
        ...base,
        color: "#2F1C0A",
        caretColor: "transparent",
    }),
    singleValue: (base) => ({
        ...base,
        display: "flex",
        alignItems: "center",
        gap: "8px",
        color: "#2F1C0A",
        fontWeight: "bold",
    }),
    placeholder: (base, state) => ({
        ...base,
        color: state.isFocused ? "#FBEFDA" : "#2F1C0A",
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        display: "flex",
        alignItems: "center",
        gap: "8px",
        backgroundColor: isSelected
            ? "#2F1C0A"
            : isFocused
                ? "#fff"
                : "#fff", // Apply only one state at a time
        color: isSelected
            ? "#fff"
            : isFocused
                ? "#2F1C0A"
                : "#2F1C0A", // Ensure color consistency
        padding: "0",
        cursor: "pointer",
    }),
    menu: (base) => ({
        ...base,
        width: "100%",
        borderRadius: "0.5rem",
        overflow: "hidden",
        zIndex: 9999, // Ensure it appears above other elements
    }),
    menuList: (base) => ({
        ...base,
        maxHeight: "150px", // Ensures the content scrolls within the dropdown
        overflowY: "auto", // Adds vertical scrolling when needed
    }),
    valueContainer: (base) => ({
        ...base,
        padding: "0",
    }),
};

const CustomSingleValue: React.FC<{ data: DropdownOption }> = ({ data }) => {
    return (
        <div className={`${styles.optionRow} ${data.topDivider ? styles.topDivider : ""}`}>
            {data.icon && <img src={data.icon} className={styles.icon} />}
            {data.profilePictureUrl && data.profilePictureUrl.length > 0 && <img src={data.profilePictureUrl} alt={data.label} className={styles.profilePicture} />}
            <span className={styles.name}>{data.label}</span>
        </div>);
};

const ActionSheetDropdown: React.FC<ActionSheetDropdownProps> = ({
    options,
    value,
    placeholder = "Select...",
    onChange,
}) => {
    return (
        <Select
            options={options}
            classNamePrefix={"actionDropdown"}
            value={options.find((option) => option.value === value) || null}
            onChange={(selectedOption: SingleValue<DropdownOption>) => {
                if (selectedOption) {
                    onChange(selectedOption.value);
                }
            }}
            placeholder={placeholder}
            formatOptionLabel={(option) => (
                <CustomSingleValue
                    data={option}
                />
            )}
            components={{ SingleValue: CustomSingleValue }}
            styles={dropdownStyles}
            isClearable={false}
            className={styles.dropdown}
        />
    );
};

export default ActionSheetDropdown;
