import React, { useState, useEffect } from 'react';
import Navbar from '../navbar/Navbar'; // Import Navbar component
import styles from './Layout.module.css'; // Import CSS styles
import { LayoutProvider, useLayout } from "./LayoutContext";
import NavigationMenu from '../menu/NavigationMenu';
import { User } from 'src/gql/types';
import ProfileView from '../profile/ProfileView';
import { isIOS, isNative } from 'src/platform/platform';
import { KeyboardProvider } from 'src/contexts/KeyboardContext';
import LayoutView from './LayoutView';

interface NavbarConfig {
    title: string; // Optional title for the Navbar
    showLogo?: boolean;
    hamburgerMenu?: boolean; // Whether to show a profile bubble
    onBack?: () => void; // Optional back button handler
    useCloseButton?: boolean;
}

interface LayoutProps {
    children: React.ReactNode; // Content of the screen
    navbarConfig?: NavbarConfig; // Navbar configuration object
    overflow?: 'hidden' | 'auto'; // Control vertical overflow behavior
    footer?: React.ReactNode; // Optional footer element
    contentClassName?: string;
    footerPaddingClassName?: string;
    noFooterPadding?: boolean;
    isHidden?: boolean;
    user?: User;
}

const GlobalOverlay: React.FC = () => {
    const { isOverlayVisible, hideOverlay } = useLayout();

    if (!isOverlayVisible) return null;

    console.log("Overlay rendered");

    return (
        <div className={styles.overlay} onClick={hideOverlay}>
            {/* Optionally render content inside the overlay */}
        </div>
    );
};


const Layout: React.FC<LayoutProps> = ({ children, navbarConfig, overflow = 'hidden', footer, isHidden, user, noFooterPadding, contentClassName, footerPaddingClassName }) => {
    const [hamburgerMenuShown, setHamburgerMenuShown] = useState<boolean>(false);

    return (
        <LayoutProvider>
            {navbarConfig && navbarConfig.hamburgerMenu && <NavigationMenu onCloseMenu={() => setHamburgerMenuShown(false)} user={user} isHidden={isHidden || !hamburgerMenuShown} />}
            {overflow == 'auto' && isNative() && <div className={`${styles.iosSafeAreaPadding}`} />}
            <GlobalOverlay />
            <KeyboardProvider>
                {navbarConfig && !isHidden && !hamburgerMenuShown && (
                    <Navbar
                        title={navbarConfig.title}
                        showLogo={navbarConfig.showLogo}
                        openHamburgerMenu={navbarConfig.hamburgerMenu ? () => setHamburgerMenuShown(true) : undefined}
                        onBack={navbarConfig.onBack}
                        useCloseButton={navbarConfig.useCloseButton}
                        isOverflowVisible={overflow !== 'hidden'}
                    />
                )}
                <LayoutView
                    overflow={overflow}
                    isHidden={isHidden || hamburgerMenuShown}
                    footer={footer}
                    noFooterPadding={noFooterPadding}
                    contentClassName={contentClassName}
                    footerPaddingClassName={footerPaddingClassName}
                >
                    {children}
                </LayoutView>
            </KeyboardProvider>
        </LayoutProvider >
    );
};

export default Layout;
