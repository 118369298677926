import React from "react";
import styles from "./MainEventItem.module.css"; // New CSS module for the header
import { Event as DigestEventType, CaretakerRequest } from "../../gql/types";
import interactionIcon from "../../assets/icons/interaction-icon.svg";

type EventSummary = {
    eventID: string;
    title: string;
    startDate: string;
    endDate?: string | null;
    caretakerRequests?: CaretakerRequest[] | null;
};

type MainEventItemProps = {
    event: EventSummary;
    isEventInteractable: boolean;
    handleEventClick?: (event: DigestEventType) => void;
};

const MainEventItem: React.FC<MainEventItemProps> = ({ event, isEventInteractable, handleEventClick }) => {
    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        return date.toLocaleTimeString(undefined, options);
    };

    return (
        <div key={event.eventID} className={`${styles.mainEventItem} ${styles.eventItem}`}
            onClick={() => isEventInteractable && handleEventClick && handleEventClick(event as DigestEventType)}>
            <div className={styles.eventItemCardRow}>
                <div className={styles.eventItemLeftSection}>
                    <div className={styles.evenItemtTitle}>{event.title || "Main Event"}</div>
                </div>
                <div className={styles.eventItemRightSection}>
                    <div className={styles.eventItemTime}>
                        {formatTime(event.startDate)}{event.endDate ? ` - ${formatTime(event.endDate)}` : ""}
                    </div>
                </div>
            </div>
            <div className={styles.eventItemCardRow}>
                <div className={styles.eventItemLeftSection}>
                    {event.caretakerRequests && event.caretakerRequests.length > 0 && (
                        <div className={styles.caretakerRequestsContainer}>
                            {event.caretakerRequests.map((request) => (
                                <div key={request.requestID} className={styles.caretakerRequestItem}>
                                    <div className={styles.caretakerRequestLabel}>
                                        Childcare Provider: {request.confirmed
                                            ? (request.caretakers && request.caretakers.length > 0
                                                ? request.caretakers[0]?.name
                                                : "Confirmed"
                                            )
                                            : "Request Sent (TBD)"}
                                    </div>
                                </div>
                            ))}
                        </div>)
                    }
                </div>
            </div>
            <div className={`${styles.eventItemCardRow} ${styles.smallerPaddingRow}`}>
                <div className={styles.interactionButtonContainer}>
                    {isEventInteractable && (
                        <button className={styles.interactionButton}>
                            <img src={interactionIcon} className={styles.interactionIcon} />
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default MainEventItem;
