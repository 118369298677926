import React, { useState } from "react";
import BaseActionSheet from "./BaseActionSheet";
import MaskedInput from "react-text-mask";
import hornIcon from "../../assets/icons/purple-horn.svg";
import styles from "./CaretakerAddSheet.module.css";

type CaretakerAddSheetProps = {
    isVisible: boolean;
    onClose: () => void;
    onSave: (newCaretaker: { name: string, contactInfo: string }) => void;
};

const CaretakerAddSheet: React.FC<CaretakerAddSheetProps> = ({
    isVisible,
    onClose,
    onSave,
}) => {
    const [name, setName] = useState("");
    const [phone, setPhone] = useState("");

    const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericOnlyValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
        setPhone(numericOnlyValue);
    };

    const handleSave = () => {
        onSave({ name, contactInfo: phone });
        onClose();
    };

    const customHeader =
        <div className={styles.addSheetHeader}>
            <div className={styles.iconContainer}>
                <img src={hornIcon} className={styles.attentionIcon} />
            </div>
            <div className={styles.headerInstruction}>
                Add your childcare provider<br />
                and their details below
            </div>
        </div>;

    return (
        <BaseActionSheet
            isVisible={isVisible}
            onClose={onClose}
            animate={false}
            customHeader={customHeader}
            actions={[
                [
                    {
                        label: "Save",
                        value: "save",
                        onClick: handleSave,
                        isDisabled: name.length === 0 || phone.length !== 10, // Disable Save button if name is empty
                        isPrimary: true,
                    }
                ],
                [
                    {
                        label: "Back",
                        value: "back",
                        onClick: onClose,
                        isPrimary: false,
                    },
                ]
            ]}
        >
            <div className={styles.inputSection}>
                <div className={styles.sectionHeader}>Name:</div>
                <input
                    type="text"
                    className={styles.input}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <div className={styles.sectionHeader}>Phone Number (optional):</div>
                <MaskedInput
                    mask={phoneMask}
                    className={styles.input}
                    value={phone}
                    onChange={handlePhoneChange}
                    placeholder="Enter your phone number"
                />
            </div>
        </BaseActionSheet >
    );
};

export default CaretakerAddSheet;
