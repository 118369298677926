import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { configureApiBaseUrl } from '../config/api';
import { AUTH_TYPE } from '../google/loginHandler';
import { isNative } from 'src/platform/platform';
import { fetchWithAuth } from 'src/request/backend';

export async function exchangeGoogleTokensForAccesTokens(authType: AUTH_TYPE, idToken: string, accessToken: string, refreshToken?: string): Promise<string> {
  const payload: Record<string, string> = { id_token: idToken, access_token: accessToken };
  if (refreshToken) {
    payload.refresh_token = refreshToken;
  }
  payload.type = authType.toLowerCase();

  const API_BASE_URL = await configureApiBaseUrl();

  if (authType == AUTH_TYPE.CALENDAR) {
    const response = await fetchWithAuth('/api/auth/google/calendar/exchange', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Google Sign-in failed');
    }

    const { email } = await response.json();
    return email;
  } else {
    const response = await fetch(`${API_BASE_URL}/api/auth/google/exchange`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const { access_token, refresh_token, email } = await response.json();
    await SecureStoragePlugin.set({ key: 'access_token', value: access_token });
    await SecureStoragePlugin.set({ key: 'refresh_token', value: refresh_token });
    return email;
  }
}

export async function exchangeFirebaseTokenForAccessTokens(idToken: string): Promise<boolean> {
  const payload: Record<string, string> = { id_token: idToken };
  const API_BASE_URL = await configureApiBaseUrl();

  if (isNative()) {
    const response = await fetch(`${API_BASE_URL}/api/auth/firebase/exchange`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${idToken}`, // 🔥 ID Token in Header
      },
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const { access_token, refresh_token } = await response.json();

    await SecureStoragePlugin.set({ key: 'access_token', value: access_token });
    await SecureStoragePlugin.set({ key: 'refresh_token', value: refresh_token });
    return true;
  }

  const response = await fetch(`${API_BASE_URL}/api/auth/firebase/session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${idToken}`, // 🔥 ID Token in Header
    },
  });

  if (!response.ok) {
    throw new Error('Login failed');
  }

  return true;
}