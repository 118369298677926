import { DateTime } from "luxon";

export const convertToISO8601 = (dateString: string, timeZone?: string) => {
    if (!dateString) return dateString; // Handle invalid input

    // Check if the input already has an explicit timezone offset
    const iso8601WithOffsetRegex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?([+-]\d{2}:\d{2}|Z)$/;
    if (iso8601WithOffsetRegex.test(dateString)) {
        return dateString; // Already in correct format with offset, return as is.
    }

    const appliedTimeZone = timeZone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const dt = DateTime.fromISO(dateString, { setZone: false }).setZone(appliedTimeZone);

    return dt.toISO() ?? dateString;
};