import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import styles from "./ExistingLoginScreen.module.css";
import Layout from "../layout/Layout";
import ActionButton from "../button/ActionButton";
import { auth } from "src/config/firebase";
import primaryLogo from "src/assets/primary-logo.svg";
import TextInputField, { TextInputFieldStyle, TextInputFieldType } from "../input/TextInputField";
import { isNative } from "src/platform/platform";

const ResetPasswordScreen: React.FC = () => {
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [oobCode, setOobCode] = useState<string | null>(null);
    const [email, setEmail] = useState<string | null>(null); // Store email from verification
    const [isLoading, setIsLoading] = useState(true);
    const [isValidFields, setIsValidFields] = useState({
        password: false,
        confirmPassword: false,
    });
    const isFormValid = Object.values(isValidFields).every(Boolean);

    const location = useLocation();
    const navigate = useNavigate();
    const inNative = isNative();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const code = params.get("oobCode");

        if (!code) {
            navigate(`/auth/login?error=true&message=${encodeURIComponent("Invalid way to reset password.")}`);
            setIsLoading(false);
            return;
        }

        // ✅ Verify if the oobCode is valid for password reset
        verifyPasswordResetCode(auth, code)
            .then((retrievedEmail) => {
                setEmail(retrievedEmail); // Store the email linked to this reset code
                setOobCode(code);
                setIsLoading(false);
            })
            .catch(() => {
                navigate(`/auth/login?error=true&message=${encodeURIComponent("Invalid or expired password reset link.")}`);
                setIsLoading(false);
                return;
            });
    }, [location.search]);

    const handleResetPassword = async () => {
        if (!oobCode) return;
        if (password !== confirmPassword) {
            navigate(`?error=true&message=${encodeURIComponent("Passwords do not match.")}`);
            return;
        }

        try {
            await confirmPasswordReset(auth, oobCode, password);
            navigate(`/auth/login/existing?error=false&message=${encodeURIComponent("Password Reset Successful! Please log in.")}`);
        } catch (err) {
            navigate(`/?error=true&message=${encodeURIComponent("Failed to reset password. The link may be expired.")}`);
        }
    };

    return (
        <>
            {oobCode && oobCode.length > 0 && (
                <Layout
                    navbarConfig={inNative ? { title: "Reset Password", onBack: () => navigate("/auth/login") } : undefined}
                >
                    <form className={`${inNative ? styles.loginForm : styles.resetWebForm}`}>
                        {!inNative &&
                            <div className={styles.headerContainer}>
                                <div className={styles.logoContainer}>
                                    <img src={primaryLogo} className={styles.headerLogo} />
                                </div>
                                <div className={styles.logoContainerHeaderText}>
                                    Reset Password
                                </div>
                            </div>}
                        <div className={`${inNative ? styles.nativeResetInstructions : styles.webResetInstructions}`}>
                            Please enter a new password below to change your password.
                        </div>
                        <TextInputField
                            type={TextInputFieldType.PASSWORD}
                            placeholder="Password (8+ chars, 1 number, 1 special char)"
                            style={TextInputFieldStyle.LOGIN}
                            value={password}
                            onChange={(value) => setPassword(value)}
                            onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, password: isValid }))}
                        />
                        <TextInputField
                            type={TextInputFieldType.PASSWORD}
                            placeholder="Confirm password"
                            style={TextInputFieldStyle.LOGIN}
                            value={confirmPassword}
                            onChange={(value) => setConfirmPassword(value)}
                            errorStr={confirmPassword.length > 0 && password !== confirmPassword ? "confirmed password does not match." : undefined}
                            onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, confirmPassword: isValid }))}
                        />
                        <ActionButton
                            onClick={handleResetPassword}
                            className={styles.formLoginButton}
                            hasDataLoad={true}
                            disabled={!isFormValid} // Disabled unless all validations pass
                        >
                            Change Password
                        </ActionButton>
                    </form>
                </Layout >)}
        </>
    );
};

export default ResetPasswordScreen;
