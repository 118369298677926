import React, { useState, useEffect } from 'react';
import CalendarSync, { AccountProviderType, SyncedAccount } from '../profile/CalendarSync';
import { useNavigate } from 'react-router-dom';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';
import { appEvents } from '../../App';
import { AppEventType } from '../../events/app';
import { CalendarPlugin } from 'calendar-plugin';
import { CalendarSourceType } from '../../types/Calendar';
import { CalendarConnection, ExternalSource, useGetCalendarConnectionsQuery, useRemoveCalendarConnectionMutation } from 'src/gql/types';
import ActionButton from '../button/ActionButton';
import Layout from '../layout/Layout';
import { AdditionalButton } from '../onboarding/OnboardingStep';
import SecondaryButton from '../button/SecondaryButton';
import styles from './CalendarComponents.module.css';
import { isIOS } from 'src/platform/platform';
import { faL } from '@fortawesome/free-solid-svg-icons';
import { convertSourceType } from './convertSourceType';

interface CalendarRefreshViewProps {
    connections: CalendarConnection[];
    onContinue: () => void;
}

const CalendarRefreshView: React.FC<CalendarRefreshViewProps> = ({
    connections,
    onContinue,
}) => {
    const [existingAccounts, setExistingAccounts] = useState<SyncedAccount[]>(connections.map((connection: CalendarConnection) => ({
        provider: connection.source === 'Google' ? AccountProviderType.GOOGLE : AccountProviderType.APPLE,
        identifier: connection.identifier,
        expired: connection.expired,
        label: connection.label ?? undefined,
    })));
    const [expiredAccounts, setExpiredAccounts] = useState<SyncedAccount[]>(connections.map((connection: CalendarConnection) => ({
        provider: connection.source === 'Google' ? AccountProviderType.GOOGLE : AccountProviderType.APPLE,
        identifier: connection.identifier,
        expired: connection.expired,
        label: connection.label ?? undefined,
    })).filter((account) => {
        return account.expired;
    }));
    const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state
    const [additionalButtons, setAdditionalButtons] = useState<
        AdditionalButton[]
    >([]);
    const navigate = useNavigate();

    // Use Apollo Client's useQuery hook to fetch calendar connections
    const { loading, error, data, refetch } = useGetCalendarConnectionsQuery({
        fetchPolicy: 'network-only',
    });

    const [removeCalendarConnection] = useRemoveCalendarConnectionMutation();

    // Update button state whenever syncedAccounts changes
    useEffect(() => {
        if (expiredAccounts.length == 0) {
            onContinue();
        }

        const actionableAccounts = expiredAccounts.filter((account) => {
            if (!account.expired) {
                return false;
            }
            if (account.provider == AccountProviderType.GOOGLE) {
                return true;
            }
            if (isIOS() && account.provider == AccountProviderType.APPLE) {
                return true;
            }
            return false;
        });

        if (actionableAccounts.length == 0) {
            onContinue();
        }
    }, [expiredAccounts]);

    // Handle adding an account
    const handleAddAccount = async (provider: AccountProviderType) => {
        if (provider == AccountProviderType.GOOGLE) {
            await handleLoginWithGoogle(AUTH_TYPE.CALENDAR, "/user/profile/");
        } else if (provider === AccountProviderType.APPLE) {
            try {
                const response = await CalendarPlugin.requestCalendarPermissions();
                if (response.granted) {
                    console.log('Calendar permissions granted.');
                    appEvents.emit(AppEventType.CALENDAR_UPDATED, { source: CalendarSourceType.APPLE, account: response.deviceIdentifier, label: response.deviceName });
                } else {
                    console.error('Calendar permissions not granted.');
                }
            } catch (error) {
                console.error('Error requesting calendar permissions:', error);
            }
        }
    };

    // Handle removing an account
    const handleRemoveAccount = async (identifier: string) => {
        try {
            const accountToRemove = expiredAccounts.find(
                (account) => account.identifier === identifier
            );
            if (!accountToRemove) {
                console.error(`Account with email ${identifier} not found`);
                return;
            }

            let source: ExternalSource;
            switch (accountToRemove.provider) {
                case AccountProviderType.GOOGLE:
                    source = ExternalSource.Google;
                    break;
                case AccountProviderType.APPLE:
                    source = ExternalSource.Apple;
                    break;
                default:
                    console.error("invalid source: ", accountToRemove.provider);
                    return;
            }

            // Execute the mutation
            await removeCalendarConnection({
                variables: {
                    calendar: {
                        source,
                        identifier: accountToRemove.identifier,
                    },
                },
            });

            // Update local state after successful mutation
            setExpiredAccounts((prev) =>
                prev.filter((account) => account.identifier !== identifier)
            );
            setExistingAccounts((prev) =>
                prev.filter((account) => account.identifier !== identifier)
            );
        } catch (error) {
            console.log(error);
            navigate(`?error=true&message=${encodeURIComponent('There was an error removing the account.')}`);
        }
    };

    const handleContinue = () => {
        onContinue();
    };

    const additionalButtonElements = additionalButtons.map((button, index) =>
        button.type === 'primary' ? (
            <ActionButton
                key={index}
                onClick={button.onClick}
                iconType={button.iconType}
                hasDataLoad={button.hasDataLoad}
                className={button.className}
            >
                {button.label}
            </ActionButton>
        ) : (
            <SecondaryButton key={index} onClick={button.onClick}>
                {button.label}
            </SecondaryButton>
        )
    );

    return (
        <Layout
            navbarConfig={{ title: "Refresh Connection" }}
        // footer={
        //     <div className={styles.buttonContainer}>
        //         {additionalButtonElements}
        //         <ActionButton
        //             onClick={handleContinue}
        //             disabled={!buttonEnabled}
        //             hasDataLoad={false}
        //         >
        //             Confirm
        //         </ActionButton>
        //     </div>
        // }
        >
            <div className={styles.instructions}>
                {loading && <>Loading calendars...</>}
                {error && <>Error loading calendars. Please try again.</>}
                {!loading && !error && (
                    expiredAccounts.length > 0
                        ? <>
                            Please refresh your connection for the latest data to be available on Plii. Your calendar will remain private to your family.
                        </>
                        : existingAccounts.length > 0 ?
                            <>
                                All of your connections are up to date!
                            </>
                            : <>
                                We need at least one connection.
                            </>)}
            </div>
            {expiredAccounts.length > 0 && <CalendarSync
                existingConnections={expiredAccounts} // Prefetched accounts
                onAccountAdd={handleAddAccount} // Pass handleAddAccount directly
                onAccountRemove={handleRemoveAccount} // Pass handleRemoveAccount directly
                setAdditionalButtons={setAdditionalButtons} // Pass down callback to set buttons
                loading={loading} // Pass loading state
                error={error} // Pass error state
            />}
        </Layout>
    );
};

export default CalendarRefreshView;
