import React, { useState, useEffect, useRef } from 'react';
import { fetchWithAuth } from '../../request/backend';
import { useLazyQuery } from '@apollo/client';
import { GET_ANALYSIS_STATUS } from '../../graphql/queries/getAnalysisStatus';
import styles from './DataSetupComponent.module.css'; // Import the CSS module
import Logo from '../../assets/primary-logo.svg'; // Import the logo
import { User } from 'src/gql/types';
import { LOCAL_MODE, SHOW_DATA_LOADING_SCREEN } from '../../config/local';
import { SKIP_DATA_SYNC } from '../../config/local';
import { SyncType, TaskStatusType, useGetSyncStatusLazyQuery, useGetSyncStatusQuery } from 'src/gql/types';
import { Preferences } from '@capacitor/preferences';
import { PreferenceKey } from 'src/storage/preferences/prefKeys';

const DataSetupComponent = ({
    user,
    displayLogo = true,
    onComplete,
    className,
    initialMessage,
}: {
    user: User;
    displayLogo?: boolean;
    onComplete: () => void;
    className?: string;
    initialMessage?: string;
}) => {
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState('Initializing...');
    const [syncType, setSyncType] = useState<SyncType | null>(null);
    const [syncComplete, setSyncComplete] = useState(false);
    const [analysisComplete, setAnalysisComplete] = useState(false);
    const [initialSyncRequestComplete, setInitialSyncRequestComplete] = useState(false);
    const [syncRange, setSyncRange] = useState<{ start: string; end: string } | null>(null);
    const [updatedAfter, setUpdatedAfter] = useState<string | null>(null);
    const [analysisTaskID, setAnalysisTaskID] = useState<string | null>(null);
    const progressRef = useRef(0); // Ref to store the current progress value

    const { data: initialCheckData, loading: initialCheckLoading, error: initialCheckError } = useGetSyncStatusQuery({
        variables: { syncType: SyncType.Initial },
        skip: SHOW_DATA_LOADING_SCREEN,
    });

    const [checkSyncStatus, { data: lazyData, loading: lazyLoading, error: lazyError }] = useGetSyncStatusLazyQuery(
        {
            fetchPolicy: "network-only",
        }
    );

    useEffect(() => {
        if (syncType) {
            return;
        }

        if (initialCheckLoading) {
            setStatus('Checking your calendar...');
        } else if (initialCheckError) {
            setStatus('There was an issue with sync.  Please try again.');
        } else if (initialCheckData?.getSyncStatus) {
            const syncStatus = initialCheckData.getSyncStatus;
            console.log("initial check results");
            console.log(syncStatus);
            if (syncStatus.status == TaskStatusType.Completed) {
                setSyncType(SyncType.Continuous);
            } else {
                setSyncType(SyncType.Initial);
            }
        }
    }, [initialCheckLoading, initialCheckError, initialCheckData]);

    const [checkAnalysisStatus, { data: analysisData, loading: analysisLoading, error: analysisError }] = useLazyQuery(
        GET_ANALYSIS_STATUS,
        { fetchPolicy: 'network-only' }
    );

    const startInitialSync = async () => {
        if (SHOW_DATA_LOADING_SCREEN || LOCAL_MODE) {
            switch (syncType) {
                case SyncType.Initial:
                    setStatus('Starting initial sync...');
                    break;
                case SyncType.Continuous:
                    setStatus('Checking for any event updates...');
                    break;
            }
            setProgress(25);
            await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate delay
            await mockCheckSyncStatus();
        } else {
            try {
                if (!syncType) {
                    throw new Error("sync type must be set at this point.");
                }
                setProgress(25);
                switch (syncType) {
                    case SyncType.Initial:
                        setStatus('Starting initial sync...');
                        await fetchWithAuth('/api/calendar/sync/initial', { method: 'POST', body: JSON.stringify({}) });
                        break;
                    case SyncType.Continuous:
                        setStatus('Checking for any event updates...');
                        await fetchWithAuth('/api/calendar/sync/incremental', { method: 'POST', body: JSON.stringify({}) });
                        break;
                }
                setInitialSyncRequestComplete(true);
            } catch (err) {
                console.error('Error starting initial sync:', err);
                setStatus('Error during sync. Please retry.');
            }
        }
    };

    const startAnalysisRequest = async () => {
        if (!syncRange || !updatedAfter) {
            console.log("required values not present");
            return;
        }

        try {
            setStatus('Analyzing your events...');
            setProgress(60);
            const response = await fetchWithAuth('/api/calendar/sync/analyze', {
                method: 'POST',
                body: JSON.stringify({
                    syncRange: {
                        start: syncRange.start, // Adjust range as needed
                        end: syncRange.end,
                    },
                    updatedAfter: updatedAfter,
                    syncType: syncType,
                }),
            });
            const data = await response.json();
            setAnalysisTaskID(data.taskId);
        } catch (err) {
            console.error('Error starting analysis:', err);
            setStatus('Error during analysis. Please retry.');
        }
    };

    const pollAnalysisStatus = async () => {
        if (!analysisTaskID) {
            return;
        }

        let delay = 10; // Start with a 10ms delay
        const maxDelay = 100; // Maximum delay of 100 milliseconds

        const poll = async () => {
            try {
                const { data: analysisData } = await checkAnalysisStatus({
                    variables: { taskID: analysisTaskID },
                });
                const analysisStatus = analysisData?.getAnalysisStatus?.status;

                if (analysisStatus === 'COMPLETED') {
                    setStatus('Analysis completed!');
                    setAnalysisComplete(true);
                    setProgress(100);
                    return; // Stop polling
                } else if (analysisStatus === 'ERROR') {
                    setStatus('Error during analysis. Please retry.');
                    return; // Stop polling
                }
            } catch (err) {
                console.error('Error checking analysis status:', err);
            }

            // Use exponential backoff, but cap at maxDelay
            delay = Math.min(delay * 2, maxDelay);
            setTimeout(poll, delay); // Schedule the next poll
        };

        poll(); // Start the polling
    };

    const mockCheckSyncStatus = async () => {
        const interval = setInterval(() => {
            setStatus('Checking sync status...');
            if (progressRef.current >= 50) {
                clearInterval(interval);
                setStatus('Sync completed!');
                setSyncComplete(true);
                return;
            }
            setProgress((prevProgress) => {
                const newProgress = Math.min(prevProgress + 5, 50);
                if (progressRef.current < 50) {
                    progressRef.current = newProgress; // Update the ref with the latest value
                }
                return newProgress;
            });
        }, 500);
    };

    useEffect(() => {
        const fetchAndPollSyncStatus = async () => {
            let delay = 10; // Start with a 10ms delay
            const maxDelay = 100; // Maximum delay of 100ms

            const poll = async () => {
                try {
                    if (!syncType) {
                        setStatus("there was an internal issue.  Please report this issue to Plii.");
                        return;
                    }

                    const { data: syncData } = await checkSyncStatus({ variables: { syncType: syncType } });

                    const syncStatus = syncData?.getSyncStatus;

                    if (syncStatus?.status === TaskStatusType.Completed) {
                        if (!syncStatus.syncRange) {
                            setStatus('Error during sync. Please retry.');
                            return;
                        }
                        // Sync already completed; set status and proceed
                        switch (syncType) {
                            case SyncType.Initial:
                                setStatus('Sync completed!');
                                break;
                            case SyncType.Continuous:
                                setStatus('Events Updated!');
                                break;
                        }
                        setSyncComplete(true);
                        setProgress(50);
                        setSyncRange(syncStatus.syncRange);
                        setUpdatedAfter(syncStatus.createdAt);
                        return; // Exit early, no need to poll further
                    }

                    if (syncStatus?.status === TaskStatusType.Error) {
                        // Sync failed; set error status
                        setStatus('Error during sync. Please retry.');
                        return; // Exit early
                    }
                } catch (err) {
                    console.error('Error fetching sync status:', err);
                }

                // Use exponential backoff for polling delay
                delay = Math.min(delay * 2, maxDelay);
                setTimeout(poll, delay); // Schedule the next poll
            };

            // Fetch once and start polling
            await poll();
        };

        if (initialSyncRequestComplete && !syncComplete && !SHOW_DATA_LOADING_SCREEN) {
            fetchAndPollSyncStatus();
        }
    }, [initialSyncRequestComplete, syncComplete, checkSyncStatus]);

    useEffect(() => {
        const analyzeEvents = async () => {
            if (SHOW_DATA_LOADING_SCREEN || LOCAL_MODE) {
                setStatus('Analyzing your events...');

                // Simulate event analysis
                await new Promise((resolve) => setTimeout(resolve, 2000));
                setStatus('Analysis Completed!');
                setAnalysisComplete(true);
                setProgress(100);
                return;
            }
            startAnalysisRequest();
        };

        if (syncComplete && !analysisTaskID) {
            analyzeEvents();
        }
    }, [syncComplete, syncRange, updatedAfter]);

    useEffect(() => {
        if (analysisTaskID && !analysisComplete) {
            pollAnalysisStatus();
        }
    }, [analysisTaskID, analysisComplete]);

    useEffect(() => {
        if (SKIP_DATA_SYNC) {
            setAnalysisComplete(true);
            return;
        }
        if (SHOW_DATA_LOADING_SCREEN || (user?.onboardingStatus?.completed && syncType)) {
            if (SHOW_DATA_LOADING_SCREEN && !syncType) {
                setSyncType(SyncType.Continuous);
                return;
            }
            startInitialSync();
        }
    }, [user, syncType]);

    useEffect(() => {
        if (progress >= 100) {
            (async () => {
                await Preferences.set({
                    key: PreferenceKey.LAST_SYNC_TIME,
                    value: Date.now().toString(),
                });
                onComplete(); // Call onComplete after saving
            })();
        }
    }, [progress]);


    return (
        <div className={`${styles.container} ${className ?? ''}`}>
            {displayLogo && <img src={Logo} alt="plii logo" className={styles.logo} />}
            <p className={styles.message}>
                {progress == 0
                    ? (initialMessage ?? "Hang tight! We’re fetching your data to best serve you...")
                    : status}
            </p>
            <div className={styles.progressBarContainer}>
                <div
                    className={styles.progressBar}
                    style={{
                        width: `${progress}%`,
                    }}
                ></div>
            </div>
            <p className={styles.percentage}>{progress}%</p>
        </div>
    );
};

export default DataSetupComponent;
