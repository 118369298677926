import React, { useEffect, useState } from "react";
import styles from "./ExistingLoginScreen.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../layout/Layout";
import ActionButton from "../button/ActionButton";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "src/config/firebase";

interface CheckInboxScreenProps {
}

const CheckInboxScreen: React.FC<CheckInboxScreenProps> = ({ }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // Retrieve email from sessionStorage if not passed via state
        const storedEmail = sessionStorage.getItem("user_email");
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            navigate("/"); // Redirect if email is missing
        }
    }, [location.state, navigate]);

    const handleResendEmail = async () => {
        setLoading(true);

        try {
            await sendPasswordResetEmail(auth, email);
            navigate(`?error=false&message=${encodeURIComponent("Password reset email sent. Please check your inbox.")}`);
        } catch (error) {
            console.error("Reset error:", error);
            navigate(`?error=true&message=${encodeURIComponent("Failed to send reset email. Please try again.")}`);
        }

        setLoading(false);
    };

    return (
        <Layout
            navbarConfig={{ title: "Verify Your Email", onBack: () => navigate("/auth/signup") }}
            footer={(
                <div className={styles.existingLoginFooter}>
                    Didn’t receive the email?{" "} <span className={styles.loginLink} onClick={handleResendEmail}>
                        Resend
                    </span>
                </div>
            )}
        >
            <div className={styles.verifyEmailContainer}>
                <div className={styles.instructions}>
                    Please verify your email using the link sent to <span className={styles.emailBoldText}>{email}</span>
                </div>
                <ActionButton onClick={() => navigate("/auth/login")} className={styles.formLoginButton} disabled={loading}>
                    {loading ? "Resending..." : "Back to Log In"}
                </ActionButton>

            </div>
        </Layout>
    );
};

export default CheckInboxScreen;
