import React from "react";
import styles from "./CaretakerRequestRecipientResponseChangeView.module.css";
import DigestHeader from "../digest/DigestHeader";
import MainEventItem from "../digest/MainEventItem";
import ProfileDetailRow from "../profile/ProfileDetailRow";
import TimeRangeRowView from "../time/TimeRangeRowView";
import purpleLogo from "../../assets/icons/purple-logo.svg";
import Layout from "../layout/Layout";
import { CaretakerRequest, CaretakerResponse } from "../../gql/types";
import { AttentionLevelType } from "../../gql/types";
import noUser from "../../assets/no-user-purple.svg";

type CaretakerRequestRecipientResponseChangeViewProps = {
    request: CaretakerRequest;
    response: CaretakerResponse;
    onConfirm: (response: CaretakerResponse) => void;
    onDecline: (response: CaretakerResponse) => void;
};

const CaretakerRequestRecipientResponseChangeView: React.FC<CaretakerRequestRecipientResponseChangeViewProps> = ({
    request,
    response,
    onConfirm,
    onDecline,
}) => {
    const itemHeader = request.requestContext?.contextHeader;
    const attentionLevel = request.requestContext?.contextHeader?.attentionLevel ?? AttentionLevelType.Informational;
    const eventDetails = request.requestContext?.eventDetails;
    const eventItem = {
        "title": eventDetails?.title ?? "",
        "startDate": eventDetails?.startDate ?? "",
        "endDate": eventDetails?.endDate ?? "",
        "eventID": "placeholderID",
    }

    const formatPhoneNumber = (phoneNumber: string) => {
        return phoneNumber.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2 - $3");
    };

    return (
        <Layout
            overflow="auto" // Allow scrolling if content exceeds screen height
        >
            <div className={styles.recipientViewContainer}>
                <DigestHeader
                    topTitle={itemHeader?.topTitle ?? ""}
                    mainTitle={itemHeader?.mainTitle ?? ""}
                    attentionLevel={attentionLevel}
                    description={itemHeader?.description ?? undefined}
                />

                {eventDetails &&
                    <MainEventItem event={eventItem} isEventInteractable={false} />}
                <div className={styles.bottomSheetContainer}>
                    <div className={styles.bottomSheet}>
                        <div className={styles.topSection}>
                            <div className={styles.iconContainer}>
                                <img src={purpleLogo} className={styles.confirmationIcon} />
                            </div>
                            <div className={styles.infoSection}>
                                Please review the childcare request you accepted and let us know if there are any changes.
                            </div>
                        </div>
                        <div className={styles.middleSection}>
                            <div className={styles.sectionHeader}>From:</div>
                            <ProfileDetailRow
                                name={request.requester.firstName + " " + request.requester.lastName}
                                profilePictureUrl={request.requester.profilePictureUrl ?? noUser}
                            />

                            <div className={styles.section}>
                                <div className={styles.sectionHeader}>Start & End Time:</div>
                                <TimeRangeRowView
                                    startTime={request.timeRange.startDate}
                                    endTime={request.timeRange.endDate}
                                />
                            </div>

                            <div className={styles.sectionHeader}>Notes/Message:</div>
                            <div className={styles.notes}>
                                {request.message || <em>No message provided</em>}
                            </div>
                        </div>
                        <div className={styles.bottomSection}>
                            <div className={styles.section}>
                                <div className={styles.sectionHeader}>Your Name:</div>
                                <div className={styles.textContainer}>
                                    {response.responder?.name}
                                </div>
                                <div className={styles.sectionHeader}>Phone Number:</div>
                                <div className={styles.textContainer}>
                                    {response.responder && response.responder.contactInfo
                                        ? formatPhoneNumber(response.responder.contactInfo)
                                        : ""}
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonContainer}>
                            <button
                                className={styles.submitButton}
                                onClick={() => onConfirm(response)}
                            >
                                Confirm and add to calendar
                            </button>
                            <button
                                className={styles.submitButton}
                                onClick={() => onDecline(response)}
                            >
                                Sorry, I can no longer make it
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default CaretakerRequestRecipientResponseChangeView;
