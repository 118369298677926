import React, { useEffect, useState } from 'react';
import styles from './ExistingLoginScreen.module.css';
import { useNavigate } from 'react-router-dom';
import Layout from '../layout/Layout';
import ActionButton from '../button/ActionButton';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';
import { exchangeFirebaseTokenForAccessTokens } from 'src/auth/verify';
import { FirebaseError } from 'firebase/app';
import TextInputField, { TextInputFieldStyle, TextInputFieldType } from '../input/TextInputField';
import { appEvents } from 'src/App';
import { AppEventType } from 'src/events/app';
import { firebaseSignIn } from 'src/auth/signin';
import { isNative } from 'src/platform/platform';
import { saveSessionData } from 'src/auth/session';

interface LoginScreenProps {
  loadUser: () => Promise<void>;
}

const LoginScreen: React.FC<LoginScreenProps> = ({ loadUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [isValidFields, setIsValidFields] = useState({
    email: false,
    password: false,
  });
  const isFormValid = Object.values(isValidFields).every(Boolean);


  const navigate = useNavigate();
  const handleForgotPassword = () => {
    navigate("/auth/forgot-password");
  };

  const handleLogin = async () => {
    try {
      const { emailVerified, idToken } = await firebaseSignIn(email, password);
      if (emailVerified && idToken) {
        try {
          await exchangeFirebaseTokenForAccessTokens(idToken);
        } catch (error) {
          navigate(`?error=true&message=${encodeURIComponent("Server authentication failed. Please try again later.")}`);
          appEvents.emit(AppEventType.RESET_ACTION_BUTTON);
          return;
        }
        await loadUser();
        navigate("/");
      } else if (!emailVerified) {
        if (isNative()) {
          await Promise.all([
            saveSessionData("user_email", email),
            saveSessionData("user_password", password),
          ]);
        } else {
          sessionStorage.setItem("user_email", email);
          sessionStorage.setItem("user_password", password);
        }

        // Navigate to verification screen
        navigate("/verify-email", { state: { email } });
      } else {
        throw Error("invalid state");
      }
    } catch (error) {
      console.log("is there an error? ", error);
      if (!(error instanceof FirebaseError)) {
        appEvents.emit(AppEventType.RESET_ACTION_BUTTON);
        navigate(`?error=true&message=${encodeURIComponent("There was an issue. Please try again later.")}`);
        return;
      }

      let errorMessage: string;
      switch (error.code) {
        case "auth/wrong-password":
          errorMessage = "Incorrect password. Please try again.";
        case "auth/user-not-found":
          errorMessage = "No account found with this email.";
        case "auth/invalid-email":
          errorMessage = "Invalid email format.";
        case "auth/too-many-requests":
          errorMessage = "Too many failed attempts. Try again later.";
        case "auth/user-disabled":
          errorMessage = "Your account has been disabled.";
        default:
          errorMessage = "Login failed. Please check your credentials and try again.";
      }

      navigate(`?error=true&message=${encodeURIComponent(errorMessage)}`);
    }
  };

  const handleBack = () => {
    // Handle existing user login logic
    navigate('/auth/login');
  };

  return (
    <Layout navbarConfig={{ title: "Log in", onBack: handleBack }}>
      <form className={styles.loginForm}>
        <TextInputField
          type={TextInputFieldType.EMAIL}
          placeholder="Email"
          style={TextInputFieldStyle.LOGIN}
          value={email}
          onChange={(value) => setEmail(value)}
          onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, email: isValid }))}
        />
        <TextInputField
          type={TextInputFieldType.PASSWORD}
          placeholder="Password"
          style={TextInputFieldStyle.LOGIN}
          value={password}
          onChange={(value) => setPassword(value)}
          onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, password: isValid }))}
        />
        <div className={styles.forgotPasswordLinkContainer}>
          <button
            type="button"
            className={styles.forgotPasswordLink}
            onClick={handleForgotPassword}
          >
            Forgot password?
          </button>
        </div>
        <ActionButton
          onClick={handleLogin}
          className={styles.formLoginButton}
          disabled={!isFormValid}
          hasDataLoad={true}
        >
          Log in
        </ActionButton>
      </form>
      <div className={styles.loginDivider}>or</div>
      <ActionButton
        iconType="Google"
        colorPattern="Light Brown"
        onClick={() => handleLoginWithGoogle(AUTH_TYPE.LOGIN, "/")}
        hasDataLoad={true}
      >
        Log In with Google
      </ActionButton>
    </Layout>
  );
};

export default LoginScreen;
