import { ReactNode } from 'react';

export enum FooterButtonPosition {
    LEFT = 'LEFT',
    MIDDLE = 'MIDDLE',
    RIGHT = 'RIGHT',
}

export type CustomFooterButton = ReactNode; // A custom button can be any React node

