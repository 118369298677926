import React, { useState } from "react";
import styles from "./ExistingLoginScreen.module.css";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import ActionButton from "../button/ActionButton";
import { auth } from "src/config/firebase";
import TextInputField, { TextInputFieldStyle, TextInputFieldType } from "../input/TextInputField";

const ForgotPasswordScreen: React.FC = () => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [isValidFields, setIsValidFields] = useState({
        email: false,
    });
    const isFormValid = Object.values(isValidFields).every(Boolean);

    const navigate = useNavigate();

    const handleResetPassword = async () => {
        setLoading(true);

        try {
            await sendPasswordResetEmail(auth, email);
            sessionStorage.setItem("user_email", email);
            navigate('/auth/check-inbox');
        } catch (error) {
            console.error("Reset error:", error);
            navigate(`?error=true&message=${encodeURIComponent("Failed to send reset email. Please try again.")}`);
        }

        setLoading(false);
    };

    return (
        <Layout navbarConfig={{ title: "Forgot Your Password?", onBack: () => navigate("/auth/login") }}>
            <form className={styles.loginForm}>
                <div className={styles.instructions}>
                    Please enter your email address, and we'll send you a link to reset your password.
                </div>
                <TextInputField
                    type={TextInputFieldType.EMAIL}
                    placeholder="Email"
                    style={TextInputFieldStyle.LOGIN}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, email: isValid }))}
                />
                <ActionButton onClick={handleResetPassword}
                    className={styles.formLoginButton}
                    disabled={!isFormValid || loading}
                    hasDataLoad={true}
                >
                    {loading ? "Checking..." : "Submit"}
                </ActionButton>
            </form>
        </Layout>
    );
};

export default ForgotPasswordScreen;
