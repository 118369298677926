import { CaretakerDetails } from "../../gql/types";
import { Caretaker } from "../input/CaretakerDropdown";

export const convertCaretakerDetailsToCaretakers = (
    details: CaretakerDetails[],
    selfUserID: string,
    spouseUserID?: string
): Caretaker[] => {
    return details
        .map((detail) => {
            const { userID, userSummary, name, contactInfo } = detail;

            let userType: "SELF" | "SPOUSE" | "OTHER" | "EXTERNAL" = "EXTERNAL";
            if (userID) {
                if (userID === selfUserID) {
                    userType = "SELF";
                } else if (userID === spouseUserID) {
                    userType = "SPOUSE";
                } else {
                    userType = "OTHER";
                }
            }

            return {
                id: userID || contactInfo || Math.random().toString(36).substr(2, 9), // Fallback to contactInfo or generate an ID
                name: name || `${userSummary?.firstName || ""} ${userSummary?.lastName || ""}`.trim(),
                profilePictureUrl: userSummary?.profilePictureUrl ?? undefined,
                userType,
            };
        });
};
