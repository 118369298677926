import { useState, useEffect, useCallback } from "react";
import { CalendarAuthParams } from "src/components/calendar/CalendarModificationScreen";
import { CalendarSourceType } from "src/types/Calendar";

const useCalendarAuthRedirect = () => {
    const [calendarAuthParams, setCalendarAuthParams] = useState<CalendarAuthParams | null>(null);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const success = queryParams.get('calendarAuthSuccess') === 'true';
        if (success) {
            const sourceParam = queryParams.get('source');
            const accountParam = queryParams.get('account');
            queryParams.delete('calendarAuthSuccess');
            queryParams.delete('source');
            queryParams.delete('account');
            window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);

            const source = Object.values(CalendarSourceType).includes(sourceParam as CalendarSourceType)
                ? (sourceParam as CalendarSourceType)
                : undefined;

            if (!source || !accountParam) {
                console.error('Invalid calendar source provided:', sourceParam);
                return;
            } else {
                console.log('Valid calendar source:', source);
            }
            setCalendarAuthParams({
                source: source,
                account: accountParam,
            });
            return;
        }
        // setCalendarAuthParams({
        //     source: CalendarSourceType.GOOGLE,
        //     account: "han.lee@gmail.com",
        // });
    }, []);

    const clearCalendarAuthParams = useCallback(() => {
        setCalendarAuthParams(null);
    }, []);

    return { calendarAuthParams, clearCalendarAuthParams };
};

export default useCalendarAuthRedirect;
