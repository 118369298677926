import React, { useState, useEffect } from 'react';
import styles from './Layout.module.css'; // Import CSS styles
import { useKeyboard } from 'src/contexts/KeyboardContext';
import { isIOS } from 'src/platform/platform';

interface LayoutViewProps {
    children: React.ReactNode; // Content of the screen
    overflow?: 'hidden' | 'auto'; // Control vertical overflow behavior
    footer?: React.ReactNode; // Optional footer element
    noFooterPadding?: boolean;
    contentClassName?: string;
    footerPaddingClassName?: string;
    isHidden?: boolean;
}

const LayoutView: React.FC<LayoutViewProps> = ({ children, overflow = 'hidden', footer, isHidden, noFooterPadding, contentClassName, footerPaddingClassName }) => {
    const { isKeyboardVisible } = useKeyboard();

    useEffect(() => {
    }, [isKeyboardVisible]);

    return (
        <>
            <div
                className={
                    `layoutView
                    ${styles.screenView}
                    ${overflow === 'hidden' ? styles.overflowHidden : styles.overflowAuto}
                    ${isKeyboardVisible ? styles.keyboardVisible : ''}
                    ${isHidden ? styles.hidden : ""}
                    `}>
                <div className={`${styles.content} ${overflow === 'hidden' ? styles.overflowHiddenContent : ''} ${contentClassName ?? ''}`}>{children}</div>
                {footer && <div className={`${styles.footer} ${noFooterPadding ? styles.noPadding : ""}`}>{footer}</div>}
                {footer && noFooterPadding && isIOS() && <div className={`${styles.iosBottomPadding} ${footerPaddingClassName ?? ''}`}></div>}
            </div>
        </>
    );
};

export default LayoutView;
