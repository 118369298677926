import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useGetCaretakerRequestQuery, useCreateCaretakerResponseMutation, CaretakerResponseType, CaretakerResponse, useUpdateCaretakerResponseMutation } from "../gql/types"; // Adjust path as needed
import CaretakerRequestRecipientView from "../components/events/CaretakerRequestRecipientView";
import SplashScreen from "../components/introduction/SplashScreen";
import CaretakerResponseScreen from "../components/events/CaretakerResponseScreen";
import CaretakerResponseCompleteScreen from "src/components/events/CaretakerResponseCompleteScreen";
import CaretakerRequestRecipientRespondedView from "src/components/events/CaretakerRequestRecipientRespondedView";
import CaretakerRequestRecipientResponseChangeView from "src/components/events/CaretakerRequestRecipientResponseChangeView";

const CaretakerRequestRecipientPage: React.FC = () => {
    const { access_token } = useParams<{ access_token: string }>(); // Get access_token from the route
    const navigate = useNavigate();

    const [hasVerifiedContactInfo, setHasVerifiedContactInfo] = useState(false);
    const [showResponseScreen, setShowResponseScreen] = useState(false); // State to manage which screen to show
    const [responseType, setResponseType] = useState<"accept" | "reject" | null>(null);

    // Fetch caretaker request data using the provided access token
    const { data, loading, error } = useGetCaretakerRequestQuery({
        variables: { token: access_token, responseType: CaretakerResponseType.Accept },
        context: { oneOffToken: access_token },
        fetchPolicy: 'network-only',
        skip: !access_token, // Skip query if access_token is not provided
    });

    // Mutation for creating caretaker response
    // TODO: change to fetch the accept response on demand instead
    const [createCaretakerResponse, { loading: mutationLoading, error: mutationError }] =
        useCreateCaretakerResponseMutation({
            context: { oneOffToken: access_token },
        });

    const [updateCaretakerResponse, { loading: updateLoading, error: updateError }] =
        useUpdateCaretakerResponseMutation({
            context: { oneOffToken: access_token },
        });

    useEffect(() => {
        // Redirect to landing page if no access_token or other errors
        if (!access_token || error || (!loading && !data?.getCaretakerRequest)) {
            console.log("Redirecting to landing page due to error or missing access_token");
            navigate("/landing-page");
        }
    }, [access_token, error, data, navigate]);

    if (loading) return <SplashScreen />; // Show a loading spinner

    let request = data?.getCaretakerRequest;

    if (!request) {
        return null;
    }

    let acceptedResponse: CaretakerResponse | null | undefined;

    if (request.filteredResponses && request.filteredResponses.length > 0) {
        acceptedResponse = request.filteredResponses.at(0);
    }

    // Handlers for mutations
    const handleConfirm = async (name: string, phone?: string) => {
        if (!request) {
            return;
        }
        try {
            const response = await createCaretakerResponse({
                variables: {
                    input: {
                        requestID: request.requestID,
                        caretakerDetails: {
                            name,
                            contactInfo: phone || "",
                        },
                        responseType: CaretakerResponseType.Accept,
                    },
                },
            });
            console.log("Confirmed childcare responsibility:", response.data);
            setResponseType("accept");
        } catch (e) {
            console.error("Error confirming caretaker responsibility:", e);
        }
    };

    const handleDecline = async (name: string) => {
        if (!request) {
            return;
        }
        try {
            const response = await createCaretakerResponse({
                variables: {
                    input: {
                        requestID: request.requestID,
                        caretakerDetails: {
                            name,
                            contactInfo: "",
                        },
                        responseType: CaretakerResponseType.Reject,
                    },
                },
            });
            console.log("Declined childcare responsibility:", response.data);
            setResponseType("reject");
        } catch (e) {
            console.error("Error declining caretaker responsibility:", e);
        }
    };

    // Handler for responding to the request
    const handleRespond = () => {
        setShowResponseScreen(true);
    };

    const handleReconfirm = () => {
        setResponseType("accept");
    }

    const handleBackout = async () => {
        if (!acceptedResponse) {
            return;
        }
        try {
            const response = await updateCaretakerResponse({
                variables: {
                    input: {
                        responseID: acceptedResponse.responseID,
                        newResponseType: CaretakerResponseType.Reject,
                    },
                },
            });
            console.log("Declined childcare responsibility:", response.data);
            setResponseType("reject");
        } catch (e) {
            console.error("Error declining caretaker responsibility:", e);
        }

        setResponseType("reject");
    }

    return (
        <>
            {responseType ? (
                <CaretakerResponseCompleteScreen
                    request={request}
                    hasAccepted={responseType === "accept"}
                />
            ) : showResponseScreen ? (
                <CaretakerResponseScreen onConfirm={handleConfirm} onDecline={handleDecline} />
            ) : acceptedResponse ? (
                !hasVerifiedContactInfo
                    ? <CaretakerRequestRecipientRespondedView request={request} response={acceptedResponse} onUpdate={() => setHasVerifiedContactInfo(true)} />  // Show this if there is a positive response
                    : <CaretakerRequestRecipientResponseChangeView request={request} response={acceptedResponse} onConfirm={handleReconfirm} onDecline={handleBackout} />
            ) : (
                <CaretakerRequestRecipientView request={request} onRespond={handleRespond} />
            )}
        </>
    );
};

export default CaretakerRequestRecipientPage;
