import React from "react";
import styles from "./TextInpuField.module.css"; // Ensure styles are imported
import inlineErrorIcon from "../../assets/icons/inlineErrorIcon.svg";

interface ErrorUnitProps {
    errorStr: string | null;
    isFocused?: boolean;
}

const ErrorUnit: React.FC<ErrorUnitProps> = ({
    errorStr,
    isFocused,
}) => {
    return (
        <div className={styles.errorMessage}>
            {!isFocused && errorStr ? (
                <>
                    <img src={inlineErrorIcon} className={styles.errorIcon} />
                    {errorStr}
                </>
            ) : (
                <span className={styles.placeholderSpace}>&nbsp;</span>
            )}
        </div>
    );
};

export default ErrorUnit;
