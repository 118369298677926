import { FirebaseAuthentication } from "@capacitor-firebase/authentication";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "src/config/firebase";
import { isNative } from "src/platform/platform";

export interface FirebaseSignInResult {
    emailVerified: boolean;
    idToken: string;
}

export async function firebaseSignIn(email: string, password: string): Promise<FirebaseSignInResult> {
    if (isNative()) {
        const userCredential = await FirebaseAuthentication.signInWithEmailAndPassword({ email, password });

        if (!userCredential.user) {
            throw Error("authentication failed");
        }

        if (!userCredential.user.emailVerified) {
            return {
                emailVerified: false,
                idToken: "",
            }
        }

        const tokenResult = await FirebaseAuthentication.getIdToken();
        return {
            emailVerified: true,
            idToken: tokenResult.token,
        };
    }
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    await userCredential.user.reload();

    if (!userCredential.user.emailVerified) {
        return {
            emailVerified: false,
            idToken: "",
        }
    }
    const idToken = await userCredential.user.getIdToken(true);
    return {
        emailVerified: true,
        idToken: idToken,
    };
}