import React from 'react';
import ReactDOM from 'react-dom/client'; // Ensure you're importing from 'react-dom/client'
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache, NormalizedCacheObject, ApolloLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { BrowserRouter as Router } from 'react-router-dom';
import { configureApiBaseUrl } from './config/api';
import { configureRedirectBaseUrl } from './config/web';
import { checkAuthentication } from './auth/jwt'; // your auth utilities
import App from './App';
import './index.css';
import { onError } from '@apollo/client/link/error';
import { isNative, isWeb } from './platform/platform';
import { LOCAL_MODE } from './config/local';
import { KeyboardProvider } from './contexts/KeyboardContext';
import { mockGraphQLLink } from './graphql/links/mockLink';

// Asynchronous function to initialize the app
const initializeApp = async () => {
  const API_BASE_URL = await configureApiBaseUrl();
  await configureRedirectBaseUrl();
  const GRAPHQL_BASE_URL = `${API_BASE_URL}/api/graphql`;

  console.log(GRAPHQL_BASE_URL);

  // HTTP Link for Apollo Client
  const httpLink = new HttpLink({
    uri: GRAPHQL_BASE_URL,
  });

  // Auth Link to add JWT tokens to request headers
  const authLink = setContext(async (_, { headers, ...context }) => {
    const oneOffToken = context.oneOffToken || null;

    if (isWeb()) {
      console.log('Web environment detected. Skipping Authorization header.');
      return {
        headers: {
          ...headers,
          ...(oneOffToken ? { 'X-OneOff-Token': oneOffToken } : {}),
        },
      };
    }

    let token: string | null = null;

    try {
      // Check for authentication tokens without forcing a redirect
      const isAuthenticated = await checkAuthentication();

      if (isAuthenticated) {
        const tokenResult = await SecureStoragePlugin.get({ key: 'access_token' });
        token = tokenResult.value;
      } else {
        console.warn('User not authenticated. Proceeding without Authorization header.');
      }
    } catch (error) {
      console.error('Error retrieving access token:', error);
    }

    return {
      headers: {
        ...headers,
        Authorization: token ? `Bearer ${token}` : '',
        ...(oneOffToken ? { 'X-OneOff-Token': oneOffToken } : {}),
      },
    };
  });


  // Error handling link
  const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
    if (LOCAL_MODE) {
      return;
    }
    // Check for the custom flag in the query's context
    const skip401Redirect = operation.getContext().skip401Redirect;

    // Handle GraphQL errors
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        if (error.extensions?.code === 'UNAUTHENTICATED' && !skip401Redirect) {
          console.warn('GraphQL 401 Unauthorized. Redirecting to login...');
          window.location.href = '/auth/login';
          return;
        }
      }
    }

    // Handle network errors (e.g., HTTP 401)
    if (networkError) {
      const statusCode = (networkError as any)?.response?.status;

      if (statusCode === 401 && !skip401Redirect) {
        console.warn('HTTP 401 Unauthorized. Redirecting to login...');
        window.location.href = '/auth/login';
        return;
      } else if (statusCode === 401 && skip401Redirect) {
        console.warn('Skipping redirect for fetchUser query (401 Unauthorized).');
      } else {
        console.error('Network error:', networkError);
      }
    }
  });

  // Create Apollo Client
  const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
    link: ApolloLink.from([
      mockGraphQLLink,
      errorLink.concat(authLink).concat(httpLink),
    ]),
    cache: new InMemoryCache({
      typePolicies: {
        Event: {
          keyFields: ["eventID"], // ✅ Tell Apollo to use eventID as the unique identifier
        },
      },
    }),
  });

  const rootElement = document.getElementById('root') as HTMLElement;

  if (rootElement && isNative()) {
    rootElement.classList.add('iosTopPadding'); // Apply padding if on native
  }
  // Create the root for rendering in React 18+
  const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <React.StrictMode>
      <ApolloProvider client={client}>
        <KeyboardProvider>
          <Router>
            <App />
          </Router>
        </KeyboardProvider>
      </ApolloProvider>
    </React.StrictMode>
  );
};

// Call the initialization function
initializeApp();
