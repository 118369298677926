export enum ActionType {
    INVALID = "INVALID",
    CARETAKER = "CARETAKER",
    CARETAKER_VIEW = "CARETAKER_VIEW",
    NAVIGATION = "NAVIGATION",
}

export enum CaretakerSelectionValues {
    SELF = "SELF",
    SPOUSE = "SPOUSE",
    OTHER = "OTHER",
    REQUEST = "REQUEST",
    SKIP = "SKIP",
}