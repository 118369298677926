import React from "react";
import Layout from "../layout/Layout";

interface BaseViewProps {
    children: React.ReactNode; // Content to display in the main area
    title?: string; // Optional title for the header/navbar
    onBack?: () => void; // Optional back button handler
    footer?: React.ReactNode; // Optional footer element
}

const BaseView: React.FC<BaseViewProps> = ({ children, title = "Title", onBack, footer }) => {
    return (
        <Layout
            navbarConfig={{ title, onBack }}
            footer={footer}
            overflow="auto" // Allow scrolling if content exceeds screen height
        >
            {children}
        </Layout>
    );
};

export default BaseView;
