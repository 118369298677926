import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import NameStep from './NameStep';
import KidsCountStep from './KidsCountStep';
import ChildInfoStep from './ChildInfoStep'; // Reusable step for child info
import PartnerEmailStep from './PartnerEmailStep';
import { User } from '../../gql/types';
import ProfilePictureStep from './ProfilePictureStep';
import { useNavigate } from 'react-router-dom';
import { SAVE_USER_SPOUSE_EMAIL } from '../../graphql/mutations/saveUserSpouseEmail';
import { MARK_ONBOARDING_COMPLETE } from '../../graphql/mutations/markOnboardingComplete';
import { SAVE_USER_PROFILE_PICTURE } from '../../graphql/mutations/saveUserProfilePicture';
import { UPSERT_CHILDREN } from '../../graphql/mutations/upsertChildren';
import { LOCAL_MODE } from '../../config/local';
import CalendarSyncStep from './CalendarSyncStep';
import CalendarListSelectionStep from './CalendarListSelectionStep';
import { appEvents } from '../../App';
import { AppEventType } from '../../events/app';
import { CalendarSourceType } from '../../types/Calendar';
import { UserData, ChildInfo, OnboardingSteps } from './types';

interface OnboardingFlowProps {
  user: User | null;
}

const OnboardingFlow: React.FC<OnboardingFlowProps> = ({ user }) => {
  const totalSteps = 5;
  const stepMap = {
    [OnboardingSteps.NAME]: 1,
    [OnboardingSteps.CALENDAR_SYNC]: 2,
    [OnboardingSteps.PARTNER_EMAIL]: 3,
    [OnboardingSteps.KIDS_COUNT]: 4,
    [OnboardingSteps.CHILD_INFO]: 4,
    [OnboardingSteps.PROFILE_PICTURE]: 5,
  };

  // Load step and data from sessionStorage if available
  const [step, setStep] = useState(() => {
    const savedState = sessionStorage.getItem('onboardingState');
    console.log(savedState);
    return savedState ? JSON.parse(savedState).step : 1;
  });

  const [currentChild, setCurrentChild] = useState<number>(() => {
    const savedState = sessionStorage.getItem('onboardingState');
    return savedState ? JSON.parse(savedState).currentChild : -1;
  });

  const [calendarAuthParams, setCalendarAuthParams] = useState<{ source: CalendarSourceType; account: string; label?: string } | null>(null);

  const [userData, setUserData] = useState<UserData>(() => {
    const savedState = sessionStorage.getItem('onboardingState');
    return savedState
      ? JSON.parse(savedState).userData
      : {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        profilePictureUrl: user?.profilePictureUrl || '',
        kidsCount: -1,
        children: [],
        partnerEmail: '',
      };
  });

  const navigate = useNavigate();

  const saveState = (nextStep: number) => {
    const state = { step: nextStep, userData, currentChild };
    sessionStorage.setItem('onboardingState', JSON.stringify(state));
  };

  const [markOnboardingComplete] = useMutation(MARK_ONBOARDING_COMPLETE, {
    onCompleted: (data) => {
      if (data?.markOnboardingComplete?.completed) {
        console.log('onboarding has completed');
        appEvents.emit(AppEventType.RELOAD_USER);
        navigate('/');
      } else {
        navigate(`?error=true&message=${encodeURIComponent('Failed to proceed. Please try again')}`);
      }
    },
    onError: (error) => {
      console.error('Failed to mark onboarding completed', error);
      navigate(`?error=true&message=${encodeURIComponent('Failed to proceed. Please try again')}`);
    },
  });

  const updateAndSaveStep = (currentStep: OnboardingSteps) => {
    // mark onboarding completed
    if (totalSteps <= stepMap[currentStep]) {
      markOnboardingComplete();
      return;
    }
    setStep(stepMap[currentStep] + 1);
    saveState(stepMap[currentStep] + 1);
  }

  useEffect(() => {
    const savedState = sessionStorage.getItem('onboardingState');

    if (savedState) {
      const { step, userData } = JSON.parse(savedState);
      setStep(step);
      setUserData(userData);
    }

    const queryParams = new URLSearchParams(window.location.search);
    const success = queryParams.get('calendarAuthSuccess') === 'true';
    if (success) {
      const sourceParam = queryParams.get('source');
      const accountParam = queryParams.get('account');
      queryParams.delete('calendarAuthSuccess');
      queryParams.delete('source');
      queryParams.delete('account');
      window.history.replaceState({}, '', `${window.location.pathname}?${queryParams.toString()}`);

      const source = Object.values(CalendarSourceType).includes(sourceParam as CalendarSourceType)
        ? (sourceParam as CalendarSourceType)
        : undefined;

      if (!source || !accountParam) {
        console.error('Invalid calendar source provided:', sourceParam);
        return;
      } else {
        console.log('Valid calendar source:', source);
      }
      setCalendarAuthParams({
        source: source,
        account: accountParam,
      });
    }

    const listener = (eventParams: { source: CalendarSourceType; account: string; label: string }) => {
      setCalendarAuthParams(eventParams);
    };

    appEvents.on(AppEventType.CALENDAR_UPDATED, listener);

    return () => {
      appEvents.off(AppEventType.CALENDAR_UPDATED, listener);
    };
  }, []);

  const [saveUserSpouseEmail] = useMutation(SAVE_USER_SPOUSE_EMAIL, {
    onCompleted: async (data) => {
      console.log('User spouse saved successfully');
      setUserData((prev) => ({ ...prev, spouseEmail: data?.saveUserSpouseEmail?.spouseEmail }));
      updateAndSaveStep(OnboardingSteps.PARTNER_EMAIL);
    },
    onError: (error) => {
      console.error('Failed to save spouse email.', error);
      navigate(`?error=true&message=${encodeURIComponent('Failed to save the information. Please try again.')}`);
    },
  });

  const [saveUserProfilePicture, { loading, error }] = useMutation(SAVE_USER_PROFILE_PICTURE, {
    onCompleted: async (data) => {
      // Proceed to the next step on success
      setUserData((prev) => ({ ...prev, profilePictureUrl: data?.saveUserProfilePicture?.profilePictureUrl }));
      console.log('profile picture saved successfully');
      console.log(data?.saveUserProfilePicture?.profilePictureUrl);
      updateAndSaveStep(OnboardingSteps.PROFILE_PICTURE);
    },
    onError: (err) => {
      // Handle errors
      console.error('Error saving profile picture:', err);
      navigate(`?error=true&message=${encodeURIComponent('Failed to save the profile picture. Please try again.')}`);
    },
  });

  const handleBack = () => {
    if (step === stepMap[OnboardingSteps.CHILD_INFO] && currentChild >= 0) {
      setCurrentChild((prevChild) => Math.max(-1, prevChild - 1));
      return;
    }
    const newStep = Math.max(0, step - 1);
    saveState(newStep);
    setStep(newStep);
  };

  const handlePartnerNext = (partnerEmail: string) => {
    if (LOCAL_MODE) {
      updateAndSaveStep(OnboardingSteps.PARTNER_EMAIL);
      return;
    }

    saveUserSpouseEmail({
      variables: {
        spouseEmailInput: partnerEmail,
      },
    });
  };

  const handleSkipPartner = () => {
    updateAndSaveStep(OnboardingSteps.PARTNER_EMAIL);
  };

  const handleNameNext = (name: string) => {
    setUserData((prev) => ({ ...prev, name }));
    updateAndSaveStep(OnboardingSteps.NAME);
  };

  const handleKidsNext = (kidsCount: number) => {
    setUserData((prev) => {
      const existingChildren = prev.children.slice(0, kidsCount); // Keep only the first `kidsCount` elements
      const additionalChildren = Array(kidsCount - existingChildren.length)
        .fill({ name: '', gender: '', birthDate: '', schoolName: '', className: '' });
      return {
        ...prev,
        kidsCount,
        children: [...existingChildren, ...additionalChildren], // Combine existing and new elements
      };
    });

    setCurrentChild(0); // Start child info substeps
    saveState(stepMap[OnboardingSteps.CHILD_INFO]);
  };

  const [upsertChildren] = useMutation(UPSERT_CHILDREN, {
    onCompleted: () => {
      console.log('User Children saved successfully');
      updateAndSaveStep(OnboardingSteps.CHILD_INFO);
    },
    onError: (error) => {
      console.error('Failed to save children info.', error);
      navigate(`?error=true&message=${encodeURIComponent('Failed to save the information. Please try again.')}`);
    },
  });

  const handleChildInfoNext = async (childInfo: ChildInfo) => {
    let updatedUserData: UserData = {
      firstName: '',
      lastName: '',
      children: [] as ChildInfo[],
      profilePictureUrl: '',
      kidsCount: -1,
      partnerEmail: '',
    };
    setUserData((prev) => {
      const updatedChildren = [...prev.children];
      updatedChildren[currentChild] = childInfo;
      updatedUserData = { ...prev, children: updatedChildren };
      return updatedUserData;
    });

    if (currentChild + 1 < userData.kidsCount) {
      setCurrentChild((prev) => prev + 1); // Move to the next child info substep
      saveState(stepMap[OnboardingSteps.CHILD_INFO]);
    } else {
      const childrenData = updatedUserData.children.map((child: ChildInfo) => ({
        childID: null, // Add childID for existing children
        name: child.name,
        gender: child.gender,
        birthday: child.birthDate,
        currentSchool: {
          schoolName: child.schoolName,
          className: child.className || null,
          startDate: null,
          endDate: null,
        },
      }));

      if (LOCAL_MODE) {
        updateAndSaveStep(OnboardingSteps.CHILD_INFO);
        return;
      }

      upsertChildren({
        variables: {
          children: childrenData, // Pass the children array as the mutation variables
        },
      });
    }
  };

  const handleProfilePictureNext = (profilePictureURL: string) => {

    if (LOCAL_MODE) {
      updateAndSaveStep(OnboardingSteps.PROFILE_PICTURE);
      return;
    }

    // Execute the mutation
    saveUserProfilePicture({
      variables: {
        profilePictureURLInput: profilePictureURL,
      },
    });
    setUserData((prev) => ({ ...prev, profilePictureUrl: profilePictureURL }));
  };

  const handleCalendarSyncNext = (calendar: string) => {
    updateAndSaveStep(OnboardingSteps.CALENDAR_SYNC);
  };


  const handleSkipProfilePicture = () => {
    updateAndSaveStep(OnboardingSteps.PROFILE_PICTURE);
  };

  const handleSkipCalendarListSelection = () => {
    setCalendarAuthParams(null);
  };

  const handleContinueCalendarListSelection = (selectedCalendars: any) => {
    console.log('Selected calendars:', selectedCalendars);
    setCalendarAuthParams(null);
  };

  const onboardingContext = {
    totalSteps: totalSteps,
    stepNumber: step,
    onBack: step > 1 ? handleBack : undefined,
  };

  return (
    <>
      {step === stepMap[OnboardingSteps.NAME] && (
        <NameStep
          onboardingContext={onboardingContext}
          onNext={handleNameNext}
          existingValues={{
            firstName: user?.firstName || '',
            lastName: user?.lastName || '',
          }} />)}
      {step === stepMap[OnboardingSteps.KIDS_COUNT] && currentChild === -1 && (
        <KidsCountStep
          onboardingContext={onboardingContext}
          onNext={handleKidsNext}
        />
      )}
      {step === stepMap[OnboardingSteps.CHILD_INFO] && currentChild >= 0 && (
        <ChildInfoStep
          onboardingContext={onboardingContext}
          childIndex={currentChild}
          onNext={handleChildInfoNext}
          existingValues={userData.children[currentChild] || {}}
        />
      )}
      {step === stepMap[OnboardingSteps.PARTNER_EMAIL] && (
        <PartnerEmailStep
          onboardingContext={onboardingContext}
          onNext={handlePartnerNext}
          onSkip={handleSkipPartner}
          existingValue={user?.spouseEmail ?? ""} />
      )}
      {step === stepMap[OnboardingSteps.PROFILE_PICTURE] && (
        <ProfilePictureStep
          onboardingContext={onboardingContext}
          onNext={handleProfilePictureNext}
          onSkip={handleSkipProfilePicture}
          existingValue={user?.profilePictureUrl ?? ""}
        />
      )}
      {step === stepMap[OnboardingSteps.CALENDAR_SYNC] && calendarAuthParams && (
        <CalendarListSelectionStep
          onboardingContext={onboardingContext}
          source={calendarAuthParams.source}
          account={calendarAuthParams.account}
          label={calendarAuthParams.label}
          onNext={handleContinueCalendarListSelection}
          onSkip={handleSkipCalendarListSelection}
        />
      )}
      {step === stepMap[OnboardingSteps.CALENDAR_SYNC] && !calendarAuthParams && (
        <CalendarSyncStep
          onboardingContext={onboardingContext}
          onNext={handleCalendarSyncNext}
        />
      )}
    </>
  );
};

export default OnboardingFlow;
