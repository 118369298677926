import React from "react";
import BaseActionSheet, { ButtonGroup } from "./BaseActionSheet";
import { ActionType } from "./types";

type NavigationActionSheetProps = {
    isVisible: boolean;
    onClose: () => void;
    onNavigate: (actionType: ActionType) => void; // Callback for navigation
    preloadData?: {
        navigationOptions?: ActionType[]; // Array of `ActionType` for navigation
    };
};

const actionLabels: Record<ActionType, string> = {
    [ActionType.CARETAKER]: "Update Childcare Provider",
    [ActionType.CARETAKER_VIEW]: "View Caretaker Request",
    // [ActionType.CARETAKER_CREATE]: "Add New Provider",
    [ActionType.INVALID]: "Invalid Option",
    [ActionType.NAVIGATION]: "Invalid Option",
};

const NavigationActionSheet: React.FC<NavigationActionSheetProps> = ({
    isVisible,
    onClose,
    onNavigate,
    preloadData,
}) => {
    const actions: ButtonGroup[] = (preloadData?.navigationOptions || []).map((actionType) => ([{
        label: actionLabels[actionType] || "Unknown Option",
        value: actionType,
        onClick: () => onNavigate(actionType),
    }]));

    return (
        <BaseActionSheet
            isVisible={isVisible}
            onClose={onClose}
            headerText="What would you like to do?"
            actions={actions} // Single group of buttons
        />
    );
};

export default NavigationActionSheet;
