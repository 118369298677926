import React, { useState, useEffect } from 'react';
import styles from './ActionButton.module.css';
import googleLogo from '../../assets/google-icon.svg';
import appleLogo from '../../assets/apple-icon-black.svg';
import Spinner from './Spinner';
import { appEvents } from 'src/App';
import { AppEventType } from 'src/events/app';

interface ActionButtonProps {
    onClick: () => void; // Function to execute on click
    children: React.ReactNode; // Button content (e.g., "Log in", "Submit")
    disabled?: boolean; // Optional: Disable the button
    hasDataLoad?: boolean; // Optional: if fetching data, set this to add spinner effect after click.
    className?: string;
    colorPattern?: 'Default' | 'Light Brown' | undefined;
    iconType?: 'Google' | 'Apple' | undefined;
}

const ActionButton: React.FC<ActionButtonProps> = ({ onClick, children, colorPattern = 'Default', disabled = false, iconType = undefined, className = '', hasDataLoad }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [disabledAfterClick, setDisabledAfterClick] = useState<boolean>(false);

    useEffect(() => {
        const resetState = () => {
            setLoading(false);
            setDisabledAfterClick(false);

            document.querySelectorAll("button").forEach((btn) => {
                btn.blur();
            });
        };

        window.addEventListener("pageshow", resetState);
        appEvents.on(AppEventType.RESET_ACTION_BUTTON, resetState);
        return () => {
            window.removeEventListener("pageshow", resetState);
            appEvents.off(AppEventType.RESET_ACTION_BUTTON, resetState);
        };
    }, []);

    const renderIcon = (iconType?: 'Google' | 'Apple') => {
        switch (iconType) {
            case 'Google':
                return <img src={googleLogo} alt="Google Icon" className={styles.icon} />;
            case 'Apple':
                return <img src={appleLogo} alt="Apple Icon" className={styles.appleIcon} />;
            default:
                return null;
        }
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onClick();
        setDisabledAfterClick(true);
        if (hasDataLoad) {
            setLoading(true);
        }
        event.currentTarget.blur(); // Removes focus after clicking
    };

    return (
        <button
            type="button"
            className={`${styles.actionButton} ${disabled || disabledAfterClick ? styles.disabled : ''} ${colorPattern == 'Light Brown' ? styles.lightBrown : ''} ${className}`}
            onClick={handleClick}
            disabled={disabled || disabledAfterClick}
        >
            {loading
                ? <Spinner className={styles.icon} />
                : (iconType && renderIcon(iconType))}
            {children}
        </button>
    );
};

export default ActionButton;
