import React from "react";
import Select, { SingleValue } from "react-select";
import styles from "./CaretakerDropdown.module.css";
import noUser from "../../assets/no-user.png";
import { StylesConfig } from "react-select";
import { baseSelectStyles } from "./SelectStyles";
import { SingleValueProps } from "react-select";

interface CaretakerOption {
    value: string; // Unique ID
    label: string; // Display name
    profilePictureUrl?: string; // Optional profile picture
    userType: "SELF" | "SPOUSE" | "OTHER" | "EXTERNAL"; // Caretaker type
}

export type Caretaker = {
    id: string;
    name: string;
    profilePictureUrl?: string;
    userType: "SELF" | "SPOUSE" | "OTHER" | "EXTERNAL";
};

export type CaretakerDropdownProps = {
    caretakers: Caretaker[];
    selectedCaretakerId: string | null;
    onSelectCaretaker: (caretakerId: string) => void;
};

const caretakerSelectStyles: StylesConfig<CaretakerOption, false> = {
    control: (base, state) => ({
        ...base,
        backgroundColor: state.isFocused ? "rgba(47, 28, 10, 0.1)" : "#fff",
        color: "#2F1C0A",
        border: "none", // No visible border
        borderRadius: "0.5rem",
        boxShadow: "none",
        padding: "0",
        fontSize: "1rem",
        width: "100%",
        boxSizing: "border-box",
    }),
    valueContainer: (base) => ({
        ...base,
        display: "flex",
        alignItems: "center",
        flexDirection: "row", // Ensure horizontal alignment
        justifyContent: "flex-start",
        padding: "0 8px", // Add some padding
        width: "100%",
        overflow: "visible",
        boxSizing: "border-box",
    }),
    singleValue: (base) => ({
        ...base,
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        gap: "10px",
        color: "#2F1C0A",
        fontWeight: "bold",
        width: "100%",
        boxSizing: "border-box",
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        display: "flex",
        boxSizing: "border-box",
        alignItems: "center",
        gap: "10px",
        backgroundColor: isFocused || isSelected ? "#2F1C0A" : "#fff",
        color: isFocused || isSelected ? "#fff" : "#2F1C0A",
        padding: "0.5rem",
        cursor: "pointer",
        transition: "all 0.3s ease-in-out", // Smooth hover/selection effect
        transform: isSelected ? "scale(1.02)" : "scale(1)", // Slight zoom effect
    }),
    input: (base: any) => ({
        ...base,
        caretColor: "transparent",
        margin: "0",
        padding: "0",
        flex: "1 1 auto", // Allow the input to shrink and grow
        height: "0", // Match container height
    }),
    menu: (base) => ({
        ...base,
        width: "100%", // Ensure the dropdown menu matches the control width
        boxSizing: "border-box",
        overflowX: "hidden",
        maxWidth: "100%",
        padding: "0",
        margin: "0",
    }),
    indicatorSeparator: () => ({
        display: "none",
    }),
};

const CustomSingleValue: React.FC<SingleValueProps<CaretakerOption, false>> = React.memo(({ data }) => {
    return (
        <div className={styles.optionRow}>
            <img
                src={data.profilePictureUrl && data.profilePictureUrl !== "" ? data.profilePictureUrl : noUser}
                alt={data.label}
                className={styles.profilePicture}
            />
            <div>
                <span className={styles.name}>{data.label}</span>
            </div>
        </div>
    );
});

const CustomOption: React.FC<CaretakerOption> = React.memo(({ profilePictureUrl, label }) => {
    return (
        <div className={styles.optionRow}>
            <img
                src={profilePictureUrl || noUser}
                alt={label}
                className={styles.profilePicture}
            />
            <div>
                <span className={styles.name}>{label}</span>
            </div>
        </div>
    );
});

const CaretakerDropdown: React.FC<CaretakerDropdownProps> = React.memo(({
    caretakers,
    selectedCaretakerId,
    onSelectCaretaker,
}) => {
    const options = React.useMemo(() => caretakers.map((caretaker) => ({
        value: caretaker.id,
        label: caretaker.name,
        profilePictureUrl: caretaker.profilePictureUrl,
        userType: caretaker.userType,
    })),
        [caretakers],
    );

    return (
        <div className={styles.caretakerContainer}>
            <Select<CaretakerOption>
                classNamePrefix={"CaretakerDropdown"}
                options={options}
                value={options.find((opt) => opt.value === selectedCaretakerId) || null}
                onChange={(selectedOption: SingleValue<CaretakerOption>) => {
                    if (selectedOption) {
                        onSelectCaretaker(selectedOption.value);
                    }
                }}
                formatOptionLabel={(option) => <CustomOption {...option} />}
                components={{ SingleValue: CustomSingleValue }}
                styles={caretakerSelectStyles}
                isClearable={false}
                className={styles.dropdown}
            />
        </div>
    );
});

export default CaretakerDropdown;
