import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./CaretakerRequestRecipientRespondedView.module.css";
import DigestHeader from "../digest/DigestHeader";
import MainEventItem from "../digest/MainEventItem";
import warningIcon from "../../assets/icons/warning.svg";
import Layout from "../layout/Layout";
import { CaretakerRequest, CaretakerResponse } from "../../gql/types";
import { AttentionLevelType } from "../../gql/types";
import MaskedInput from "react-text-mask"; // Import MaskedInput

type CaretakerRequestRecipientRespondedViewProps = {
    request: CaretakerRequest;
    response: CaretakerResponse;
    onUpdate: () => void; // Confirm handler
};

const CaretakerRequestRecipientRespondedView: React.FC<CaretakerRequestRecipientRespondedViewProps> = ({
    request,
    response,
    onUpdate,
}) => {
    const [phone, setPhone] = useState('');
    const navigate = useNavigate();

    const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericOnlyValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
        setPhone(numericOnlyValue);
    };

    const itemHeader = request.requestContext?.contextHeader;
    const attentionLevel = request.requestContext?.contextHeader?.attentionLevel ?? AttentionLevelType.Informational;
    const eventDetails = request.requestContext?.eventDetails;
    const eventItem = {
        "title": eventDetails?.title ?? "",
        "startDate": eventDetails?.startDate ?? "",
        "endDate": eventDetails?.endDate ?? "",
        "eventID": "placeholderID",
    }

    const handleVerify = () => {
        // TODO: ask for verification
        if (phone.length > 0 && phone == response.responder?.contactInfo) {
            onUpdate();
        } else {
            navigate(`?error=true&message=${encodeURIComponent('Your number does not match the number in our systems.')}`);
        }
    };

    return (
        <Layout
            overflow="auto" // Allow scrolling if content exceeds screen height
        >
            <div className={styles.recipientViewContainer}>
                <DigestHeader
                    topTitle={itemHeader?.topTitle ?? ""}
                    mainTitle={itemHeader?.mainTitle ?? ""}
                    attentionLevel={attentionLevel}
                    description={itemHeader?.description ?? undefined}
                />

                {eventDetails &&
                    <MainEventItem event={eventItem} isEventInteractable={false} />}
                <div className={styles.bottomSheetContainer}>
                    <div className={styles.bottomSheet}>
                        <div className={styles.topSection}>
                            <div className={styles.iconContainer}>
                                <div className={styles.warningIconContainer}>
                                    <img src={warningIcon} className={styles.warningIcon} />
                                </div>
                            </div>
                            <div className={styles.infoSection}>
                                This request has already been accepted.
                                Are you here to update your RSVP?
                            </div>
                        </div>
                        <div className={styles.bottomSection}>
                            <div className={styles.sectionHeader}>Phone Number:</div>
                            <MaskedInput
                                mask={phoneMask}
                                className={styles.input}
                                value={phone}
                                onChange={handlePhoneChange}
                                placeholder="Enter your phone number"
                            />
                            <div className={styles.buttonContainer}>
                                <button
                                    className={styles.submitButton}
                                    onClick={handleVerify}
                                >
                                    Update my RSVP
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default CaretakerRequestRecipientRespondedView;
