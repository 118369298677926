import React from "react";
import BaseActionSheet from "./BaseActionSheet";
import { CaretakerDetails } from "../../gql/types";
import noUser from "../../assets/no-user-purple.svg";

type CaretakerOtherConfirmSheetProps = {
    isVisible: boolean;
    caretakers: CaretakerDetails[];
    selectedCaretaker: CaretakerDetails;
    onClose: () => void;
    onConfirm: (selectedCaretaker: CaretakerDetails) => void;
    onBack: () => void;
    onCaretakerChange: (value: string) => void;
};

const CaretakerOtherConfirmSheet: React.FC<CaretakerOtherConfirmSheetProps> = ({
    isVisible,
    caretakers,
    selectedCaretaker,
    onClose,
    onConfirm,
    onBack,
    onCaretakerChange,
}) => {
    const caretakerSelectorOptions = caretakers.map((caretaker) => ({
        label: caretaker.name,
        value: caretaker.userID || caretaker.name,
        profilePictureUrl: noUser,
    }));

    const actions = [
        [
            {
                label: selectedCaretaker.name,
                value: "caretakerSelector",
                selectorOptions: caretakerSelectorOptions,
                onSelectorChange: onCaretakerChange,
            },
        ],
        [
            { label: "Confirm", value: "confirm", onClick: () => onConfirm(selectedCaretaker), isPrimary: true },
        ],
        [
            { label: "Back to More Options", value: "back", onClick: onBack },
        ],
    ];

    return (
        <BaseActionSheet
            isVisible={isVisible}
            onClose={onClose}
            headerText="Would you like to set this person as the childcare provider for the event?"
            actions={actions}
            animate={false}
        />
    );
};

export default CaretakerOtherConfirmSheet;
