import { TimeOption } from "./types";

export const generateTimeOptions = (): TimeOption[] => {
    const times: TimeOption[] = [];
    for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 30) {
            const isPM = hour >= 12;
            const adjustedHour = hour % 12 || 12;
            const label = `${adjustedHour.toString().padStart(2, "0")}:${minute
                .toString()
                .padStart(2, "0")} ${isPM ? "PM" : "AM"}`;
            const value = `${hour.toString().padStart(2, "0")}:${minute
                .toString()
                .padStart(2, "0")}`;
            times.push({ value, label });
        }
    }
    return times;
};
