import React from "react";
import styles from "./DigestHeader.module.css"; // New CSS module for the header
import hornIcon from "../../assets/icons/horn.svg";
import { DigestItemType, AttentionLevelType } from "../../types/Digest";

type DigestHeaderProps = {
    topTitle: string;
    mainTitle: string;
    attentionLevel: string;
    description?: string;
};

const DigestHeader: React.FC<DigestHeaderProps> = ({ topTitle, mainTitle, attentionLevel, description }) => {
    const enumValue = AttentionLevelType[attentionLevel as keyof typeof AttentionLevelType];
    const isHighAttentionLevel =
        enumValue === AttentionLevelType.MAY_NEED_ACTION ||
        enumValue === AttentionLevelType.ACTION_REQUIRED;

    return (
        <div className={`${styles.itemHeader} ${isHighAttentionLevel ? styles.highAttentionLevel : ""}`}>
            <div className={styles.leftHeader}>
                <div className={styles.topTitle}>{topTitle}</div>
                <div className={styles.mainTitle}>{mainTitle}</div>
            </div>

            <div className={styles.headerDivider}></div>

            <div className={styles.rightHeader}>
                {isHighAttentionLevel &&
                    <div className={styles.attentionHeader} >
                        <div className={styles.hornIconContainer} >
                            <img className={styles.hornIcon} src={hornIcon} />
                        </div>
                    </div>}
                {description && <div className={styles.headerSummary}>
                    {description.split("!").map((part, index) => (
                        <p key={`${index}`}>
                            {part.trim() /* Remove any extra whitespace */}
                            {description && index < description.split("!").length - 1 && "!" /* Add the "!" back */}
                        </p>
                    ))}
                    {/* {item.itemHeader.description.map((desc, index) => (
                            <p key={index}>{desc}</p> // Render each string inside a paragraph
                        ))} */}
                </div>}
                {/* <div className={styles.headerSummary}>{item.itemHeader.description}</div> */}
            </div>
        </div>
    );
};

export default DigestHeader;
