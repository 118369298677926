import React, { useState, useEffect } from "react";
import styles from "./ExistingLoginScreen.module.css"; // Reusing login styles for consistency
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import ActionButton from "../button/ActionButton";
import { AUTH_TYPE, handleLoginWithGoogle } from "../../google/loginHandler";
import { fetchWithoutAuth } from "src/request/backend";
import { appEvents } from "src/App";
import { AppEventType } from "src/events/app";
import TextInputField, { TextInputFieldStyle, TextInputFieldType } from "../input/TextInputField";
import { isNative } from "src/platform/platform";
import { saveSessionData } from "src/auth/session";
import NameInputContainer from "../input/NameInputContainer";

const SignUpScreen: React.FC = () => {
    const navigate = useNavigate();
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [isValidFields, setIsValidFields] = useState({
        name: false,
        email: false,
        password: false,
        confirmPassword: false,
    });
    const isFormValid = Object.values(isValidFields).every(Boolean);

    const handleBack = () => {
        navigate("/auth/login");
    };

    const handleSignUp = async () => {
        if (!isFormValid) return;

        try {
            const response = await fetchWithoutAuth("/api/signup", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ firstName, lastName, email, password }),
            });

            const data: { uid?: string } = await response.json();

            if (data.uid) {
                // Store credentials for verification step
                if (isNative()) {
                    await saveSessionData("user_email", email);
                    await saveSessionData("user_password", password);
                } else {
                    sessionStorage.setItem("user_email", email);
                    sessionStorage.setItem("user_password", password);
                }

                // Navigate to verification screen
                navigate("/verify-email", { state: { email } });
            } else {
                navigate(`?error=true&message=${encodeURIComponent('Signup failed. Try again.')}`);
                appEvents.emit(AppEventType.RESET_ACTION_BUTTON);
            }
        } catch (error) {
            console.error("Signup error:", error);
            navigate(`?error=true&message=${encodeURIComponent('Signup failed. Try again.')}`);
            appEvents.emit(AppEventType.RESET_ACTION_BUTTON);
        }
    };

    return (
        <Layout
            navbarConfig={{ title: "Sign Up with Email", onBack: handleBack }}
            overflow="auto"
            footer={(
                <div className={styles.existingLoginFooter}>
                    Already have an account? <span className={styles.loginLink} onClick={() => navigate("/auth/login/existing")}>
                        Log in
                    </span>
                </div>
            )}>
            <form className={styles.signupForm}>
                <div className={styles.instructions}>
                    Enter your details below to create your account and get started.
                </div>
                <NameInputContainer
                    firstName={firstName}
                    lastName={lastName}
                    firstNameSetter={setFirstName}
                    lastNameSetter={setLastName}
                    onNameValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, name: isValid }))}
                />
                <TextInputField
                    type={TextInputFieldType.EMAIL}
                    placeholder="Email"
                    style={TextInputFieldStyle.LOGIN}
                    value={email}
                    onChange={(value) => setEmail(value)}
                    onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, email: isValid }))}
                />
                <TextInputField
                    type={TextInputFieldType.PASSWORD}
                    placeholder="Password (8+ chars, 1 number, 1 special char)"
                    style={TextInputFieldStyle.LOGIN}
                    value={password}
                    onChange={(value) => setPassword(value)}
                    onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, password: isValid }))}
                />
                <TextInputField
                    type={TextInputFieldType.PASSWORD}
                    placeholder="Confirm password"
                    style={TextInputFieldStyle.LOGIN}
                    value={confirmPassword}
                    onChange={(value) => setConfirmPassword(value)}
                    errorStr={confirmPassword.length > 0 && password !== confirmPassword ? "confirmed password does not match." : undefined}
                    onValidChange={(isValid) => setIsValidFields((prev) => ({ ...prev, confirmPassword: isValid }))}
                />
                <ActionButton
                    onClick={handleSignUp}
                    className={styles.formLoginButton}
                    hasDataLoad={true}
                    disabled={!isFormValid} // Disabled unless all validations pass
                >
                    Sign Up
                </ActionButton>
            </form>
            <div className={styles.loginDivider}>or</div>
            <ActionButton
                iconType="Google"
                colorPattern="Light Brown"
                onClick={() => handleLoginWithGoogle(AUTH_TYPE.LOGIN, "/")}
            >
                Sign up with Google
            </ActionButton>
        </Layout>
    );
};

export default SignUpScreen;
