import React from "react";
import styles from "./ProfileDetailRow.module.css";
import noUser from "../../assets/no-user.png";

type ProfileDetailRowProps = {
    profilePictureUrl?: string; // Optional profile picture URL
    name: string; // Display name
    altText?: string; // Alt text for the image
};

const ProfileDetailRow: React.FC<ProfileDetailRowProps> = ({ profilePictureUrl, name, altText }) => {
    return (
        <div className={styles.profileDetailContainer}>
            <img
                src={profilePictureUrl || noUser}
                alt={altText || name}
                className={styles.profilePicture}
            />
            <div className={styles.name}>{name}</div>
        </div>
    );
};

export default ProfileDetailRow;
