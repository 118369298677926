import { useState, useEffect, useCallback } from "react";
import { App as CapacitorApp } from "@capacitor/app";
import { useGetCalendarConnectionsQuery, CalendarConnection, ExternalSource } from "src/gql/types";
import { isIOS, isNative, isWeb } from "src/platform/platform"; // Assuming you have these functions
import { PluginListenerHandle } from "@capacitor/core";

const useCalendarRefreshBlockingCondition = (isEligible: boolean | null) => {
    const [isBlocked, setIsBlocked] = useState<boolean | null>(null);
    const [fetchedConnections, setFetchedConnections] = useState<CalendarConnection[]>([]);

    // Prevent fetching if the user is not authenticated
    const shouldFetch = isEligible === true;

    // Use Apollo Client's useQuery hook to fetch calendar connections only if authenticated
    const { data, refetch } = useGetCalendarConnectionsQuery({
        fetchPolicy: 'network-only', // Ensures we always get fresh data
        skip: !shouldFetch, // Skip fetching if not authenticated
    });

    // Function to check blocking condition
    const checkBlockingCondition = useCallback(() => {
        if (data?.getCalendarConnections) {
            const actionableStaleAccounts = data.getCalendarConnections.filter((connection) => {
                if (connection.source === ExternalSource.Google) {
                    return connection.expired;
                }
                return isIOS() && connection.source === ExternalSource.Apple ? connection.expired : false;
            });
            const nonStaleAccounts = data.getCalendarConnections.filter((connection) => {
                return !connection.expired;
            });
            setIsBlocked(actionableStaleAccounts.length > 0 || nonStaleAccounts.length == 0);
            setFetchedConnections(data.getCalendarConnections);
        }
    }, [data]);

    // Run the check when data updates
    useEffect(() => {
        checkBlockingCondition();
    }, [data, checkBlockingCondition]);

    // Handle app foreground event & tab visibility for web
    useEffect(() => {
        if (!shouldFetch) return; // Don't run if the user isn't logged in

        let appStateListener: PluginListenerHandle;
        const cleanupFunctions: (() => void)[] = [];

        const setupListeners = async () => {
            // 📱 Mobile (Capacitor)
            if (isNative()) {
                appStateListener = await CapacitorApp.addListener("appStateChange", async ({ isActive }) => {
                    if (isActive) {
                        await refetch(); // Refetch from Apollo when app foregrounds
                        checkBlockingCondition(); // Check new data
                    }
                });
                cleanupFunctions.push(() => appStateListener.remove());
            }

            // 🌍 Web (Tab Visibility)
            if (isWeb()) {
                const handleVisibilityChange = async () => {
                    if (document.visibilityState === "visible") {
                        await refetch(); // Refetch when tab is focused
                        checkBlockingCondition();
                    }
                };
                document.addEventListener("visibilitychange", handleVisibilityChange);
                cleanupFunctions.push(() => document.removeEventListener("visibilitychange", handleVisibilityChange));
            }
        };

        setupListeners();

        return () => {
            cleanupFunctions.forEach((cleanup) => cleanup());
        };
    }, [shouldFetch, refetch, checkBlockingCondition]);

    return { isBlocked, fetchedConnections, refetch };
};

export default useCalendarRefreshBlockingCondition;
