import React from "react";
import styles from "./DigestItem.module.css";
import { DigestItem as DigestItemType } from "../../gql/types";
import EventItem from "./EventItem";
import GroupedEventItem from "./GroupedEventItem";

type DigestItemProps = {
    item: DigestItemType;
};

const DigestItem: React.FC<DigestItemProps> = ({ item }) => {
    const itemType = item.itemType;
    switch (itemType) {
        case "event":
            return <EventItem item={item} />;
        case "groupedEvent":
            return <GroupedEventItem item={item} />;
        default:
            return (
                <li className={styles.digestItem}>
                    <p className={styles.unsupportedType}>Unsupported content type</p>
                </li>
            );
    }
};

export default DigestItem;
