import { useState } from "react";
import Overlay from "../../layout/Overlay";
import { Share } from "@capacitor/share";
import { isMobileWeb } from "../../../platform/platform";

export type ShareOptions = {
  title: string;
  text: string;
  url?: string;
  dialogTitle?: string;
};

export enum ShareStatus {
  STARTED = "STARTED",
  SUCCEEDED = "SUCCEEDED",
  ABORTED = "ABORTED",
  ERROR = "ERROR",
};

export const useShareWithOverlay = () => {
  const [overlayVisible, setOverlayVisible] = useState(false);
  const [overlayMessage, setOverlayMessage] = useState<string | undefined>();
  const [showSpinner, setShowSpinner] = useState(false);
  const [status, setStatus] = useState<ShareStatus | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const showOverlayWithSpinner = (message: string) => {
    setOverlayMessage(message);
    setShowSpinner(true);
    setOverlayVisible(true);
  };

  const shareContentWithOverlay = async (
    options: ShareOptions,
    overlayOptions?: { message?: string; showSpinner?: boolean }
  ): Promise<{ status: ShareStatus; errorMessage?: string | null }> => {
    const { message, showSpinner: spinnerOption } = overlayOptions || {};
    setOverlayMessage(message || "");
    setShowSpinner(spinnerOption !== undefined ? spinnerOption : false);
    setOverlayVisible(true);
    setStatus(null);

    try {
      if (isMobileWeb() && navigator.share) {
        try {
          await navigator.share({
            title: options.title,
            text: options.text,
            url: options.url,
          });
          setStatus(ShareStatus.SUCCEEDED);
        } catch (error) {
          setStatus(ShareStatus.ERROR);
        }
      } else {
        try {
          await Share.share({
            title: options.title,
            text: options.text,
            url: options.url,
            dialogTitle: options.dialogTitle,
          });
          setStatus(ShareStatus.SUCCEEDED);
        } catch (error) {
          setStatus(ShareStatus.ABORTED);
          const errorMsg = error instanceof Error ? error.message : "An unknown error occurred.";
          setErrorMessage(errorMsg);
          console.error("Error sharing content:", errorMsg);
        }
      }
    } catch (error) {
      setStatus(ShareStatus.ERROR);
    } finally {
      setOverlayVisible(false);
    }

    return { status: status ?? ShareStatus.ERROR, errorMessage };
  };

  const OverlayComponent = (
    <Overlay visible={overlayVisible} message={overlayMessage} showSpinner={showSpinner} />
  );

  return { shareContentWithOverlay, showOverlayWithSpinner, OverlayComponent, status };
};
