import React, { createContext, useContext, useEffect, useState, useRef } from "react";
import { Capacitor } from "@capacitor/core";
import { Keyboard } from "@capacitor/keyboard";

// Check if the app is running in a native environment (iOS/Android)
const isCapacitorNative = Capacitor.isNativePlatform();

interface KeyboardContextProps {
    isKeyboardVisible: boolean;
}

// Create context
const KeyboardContext = createContext<KeyboardContextProps>({
    isKeyboardVisible: false,
});

// Custom hook to use the keyboard context
export const useKeyboard = () => useContext(KeyboardContext);

// Provider component
export const KeyboardProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [isKeyboardVisible, setIsKeyboardVisible] = useState(false);
    let keyboardCloseTimeout: NodeJS.Timeout | null = null;
    const lastScrollPosition = useRef(0);
    const lastKeyboardHeight = useRef(0);

    useEffect(() => {
        const rootElement = document.getElementById("root"); // Target #root
        const onKeyboardShow = (info: { keyboardHeight: number }) => {
            setIsKeyboardVisible(true);
            lastKeyboardHeight.current = info.keyboardHeight; // Save the last keyboard height
            if (keyboardCloseTimeout) { clearTimeout(keyboardCloseTimeout); }
            // 🔥 Get focused input & adjust scrolling
            setTimeout(() => {
                const activeElement = document.activeElement as HTMLElement;
                if (activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA")) {
                    activeElement.scrollIntoView({ behavior: "smooth", block: "center" });
                    lastScrollPosition.current = window.scrollY; // Save current scroll position
                }
            }, 100);
        };
        const onKeyboardHide = () => {
            setIsKeyboardVisible(false);
        };

        let hiddenFocusTimeout: NodeJS.Timeout | null;

        const handleScroll = (reset: boolean = false) => {
            const scrollY = window.scrollY || document.documentElement.scrollTop;
            const safeInset = parseFloat(getComputedStyle(document.documentElement).getPropertyValue("env(safe-area-inset-top)")) || 0;
            const viewPort = window.visualViewport;

            const navbar = document.querySelector(".navbar") as HTMLElement;

            if (!viewPort || reset) {
                if (navbar) {
                    navbar.style.transform = `translateY(${safeInset})`;
                }
                return;
            }

            const activeElement = document.activeElement;
            const isFocusedInput = activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA");

            if (navbar && isFocusedInput) {
                const finalTop = safeInset + scrollY; // Adjust dynamically
                navbar.style.transform = `translateY(${finalTop})`;
            }
        };

        const viewPort = window.visualViewport;
        if (isCapacitorNative) {
            // ✅ Only register Capacitor listeners in native apps
            Keyboard.addListener("keyboardDidShow", onKeyboardShow);
            Keyboard.addListener("keyboardDidHide", onKeyboardHide);
        } else {
            if (!viewPort) {
                setIsKeyboardVisible(false);
                handleScroll(true);
                return;
            }

            // ✅ Use `visualViewport` for mobile web
            const handleResize = () => {
                const heightDiff = window.innerHeight - viewPort.height;
                const activeElement = document.activeElement;
                const isFocusedInput = activeElement && (activeElement.tagName === "INPUT" || activeElement.tagName === "TEXTAREA");

                // 🔥 Improved keyboard visibility check
                const isKeyboardNowVisible = Math.abs(heightDiff) > 100 || isFocusedInput;

                if (!isKeyboardNowVisible) {
                    setIsKeyboardVisible(false);
                    handleScroll(true);
                } else {
                    setIsKeyboardVisible(true);
                    handleScroll(false);
                }
            };

            const handleScrollDefault = () => handleScroll();
            window.addEventListener("scroll", handleScrollDefault, { passive: true });
            viewPort.addEventListener("resize", handleResize);

            const handleFocusIn = (event: FocusEvent) => {
                const target = event.target as HTMLElement;
                if (target.tagName === "INPUT" || target.tagName === "TEXTAREA") {
                    if (keyboardCloseTimeout) clearTimeout(keyboardCloseTimeout);
                    if (hiddenFocusTimeout) clearTimeout(hiddenFocusTimeout);
                    setIsKeyboardVisible(true);
                    handleScroll();
                    handleResize();
                }
            };

            const handleFocusOut = (_: FocusEvent) => {
                if (!document.activeElement || !(document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")) {
                    keyboardCloseTimeout = setTimeout(() => {
                        setIsKeyboardVisible(false);
                        window.scrollTo(0, 0); // Force a reset
                        // document.body.style.height = "100dvh"; // Restore full height
                        handleScroll(true);
                    }, 100); // Slight delay to ensure next input isn't focused
                }
            };

            // let isDragging = false; // Track if user is dragging

            // const handleTouchStart = () => {
            //     isDragging = false; // Reset dragging state when touch starts
            // };

            // const handleTouchMove = () => {
            //     if (!isDragging) { // Only trigger once per drag
            //         isDragging = true;
            //         console.log("Closing keyboard due to scrolling gesture.");

            //         if (!document.activeElement || !(document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA")) {
            //             return;
            //         }
            //         console.log("Closing keyboard due to scrolling gesture.");
            //         const hiddenInput = document.getElementById("hiddenInput") as HTMLInputElement;
            //         if (hiddenInput) {
            //             hiddenInput.focus();
            //             hiddenFocusTimeout = setTimeout(() => hiddenInput.blur(), 50); // Force keyboard close
            //         }
            //     }
            // };

            // // Attach event listeners
            // window.addEventListener("touchstart", handleTouchStart, { passive: true });
            // window.addEventListener("touchmove", handleTouchMove, { passive: true });


            document.addEventListener("focusin", handleFocusIn);
            document.addEventListener("focusout", handleFocusOut);
            return () => {
                window.removeEventListener("scroll", handleScrollDefault);
                viewPort.removeEventListener("resize", handleResize);
                document.removeEventListener("focusin", handleFocusIn);
                document.removeEventListener("focusout", handleFocusOut);
                // window.removeEventListener("touchstart", handleTouchStart);
                // window.removeEventListener("touchmove", handleTouchMove);

            }
        }

        return () => {
            if (isCapacitorNative) {
                Keyboard.removeAllListeners();
            }
        };
    }, []);

    return (
        <KeyboardContext.Provider value={{ isKeyboardVisible }}>
            {children}
        </KeyboardContext.Provider>
    );
};

