import React, { useEffect, useState } from "react";
import styles from "./ExistingLoginScreen.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import Layout from "../layout/Layout";
import ActionButton from "../button/ActionButton";
import { fetchWithoutAuth } from "src/request/backend";
import { exchangeFirebaseTokenForAccessTokens } from "src/auth/verify";
import { firebaseSignIn } from "src/auth/signin";
import { isNative } from "src/platform/platform";
import { clearSessionData, getSessionData } from "src/auth/session";
import inlineErrorIcon from "../../assets/icons/inlineErrorIcon.svg";

interface VerifyEmailScreenProps {
    loadUser: () => Promise<void>;
}

const VerifyEmailScreen: React.FC<VerifyEmailScreenProps> = ({ loadUser }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [email, setEmail] = useState<string>("");
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        // Retrieve email from sessionStorage if not passed via state
        const storedEmail = sessionStorage.getItem("user_email");
        if (location.state?.email) {
            setEmail(location.state.email);
        } else if (storedEmail) {
            setEmail(storedEmail);
        } else {
            navigate("/signup"); // Redirect if email is missing
        }
    }, [location.state, navigate]);

    const handleProceed = async () => {
        setLoading(true);
        setError(null);

        let storedPassword: string | null;
        if (isNative()) {
            storedPassword = await getSessionData("user_password");
        } else {
            storedPassword = sessionStorage.getItem("user_password");
        }

        if (!email || !storedPassword) {
            setError("Session expired. Please log in again.");
            setLoading(false);
            return;
        }

        try {
            const { emailVerified, idToken } = await firebaseSignIn(email, storedPassword);
            if (emailVerified && idToken) {
                if (isNative()) {
                    await Promise.all([
                        clearSessionData("user_email"),
                        clearSessionData("user_password"),
                    ]);
                } else {
                    sessionStorage.clear(); // Clear stored credentials after success
                }
                await exchangeFirebaseTokenForAccessTokens(idToken);
                await loadUser();
                navigate("/");
            } else {
                setError("Email is not verified yet. Please check your inbox.");
            }
        } catch (error) {
            console.error("Verification error:", error);
            setError("Error verifying email. Try again.");
        }

        setLoading(false);
    };

    const handleResendEmail = async () => {
        setLoading(true);
        setError(null);

        try {
            const response = await fetchWithoutAuth("/api/resend-verification", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ email }),
            });

            if (response.ok) {
                navigate(`?error=false&message=${encodeURIComponent('Verification email has been resent.')}`);
            } else {
                setError("Failed to resend email. Try again later.");
            }
        } catch (error) {
            console.error("Resend error:", error);
            setError("Failed to resend email.");
        }

        setLoading(false);
    };

    return (
        <Layout
            navbarConfig={{ title: "Verify Your Email", onBack: () => navigate("/auth/login") }}
            footer={(
                <div className={styles.existingLoginFooter}>
                    Didn’t receive the email?{" "} <span className={styles.loginLink} onClick={handleResendEmail}>
                        Resend
                    </span>
                </div>
            )}
        >
            <div className={styles.verifyEmailContainer}>
                <div className={styles.instructions}>
                    Please verify your email using the link sent to <span className={styles.emailBoldText}>{email}</span>
                </div>
                {error && (
                    <>
                        <div className={styles.errorText}>
                            <img src={inlineErrorIcon} className={styles.errorIcon} />
                            {error}
                            <span className={styles.placeholderSpace}>&nbsp;</span>
                        </div>
                    </>
                )}
                <ActionButton onClick={handleProceed} disabled={loading}>
                    {loading ? "Checking..." : "Proceed"}
                </ActionButton>

            </div>
        </Layout >
    );
};

export default VerifyEmailScreen;
