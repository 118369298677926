import React, { useState } from 'react';
import { useDigestView } from './DigestViewContext';
import ActionSheetFactory from '../action/ActionSheetFactory';
import ActionSheetClose from '../action/ActionSheetClose';
import { FooterButtonPosition } from './types';
import styles from './DigestViewActionSheetWrapper.module.css';
import { ActionType } from '../action/types';

const DigestViewActionSheetWrapper: React.FC = ({ }) => {
    const { actionSheetConfig, hideActionSheet, setFooterButton, caretakers, fetchCaretakers, addCaretaker } = useDigestView();
    const [actionType, setActionType] = useState<ActionType | null>(actionSheetConfig.actionType ?? null);

    React.useEffect(() => {
        if (actionSheetConfig.isVisible) {
            setFooterButton(
                FooterButtonPosition.MIDDLE,
                <ActionSheetClose onClose={actionSheetConfig.onClose || hideActionSheet} />
            );
            if (actionSheetConfig.actionType) {
                setActionType(actionSheetConfig.actionType);
            }
        } else {
            setFooterButton(FooterButtonPosition.MIDDLE, null);
        }
    }, [actionSheetConfig.isVisible]);

    return (
        <div className={`${styles.actionSheetContainer} ${!actionSheetConfig.isVisible ? styles.isHidden : ''}`}>
            {actionSheetConfig.isVisible && actionType && actionSheetConfig.onSelect && (
                <ActionSheetFactory
                    actionType={actionType}
                    setActionType={setActionType}
                    isVisible={actionSheetConfig.isVisible}
                    onClose={actionSheetConfig.onClose || hideActionSheet}
                    onSelect={actionSheetConfig.onSelect}
                    preloadData={{
                        caretakers: {
                            loader: fetchCaretakers,
                            onAddCaretaker: addCaretaker,
                        },
                    }}
                />
            )}
        </div>
    );

};

export default DigestViewActionSheetWrapper;
