// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyDuH5SF8Ku22Gfop9FhvUjkQuI5vnG6Bco",
    authDomain: "pathy-61feb.firebaseapp.com",
    databaseURL: "https://pathy-61feb-default-rtdb.firebaseio.com",
    projectId: "pathy-61feb",
    storageBucket: "pathy-61feb.firebasestorage.app",
    messagingSenderId: "691423973656",
    appId: "1:691423973656:web:9ba28352a75816d5154e6d",
    measurementId: "G-TM4JNDD2J0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { auth };