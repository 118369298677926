import React, { createContext, useContext, useState, ReactNode, useCallback, useEffect } from 'react';
import { FooterButtonPosition } from './types';
import { ActionType } from '../action/types';
import { ActionSheetData } from '../action/ActionSheetFactory';
import { Digest, DigestItem, Event as DigestEventType, Event, CaretakerDetails, CaretakerDetailsInput, GenerateDigestQuery, DigestSection, GroupedEvent, useGenerateDigestLazyQuery, GenerateDigestDocument } from '../../gql/types';
import { useGetCaretakerDetailsQuery, useAddCaretakerMutation } from '../../gql/types';
import { CaretakerRequestDetails } from '../events/types';
import { CaretakerRequest } from '../../gql/types';

type FooterButtonMap = {
    [key in FooterButtonPosition]?: ReactNode;
};

interface ActionSheetConfig {
    isVisible: boolean;
    actionType?: ActionType;
    onClose?: () => void;
    onComplete?: (actionType: ActionType, success: boolean, updatedContent: any) => void;
    onSelect?: <T extends ActionType>(actionType: T, actionValue: string, additionalData: ActionSheetData[T]) => void;
    content?: any; // context to feed into the action sheet for the next set of operations
}

interface CaretakerRequestConfig {
    isVisible: boolean;
    item?: DigestItem;
    event?: DigestEventType; // Event details
    onClose?: () => void;
    caretakerRequestDetails?: CaretakerRequestDetails;
}

interface CaretakerConfirmationConfig {
    isVisible: boolean;
    item?: DigestItem;
    event?: DigestEventType; // Event details
    caretakerRequestDetails?: CaretakerRequestDetails;
    onBack?: () => void; // Back button handler
    onClose?: (caretakerRequest: CaretakerRequest, event?: DigestEventType) => void;
}

interface DigestViewContextProps {
    // Footer button management
    footerButtons: FooterButtonMap;
    setFooterButton: (position: FooterButtonPosition, button: ReactNode | null) => void;
    clearFooterButtons: () => void;

    // ActionSheet management
    actionSheetConfig: ActionSheetConfig;
    showActionSheet: (
        actionType: ActionType,
        content: any,
        onComplete?: (actionType: ActionType, success: boolean, updatedContent: any) => void,
        onClose?: () => void,
        onSelect?: <T extends ActionType>(actionType: T, actionValue: string, additionalData: ActionSheetData[T]) => void,
    ) => void;
    hideActionSheet: () => void;

    refetchDigestItem: (itemId: string) => Promise<void>;
    updateDigestItem: (updatedItem: DigestItem) => void;

    caretakerRequestConfig: CaretakerRequestConfig;
    showCaretakerRequest: (item: DigestItem, event?: DigestEventType) => void;
    hideCaretakerRequest: () => void;

    caretakerConfirmationConfig: CaretakerConfirmationConfig;
    showCaretakerConfirmation: (aretakerRequestDetails: CaretakerRequestDetails, item?: DigestItem, event?: DigestEventType) => void;
    hideCaretakerConfirmation: (backToRequest: boolean) => void;
    setCaretakerConfirmationCallback: (callbackFn: (caretakerRequest: CaretakerRequest, event?: DigestEventType) => void) => void;

    // Caretaker management
    caretakers: CaretakerDetails[]; // Current list of caretakers
    fetchCaretakers: () => Promise<CaretakerDetails[]>; // Lazy loader for caretakers
    addCaretaker: (name: string, contactInfo: string) => Promise<CaretakerDetails | null>; // Add caretaker and update state
}

const DigestViewContext = createContext<DigestViewContextProps | undefined>(undefined);

export const DigestViewProvider: React.FC<{ children: ReactNode, setIsDigestVisible: (isVisible: boolean) => void }> = ({ children, setIsDigestVisible }) => {
    // Footer buttons state
    const [footerButtons, setFooterButtons] = useState<FooterButtonMap>({});
    const [caretakers, setCaretakers] = useState<CaretakerDetails[]>([]); // Add caretakers state
    const [caretakerLoading, setCaretakerLoading] = useState<boolean>(false);

    const setFooterButton = (position: FooterButtonPosition, button: ReactNode | null) => {
        setFooterButtons((prev) => ({
            ...prev,
            [position]: button,
        }));
    };

    const clearFooterButtons = () => {
        setFooterButtons({});
    };

    // ActionSheet state
    const [actionSheetConfig, setActionSheetConfig] = useState<ActionSheetConfig>({
        isVisible: false,
    });

    const showActionSheet = (
        actionType: ActionType,
        content: any,
        onComplete?: (actionType: ActionType, success: boolean, updatedContent: any) => void,
        onClose?: () => void,
        onSelect?: <T extends ActionType>(actionType: T, actionValue: string, additionalData: ActionSheetData[T]) => void,
    ) => {
        setActionSheetConfig({
            isVisible: true,
            actionType,
            onClose,
            onComplete,
            onSelect,
            content,
        });
    };

    const hideActionSheet = () => {
        setActionSheetConfig({
            isVisible: false,
        });
    };

    const refetchDigestItem = async (itemID: string) => {
    };

    const updateDigestItem = useCallback((updatedItem: DigestItem) => {
        // setDigest((prevDigest) => ({
        //     ...prevDigest,
        //     sections: prevDigest.sections.map((section) => ({
        //         ...section,
        //         items: section.items.map((item) =>
        //             item.id === updatedItem.id ? updatedItem : item
        //         ),
        //     })),
        // }));
    }, []);

    const [caretakerRequestConfig, setCaretakerRequestConfig] = useState<CaretakerRequestConfig>({
        isVisible: false,
    });

    const showCaretakerRequest = (item: DigestItem, event?: DigestEventType) => {
        setIsDigestVisible(false);
        setCaretakerRequestConfig({
            isVisible: true,
            item,
            event,
        });
    };

    const hideCaretakerRequest = () => {
        setIsDigestVisible(true);
        setCaretakerRequestConfig({
            ...caretakerRequestConfig,
            isVisible: false,
        });
    };

    const [caretakerConfirmationConfig, setcaretakerConfirmationConfig] = useState<CaretakerConfirmationConfig>({
        isVisible: false,
    });

    const showCaretakerConfirmation = (caretakerRequestDetails: CaretakerRequestDetails, item?: DigestItem, event?: DigestEventType) => {
        setIsDigestVisible(false);
        setCaretakerRequestConfig({
            ...caretakerRequestConfig,
            isVisible: false,
        });
        setcaretakerConfirmationConfig({
            ...caretakerConfirmationConfig,
            isVisible: true,
            item,
            event,
            caretakerRequestDetails,
        });
    };

    const hideCaretakerConfirmation = (backToRequest: boolean) => {
        setcaretakerConfirmationConfig({
            ...caretakerConfirmationConfig,
            isVisible: false,
        });
        if (backToRequest) {
            setCaretakerRequestConfig({
                ...caretakerRequestConfig,
                caretakerRequestDetails: caretakerConfirmationConfig.caretakerRequestDetails,
                isVisible: true,
            });
            return;
        } else {
            setIsDigestVisible(true);
        }
    };

    // Apollo hooks for caretakers
    const { refetch: refetchCaretakers } = useGetCaretakerDetailsQuery({
        skip: true,
    });
    const [addCaretakerMutation] = useAddCaretakerMutation();

    // Lazy loader for caretakers
    const fetchCaretakers = useCallback(async () => {
        if (caretakerLoading || caretakers.length > 0) {
            // Return cached caretakers if already fetched
            return caretakers;
        }

        setCaretakerLoading(true);
        try {
            const { data } = await refetchCaretakers();
            if (data?.getCaretakerDetails) {
                setCaretakers([...data.getCaretakerDetails]);
                return data.getCaretakerDetails;
            }
        } catch (error) {
            console.error('Failed to fetch caretakers:', error);
        } finally {
            setCaretakerLoading(false);
        }
        return [];
    }, [caretakerLoading, caretakers, refetchCaretakers]);

    // Add caretaker and update state
    const addCaretaker = useCallback(
        async (name: string, contactInfo: string) => {
            try {
                const { data } = await addCaretakerMutation({
                    variables: {
                        input: { name, contactInfo },
                    },
                });

                if (data?.addCaretaker) {
                    setCaretakers((prev) => [...prev, data.addCaretaker]);
                    return data.addCaretaker;
                }
            } catch (error) {
                console.error('Failed to add caretaker:', error);
            } finally {
                return null;
            }
        },
        [addCaretakerMutation],
    );

    const setCaretakerConfirmationCallback = (callbackFn: (caretakerRequest: CaretakerRequest, event?: DigestEventType) => void) => {
        setcaretakerConfirmationConfig(
            {
                ...caretakerConfirmationConfig,
                onClose: callbackFn,
            }
        );
    };

    return (
        <DigestViewContext.Provider
            value={{
                footerButtons,
                setFooterButton,
                clearFooterButtons,
                actionSheetConfig,
                showActionSheet,
                hideActionSheet,
                refetchDigestItem,
                updateDigestItem,
                caretakerRequestConfig,
                showCaretakerRequest,
                hideCaretakerRequest,
                caretakers,
                fetchCaretakers,
                addCaretaker,
                caretakerConfirmationConfig,
                showCaretakerConfirmation,
                hideCaretakerConfirmation,
                setCaretakerConfirmationCallback,
            }}
        >
            {children}
        </DigestViewContext.Provider>
    );
};

export const useDigestView = (): DigestViewContextProps => {
    const context = useContext(DigestViewContext);
    if (!context) {
        throw new Error('useDigestView must be used within a DigestViewProvider');
    }
    return context;
};
