import React from "react";
import DigestItem from "./DigestItem";
import styles from "./DigestCard.module.css";
import { DigestItem as DigestItemType } from "../../gql/types";
import DigestHeader from "./DigestHeader";

type DigestCardProps = {
    item: DigestItemType;
};

const DigestCard: React.FC<DigestCardProps> = ({ item }) => {
    return (
        <div className={styles.section}>
            {/* Top Header */}
            <DigestHeader
                topTitle={item.itemHeader?.topTitle ?? ""}
                mainTitle={item.itemHeader?.mainTitle ?? ""}
                attentionLevel={item.attentionLevel}
                description={item.itemHeader?.description ?? undefined}
            />

            {/* Content */}
            <div className={styles.content}>
                <DigestItem item={item} />
            </div>
        </div>
    );
};

export default DigestCard;
