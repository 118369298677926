import React, { useState } from 'react';
import MaskedInput from "react-text-mask"; // Import MaskedInput
import styles from './CaretakerResponseCompleteScreen.module.css'; // Import your CSS module
import purpleLogo from "../../assets/icons/purple-logo.svg";
import Layout from '../layout/Layout';
import { CaretakerRequest } from '../../gql/types';
import { AddToCalendarButton } from "add-to-calendar-button-react";

type CaretakerResponseCompleteScreenProps = {
    request: CaretakerRequest;
    hasAccepted: boolean;
};

const CaretakerResponseCompleteScreen: React.FC<CaretakerResponseCompleteScreenProps> = ({ request, hasAccepted }) => {
    const eventDetails = {
        name: "Caretaker Request",
        description: request.message || "Childcare request from " + request.requester.firstName,
        startDate: new Date(request.timeRange.startDate).toISOString(),
        endDate: new Date(request.timeRange.endDate).toISOString(),
    };

    return (
        <div className={styles.container}>
            <div className={styles.bottomSheetContainer}>
                <div className={styles.bottomSheet}>
                    <div className={styles.topSection}>
                        <div className={styles.iconContainer}>
                            <img src={purpleLogo} className={styles.confirmationIcon} />
                        </div>
                        <div className={styles.infoSection}>
                            Thank you for your response!
                        </div>
                    </div>
                    <div className={styles.bottomSection}>
                        {hasAccepted &&
                            // replace this with AddToCalendarButton
                            <div>
                                <h3>Add the event to your calendar:</h3>
                                <AddToCalendarButton
                                    name={eventDetails.name}
                                    description={eventDetails.description}
                                    startDate={eventDetails.startDate}
                                    endDate={eventDetails.endDate}
                                    options={["Apple", "Google"]}
                                    trigger="click"

                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaretakerResponseCompleteScreen;
