import { Preferences } from '@capacitor/preferences';

// Define expiry duration (e.g., 30 minutes)
const EXPIRY_TIME_MS = 30 * 60 * 1000; // 30 minutes

// Store session data with a timestamp
export const saveSessionData = async (key: string, value: string) => {
    const timestamp = Date.now();
    await Preferences.set({
        key,
        value: JSON.stringify({ value, timestamp }),
    });
};

// Retrieve session data and check expiration
export const getSessionData = async (key: string) => {
    const item = await Preferences.get({ key });
    if (!item.value) return null; // No data stored

    const { value, timestamp } = JSON.parse(item.value);
    if (Date.now() - timestamp > EXPIRY_TIME_MS) {
        // Expired: Clear the stored data
        await Preferences.remove({ key });
        return null;
    }

    return value; // Still valid
};

// Remove session data manually
export const clearSessionData = async (key: string) => {
    await Preferences.remove({ key });
};
