import React from 'react';
import { useNavigate } from 'react-router-dom'; // Assuming you're using React Router
import styles from './Navbar.module.css'; // Import CSS for the Navbar
import { isNative } from '../../platform/platform';
import backButtonIcon from '../../assets/icons/backButton.svg';
import xButtonIcon from '../../assets/icons/xButton.svg';
import hamburgerMenuIcon from '../../assets/icons/hamburgerMenu.svg';


interface NavbarProps {
  title: string; // Title for the navbar
  showLogo?: boolean;
  openHamburgerMenu?: () => void;
  onBack?: () => void; // Optional custom back button behavior
  isOverflowVisible: boolean;
  useCloseButton?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ title, showLogo, openHamburgerMenu, onBack, isOverflowVisible, useCloseButton }) => {
  const navigate = useNavigate();
  const primaryLogo = new URL("../../assets/primary-logo.png", import.meta.url).href;

  const handleBackClick = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1); // Default behavior: go back one page
    }
  };

  return (
    <div className={`navbar ${styles.navbar} ${styles.overflowVisible} ${isOverflowVisible && isNative() ? styles.mobileTop : ""}`}>
      {onBack && !openHamburgerMenu && <div className={`${useCloseButton ? styles.closeButtonContainer : styles.backButtonContainer}`}>
        <button className={styles.prevButton} onClick={handleBackClick}>
          {useCloseButton ? <img src={xButtonIcon} /> : <img src={backButtonIcon} />}
        </button>
      </div>}
      {openHamburgerMenu && <div className={styles.backButtonContainer}>
        <button className={styles.prevButton} onClick={openHamburgerMenu}>
          <img src={hamburgerMenuIcon} />
        </button>
      </div>}
      <h1 className={`${styles.title} ${showLogo ? styles.hidden : ""}`}>{title}</h1>
      {<img src={primaryLogo} className={`${showLogo ? "" : styles.imageHidden} ${styles.logo}`} decoding="async" loading="lazy" alt="Plii Logo" />}
    </div >
  );
};

export default Navbar;
