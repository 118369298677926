import React from "react";
import styles from "./TimeRangeRowView.module.css";

interface TimeRangeRowViewProps {
    startTime: string;
    endTime: string;
}

const formatTime = (time: string): string => {
    return new Date(time).toLocaleTimeString("en-US", {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true, // Ensures AM/PM is used
    });
};

const TimeRangeRowView: React.FC<TimeRangeRowViewProps> = ({ startTime, endTime }) => {
    return (
        <div className={styles.timeDetails}>
            <div className={styles.startTimeContainer}>{`${formatTime(startTime)}`}</div>
            <span className={styles.timeDivider}>to</span>
            <div className={styles.endTimeContainer}>{`${formatTime(endTime)}`}</div>
        </div>
    );
};

export default TimeRangeRowView;
