import React, { useState, useEffect } from 'react';
import CalendarSync, { AccountProviderType, SyncedAccount } from '../profile/CalendarSync';
import OnboardingStep from './OnboardingStep';
import { useNavigate } from 'react-router-dom';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';
import { appEvents } from '../../App';
import { AppEventType } from '../../events/app';
import { CalendarPlugin } from 'calendar-plugin';
import { CalendarSourceType } from '../../types/Calendar';
import { CalendarConnection, ExternalSource, useGetCalendarConnectionsQuery, useRemoveCalendarConnectionMutation } from 'src/gql/types';

interface CalendarSyncStepProps {
    onboardingContext: {
        stepNumber: number;
        totalSteps: number;
        onBack?: () => void;
    };
    onNext: (name: string) => void;
}

const CalendarSyncStep: React.FC<CalendarSyncStepProps> = ({
    onboardingContext,
    onNext,
}) => {
    const [syncedAccounts, setSyncedAccounts] = useState<SyncedAccount[]>([]);
    const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state
    const [additionalButtons, setAdditionalButtons] = useState<
        { label: string; onClick: () => void; type: 'primary' | 'secondary' }[]
    >([]);
    const navigate = useNavigate();

    // Use Apollo Client's useQuery hook to fetch calendar connections
    const { loading, error, data, refetch } = useGetCalendarConnectionsQuery({
        fetchPolicy: 'network-only',
    });

    const [removeCalendarConnection] = useRemoveCalendarConnectionMutation();

    // Update syncedAccounts when data is fetched
    useEffect(() => {
        if (data?.getCalendarConnections) {
            const fetchedAccounts: SyncedAccount[] = data.getCalendarConnections.map((connection: CalendarConnection) => ({
                provider: connection.source === 'Google' ? AccountProviderType.GOOGLE : AccountProviderType.APPLE,
                identifier: connection.identifier,
                expired: connection.expired,
                label: connection.label ?? undefined,
            })).filter((account) => {
                return !account.expired;
            })
            setSyncedAccounts(fetchedAccounts);
        }
    }, [data]);

    // Update button state whenever syncedAccounts changes
    useEffect(() => {
        setButtonEnabled(syncedAccounts.length > 0); // Enable button only when accounts exist
    }, [syncedAccounts]);

    // Handle adding an account
    const handleAddAccount = async (provider: AccountProviderType) => {
        if (provider == AccountProviderType.GOOGLE) {
            await handleLoginWithGoogle(AUTH_TYPE.CALENDAR, "/");
        } else if (provider === AccountProviderType.APPLE) {
            try {
                const response = await CalendarPlugin.requestCalendarPermissions();
                if (response.granted) {
                    console.log('Calendar permissions granted.');
                    appEvents.emit(AppEventType.CALENDAR_UPDATED, { source: CalendarSourceType.APPLE, account: response.deviceIdentifier, label: response.deviceName });
                } else {
                    console.error('Calendar permissions not granted.');
                }
            } catch (error) {
                console.error('Error requesting calendar permissions:', error);
            }
        }
    };

    // Handle removing an account
    const handleRemoveAccount = async (identifier: string) => {
        try {
            const accountToRemove = syncedAccounts.find(
                (account) => account.identifier === identifier
            );
            if (!accountToRemove) {
                console.error(`Account with email ${identifier} not found`);
                return;
            }

            let source: ExternalSource;
            switch (accountToRemove.provider) {
                case AccountProviderType.GOOGLE:
                    source = ExternalSource.Google;
                    break;
                case AccountProviderType.APPLE:
                    source = ExternalSource.Apple;
                    break;
                default:
                    console.error("invalid source: ", accountToRemove.provider);
                    return;

            }
            // Execute the mutation
            await removeCalendarConnection({
                variables: {
                    calendar: {
                        source: source,
                        identifier: accountToRemove.identifier,
                    },
                },
            });

            // Update local state after successful mutation
            setSyncedAccounts((prev) =>
                prev.filter((account) => account.identifier !== identifier)
            );
        } catch (error) {
            console.log(error);
            navigate(`?error=true&message=${encodeURIComponent('There was an error removing the account.')}`);
        }
    };

    const handleContinue = () => {
        onNext('test');
    };

    return (
        <OnboardingStep
            title="Confirm your synced calendars to proceed, or add more if needed."
            subtitle=""
            onboardingContext={onboardingContext}
            buttonEnabled={buttonEnabled}
            onContinue={handleContinue}
            additionalButtons={additionalButtons} // Buttons dynamically set by CalendarSync
        >
            <CalendarSync
                existingConnections={syncedAccounts} // Prefetched accounts
                onAccountAdd={handleAddAccount} // Pass handleAddAccount directly
                onAccountRemove={handleRemoveAccount} // Pass handleRemoveAccount directly
                setAdditionalButtons={setAdditionalButtons} // Pass down callback to set buttons
                loading={loading} // Pass loading state
                error={error} // Pass error state
            />
        </OnboardingStep>
    );
};

export default CalendarSyncStep;
