import React from "react";
import styles from "./Overlay.module.css";

type OverlayProps = {
    visible: boolean;
    message?: string;
    showSpinner?: boolean; // Optional spinner
};

const Overlay: React.FC<OverlayProps> = ({ visible, message, showSpinner = true }) => {
    return (
        <div className={`${styles.overlay} ${visible ? '' : styles.hidden}`}>
            <div className={styles.content}>
                {showSpinner && <div className={styles.spinner}></div>}
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default Overlay;
