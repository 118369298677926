import React, { useState, useEffect } from "react";
import styles from "./ProfileView.module.css";
import { useNavigate } from "react-router-dom";
import Layout from "../layout/Layout";
import { LOCAL_MODE } from "src/config/local";
import { useLogoutMutation, User } from "src/gql/types";
import ProfilePictureUpload, { FlowType } from "./ProfilePictureUpload";
import logoutIcon from "../../assets/icons/logoutIcon.svg";
import { clearTokens } from "src/auth/jwt";
import Spinner from "../button/Spinner";
import ActionButton from "../button/ActionButton";
import composeIcon from "../../assets/icons/composeIcon.svg";
import MySettingsView from "./MySettingsView";
import useCalendarAuthRedirect from "src/hooks/useCalendarAuthRedirect";

type ProfileViewProps = {
    user: User;
    onBack?: () => void;
    isHidden?: boolean;
};

const ProfileView: React.FC<ProfileViewProps> = ({ user, onBack, isHidden }) => {
    const { calendarAuthParams, clearCalendarAuthParams } = useCalendarAuthRedirect();

    const [dataURL, setDataURL] = useState<string>('');
    const [localLoading, setLocalLoading] = useState(false);
    const [showSettingView, setShowSettingView] = useState(false);
    const navigate = useNavigate();
    const handleSetDataURL = (newDataURL: string | null) => {
        if (!newDataURL) {
            setDataURL('');
            return;
        }
        setDataURL(newDataURL);
    }

    useEffect(() => {
        if (calendarAuthParams) {
            setShowSettingView(true);
        }
    }, [calendarAuthParams]);

    // Use the useLogoutMutation hook
    const [logout, { loading: mutationLoading }] = useLogoutMutation({
        onCompleted: async () => {
            // Clear session data and navigate to login
            await clearTokens();
            navigate('/auth/login');
        },
        onError: (error) => {
            console.error("Logout failed:", error);
        },
    });

    const loading = LOCAL_MODE ? localLoading : mutationLoading;

    const handleLogout = async () => {
        try {
            if (LOCAL_MODE) {
                // simulate loading with timeout
                setLocalLoading(true); // Show the spinner
                setTimeout(() => {
                    localStorage.removeItem('authToken'); // Simulated session clearing
                    setLocalLoading(false);
                }, 1500); // Simulates a 1.5s logout delay
                navigate('/auth/login');
                return;
            }
            await logout();
        } catch (error) {
            console.error("Error logging out:", error);
        }
    };

    const handleOpenSetting = () => {
        setShowSettingView(true);
    }

    return (
        <>
            {showSettingView && <MySettingsView onBack={() => setShowSettingView(false)} calendarAuthParams={calendarAuthParams ?? undefined} clearCalendarAuthParams={clearCalendarAuthParams} />}
            {!showSettingView && <Layout
                navbarConfig={{ title: "My Profile", onBack, useCloseButton: false }}
                footer={(
                    <div className={styles.logoutContainer} onClick={handleLogout}>
                        <div className={styles.logOutButtonContainer}>
                            {loading
                                ? <Spinner />
                                : <img src={logoutIcon} className={styles.logoutButton} />}
                        </div>
                        <div className={styles.logOutText}>
                            Log Out
                        </div>
                    </div>
                )}
                isHidden={isHidden}
            >
                <div className={styles.profileViewContainer}>
                    <ProfilePictureUpload
                        onComplete={handleSetDataURL}
                        flowType={FlowType.Profile}
                        initialImage={user.profilePictureUrl ?? undefined}
                    />
                    <div className={styles.nameAndEmailContainer}>
                        <div className={styles.name}>
                            {user.firstName + " " + user.lastName}
                        </div>
                        <div className={styles.email}>
                            {user.email}
                        </div>
                    </div>
                    <div className={styles.settingButtonContainer}>
                        <ActionButton
                            onClick={handleOpenSetting}
                            className={styles.settingButton}
                        >
                            My Setting <img src={composeIcon} className={styles.composeIcon} />
                        </ActionButton>
                    </div>
                </div>
            </Layout>
            }
        </>
    );
};

export default ProfileView;
