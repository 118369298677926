import React, { ReactNode } from "react";
import styles from "./ActionSheet.module.css";
import ActionSheetDropdown from "./ActionSheetDropdown";

export type Action = {
    label: string;
    value: string;
    onClick?: () => void; // Optional for custom button behavior
    isPrimary?: boolean;
    isDisabled?: boolean;
    selectorOptions?: { label: string; value: string }[]; // Optional dropdown options
    onSelectorChange?: (selectedValue: string) => void; // Callback when a value is selected
};

export type ButtonGroup = Action[]; // A group of buttons to be displayed in the same row

export type BaseActionSheetProps = {
    isVisible: boolean;
    onClose: () => void;
    onSelect?: (actionValue: string) => void; // Optional if using custom content
    customHeader?: ReactNode; // Custom Header
    headerText?: string; // Optional header text for the action sheet
    actions?: ButtonGroup[]; // List of button groups
    children?: ReactNode; // Custom content (form, inputs, etc.)
    animate?: boolean; // animation to bring up the sheet from the bottom, use it only for the initial open
};

const BaseActionSheet: React.FC<BaseActionSheetProps> = ({
    isVisible,
    onClose,
    onSelect,
    customHeader,
    headerText,
    actions,
    children,
    animate = true,
}) => {
    if (!isVisible) return null;

    // Helper function to render a single button or selector
    const renderAction = (action: Action) => {
        if (action.selectorOptions) {
            // Use ActionSheetDropdown for advanced dropdown functionality
            return (
                <ActionSheetDropdown
                    key={action.value}
                    options={action.selectorOptions}
                    value={action.value}
                    placeholder={action.label}
                    onChange={(selectedValue) =>
                        action.onSelectorChange && action.onSelectorChange(selectedValue)
                    }
                />
            );
        }

        // Render a regular button
        return (
            <button
                key={action.value}
                className={`${action.isPrimary ? styles.primaryButton : styles.selectionButton}`}
                onClick={() =>
                    action.onClick ? action.onClick() : onSelect && onSelect(action.value)
                }
                disabled={action.isDisabled}
            >
                {action.label}
            </button>
        );
    };

    // Helper function to render a group of buttons
    const renderButtonGroup = (group: ButtonGroup, index: number) => (
        <div key={index} className={styles.rowButtons}>
            {group.map(renderAction)}
        </div>
    );

    return (
        <>
            <div className={styles.overlay} onClick={onClose} />
            <div className={styles.actionSheetContainer}>
                <div className={`${styles.actionSheet} ${animate ? "" : styles.actionSheetNoAnimation}`}>
                    {customHeader}
                    {headerText && <h2>{headerText}</h2>}
                    {children &&
                        <div className={styles.customContent}>{children}</div>}
                    <div className={styles.actionsContainer}>
                        {actions?.map(renderButtonGroup)}
                    </div>
                </div>
            </div>
        </>
    );
};

export default BaseActionSheet;
