import React, { useState, useEffect } from "react";
import styles from "./GroupedEventItem.module.css";
import { CaretakerDetails, CaretakerRequest, Event as DigestEventType, DigestItem as DigestItemType, GroupedEvent, ViewerRelationshipType } from "../../gql/types";
import { useDigestView } from "./DigestViewContext";
import { sanitizeActionTypes } from "./groupedEventItemSanitization";
import { ActionType, CaretakerSelectionValues } from "../action/types";
import { ActionSheetData } from "../action/ActionSheetFactory";
import { useNavigate } from "react-router-dom";
import MainEventItem from "./MainEventItem";

type GroupedEventItemProps = {
    item: DigestItemType;
};

type AdditionalContext = {
    UserAction?: {
        [eventID: string]: string[]; // Map of event IDs to a list of action types
    };
};

const GroupedEventItem: React.FC<GroupedEventItemProps> = ({ item }) => {
    const groupedEvent = item.content as GroupedEvent;
    const [selectedEvent, setSelectedEvent] = useState<DigestEventType | null>(null);
    const [actionTypes, setActionTypes] = useState<ActionType[]>([]); // Action sheet types
    const [currentActionType, setCurrentActionType] = useState<ActionType | null>(null);
    const { showActionSheet, hideActionSheet, showCaretakerRequest, setCaretakerConfirmationCallback } = useDigestView();
    const sanitizedUserActionMap = item.additionalContext ? sanitizeActionTypes(item.additionalContext as AdditionalContext) : null;
    const navigate = useNavigate();


    const handleActionClose = () => {
        if (actionTypes.length === 1) {
            setActionTypes([]);
            setCurrentActionType(null);
            hideActionSheet();
        } else {
            setActionTypes((prev) => prev.filter((type) => type !== currentActionType));
        }
    };

    const handleUpdateCaretakerRequestToEvent = (request: CaretakerRequest, event?: DigestEventType) => {
        if (!event) {
            return;
        }
        // This is now handled automatically when the createCaretakerRequest finishes in DigestViewContext
    };

    const handleActionCompletion = (actionType: ActionType, success: boolean, updatedContent: any) => {
        if (!success) {
            navigate(`?error=true&message=${encodeURIComponent('Failed to make changes. Please try again.')}`);
            return;
        }

        let operationSuccess = true;
        switch (actionType) {
            case ActionType.CARETAKER:
            // implement caretaker open view on result = 

            // case ActionType.CARETAKER_CREATE:
            //     operationSuccess = handleUpdateEvent(updatedContent);
            //     break;
            default:
                console.log("these actions are not handled: %s", actionType)
        }

        if (!operationSuccess) {
            navigate(`?error=true&message=${encodeURIComponent('Failed to apply changes. Please try again.')}`);
            return;
        }
        handleActionClose();
    };

    const formatTime = (dateString: string) => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
        };
        return date.toLocaleTimeString(undefined, options);
    };

    const isEventInteractable = (eventID: string): boolean => {
        return !!(sanitizedUserActionMap && sanitizedUserActionMap[eventID]?.length > 0);
    };

    const handleCaretakerAction = (selectionValue: CaretakerSelectionValues, caretaker?: CaretakerDetails) => {
        if (selectedEvent == null) {
            console.error("an event should be set at this stage");
            return;
        }
        switch (selectionValue) {
            case CaretakerSelectionValues.REQUEST:
                showCaretakerRequest(item, selectedEvent);
                setCaretakerConfirmationCallback(handleUpdateCaretakerRequestToEvent);
                break;
            default:
                console.error("not implemented yet: %s", selectionValue)
        }
    }

    const handleSelection = <T extends ActionType>(actionType: T, actionValue: string, additionalData: ActionSheetData[T]) => {
        hideActionSheet();
        switch (actionType as ActionType) {
            case ActionType.CARETAKER:
                handleCaretakerAction(actionValue as CaretakerSelectionValues, additionalData as CaretakerDetails);
                break;
            default:
                console.error("not handled yet: %s", actionType)
        }
    };

    const handleEventClick = (event: DigestEventType) => {
        if (sanitizedUserActionMap && sanitizedUserActionMap[event.eventID]) {
            setSelectedEvent(event);
            setActionTypes(sanitizedUserActionMap[event.eventID]); // Set available action types
            setCurrentActionType(sanitizedUserActionMap[event.eventID][0]);
            showActionSheet(sanitizedUserActionMap[event.eventID][0], event, handleActionCompletion, handleActionClose, handleSelection);
        }
    };

    // Side effect to handle currentActionType and showActionSheet
    useEffect(() => {
        if (actionTypes.length > 0) {
            const nextActionType = actionTypes[0];
            setCurrentActionType(nextActionType);
            showActionSheet(nextActionType, selectedEvent, handleActionCompletion, handleActionClose, handleSelection);
        }
    }, [actionTypes]);

    return (
        <div className={`${styles.digestItem}`}>
            {groupedEvent.mainEvents && (
                <div className={styles.mainEventSection}>
                    {groupedEvent.mainEvents.map((event) => {
                        return (
                            <MainEventItem
                                key={event.eventID}
                                event={event}
                                isEventInteractable={(event?.eventID && isEventInteractable(event.eventID)) || false}
                                handleEventClick={handleEventClick}
                            />);
                    }
                    )}
                </div>
            )}

            {groupedEvent.relatedEvents && (
                <div className={styles.relatedEventSection}>
                    {groupedEvent.relatedEvents.map((event) => (
                        <div key={event?.eventID} className={`${styles.eventItem} ${event?.owner.viewerRelationship == ViewerRelationshipType.Spouse ? styles.spouseRelatedEventItem : styles.relatedEventItem}`}>
                            <div className={styles.eventItemLeftSection}>
                                {event?.owner.viewerRelationship !== ViewerRelationshipType.Self && event.owner.userSummary?.profilePictureUrl &&
                                    <div className={styles.eventItemProfilePictureContainer}>
                                        <img src={event.owner.userSummary.profilePictureUrl} className={styles.eventItemProfilePicture} />
                                    </div>
                                }
                                <div className={styles.evenItemtTitle}>{event.title || "Related Event"}</div>
                            </div>
                            <div className={styles.eventItemRightSection}>
                                {event.owner.viewerRelationship !== ViewerRelationshipType.Self &&
                                    <div className={styles.eventItemCreator}>
                                        Created by: {event.owner.userSummary?.firstName // Check if firstName exists
                                            ? event.owner.userSummary.firstName
                                            : event.owner.viewerRelationship === 'SPOUSE' // Check if viewerRelationship is SPOUSE
                                                ? 'spouse'
                                                : 'other member' // Fallback if neither condition is met
                                        }
                                    </div>
                                }
                                <div className={styles.eventItemTime}>
                                    {formatTime(event.startDate)}{event.endDate ? ` - ${formatTime(event.endDate)}` : ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}

            {groupedEvent.otherEvents && (
                <div className={styles.otherEventSection}>
                    {groupedEvent.otherEvents.map((event) => (
                        <div key={event.eventID} className={`${styles.otherEventItem} ${styles.eventItem}`}>
                            <div className={styles.eventItemLeftSection}>
                                <div className={styles.evenItemtTitle}>{event.title || "Other Event"}</div>
                            </div>
                            <div className={styles.eventItemRightSection}>
                                <div className={styles.eventItemTime}>
                                    {formatTime(event.startDate)}{event.endDate ? ` - ${formatTime(event.endDate)}` : ""}
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default GroupedEventItem;
