import React, { useState } from 'react';
import MaskedInput from "react-text-mask"; // Import MaskedInput
import styles from './CaretakerResponseScreen.module.css'; // Import your CSS module
import purpleLogo from "../../assets/icons/purple-logo.svg";
import Layout from '../layout/Layout';

type CaretakerResponseScreenProps = {
    onConfirm: (name: string, phone?: string) => void;
    onDecline: (name: string) => void;
};

const CaretakerResponseScreen: React.FC<CaretakerResponseScreenProps> = ({ onConfirm, onDecline }) => {
    const [step, setStep] = useState<'initial' | 'accept' | 'declined'>('initial');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');

    const phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const numericOnlyValue = e.target.value.replace(/[^\d]/g, ""); // Remove non-numeric characters
        setPhone(numericOnlyValue);
    };

    const handleAccept = () => {
        setStep('accept');
    };

    const handleDecline = () => {
        setStep('declined');
        onDecline(name);
    };

    const handleConfirm = () => {
        console.log('Confirmed:', { name, phone });
        onConfirm(name, phone);
    };

    const isNameEmpty = !name.trim();
    const isPhoneInvalid = step === 'accept' && phone.length !== 10;

    return (
        <div className={styles.container}>
            <div className={styles.bottomSheetContainer}>
                <div className={styles.bottomSheet}>
                    <div className={styles.topSection}>
                        <div className={styles.iconContainer}>
                            <img src={purpleLogo} className={styles.confirmationIcon} />
                        </div>
                        <div className={styles.infoSection}>
                            Let the sender know you’ve accepted the childcare responsibility by providing the details below.
                        </div>
                    </div>
                    <div className={styles.bottomSection}>
                        <div className={styles.inputContainer}>
                            {
                                step !== 'declined' && (
                                    <>
                                        <div className={styles.inputGroup}>
                                            <div className={styles.sectionHeader}>Your Name:</div>
                                            <input
                                                id="name"
                                                type="text"
                                                value={name}
                                                onChange={(e) => setName(e.target.value)}
                                                className={styles.input}
                                                required
                                            />
                                        </div>

                                        {step === 'accept' && (
                                            <div className={styles.inputGroup}>
                                                <div className={styles.sectionHeader}>Phone Number:</div>
                                                <MaskedInput
                                                    mask={phoneMask}
                                                    className={styles.input}
                                                    value={phone}
                                                    onChange={handlePhoneChange}
                                                    placeholder="Enter your phone number"
                                                />
                                            </div>
                                        )}
                                    </>
                                )
                            }
                        </div>
                        {
                            step === 'initial' && (
                                <div className={styles.buttonGroup}>
                                    <button onClick={handleAccept} className={styles.submitButton} disabled={isNameEmpty}>
                                        Accept
                                    </button>
                                    <button onClick={handleDecline} className={styles.secondaryButton} disabled={isNameEmpty}>
                                        I can't make it
                                    </button>
                                </div>
                            )
                        }

                        {
                            step === 'accept' && (
                                <button onClick={handleConfirm} className={styles.submitButton} disabled={isNameEmpty || isPhoneInvalid}>
                                    Confirm
                                </button>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaretakerResponseScreen;
