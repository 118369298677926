import { Digest as DigestType, Event as DigestEventType } from "src/gql/types";

export const updateDigestWithNewEvent = (digest: DigestType, updatedEvent: DigestEventType): DigestType => {
    return {
        ...digest,
        sections: digest.sections.map((section) => ({
            ...section,
            items: section.items.map((item) => {
                if (item.content?.__typename === "GroupedEvent") {
                    return {
                        ...item,
                        content: {
                            ...item.content,
                            mainEvents: item.content.mainEvents?.map((event) =>
                                event.eventID === updatedEvent.eventID ? updatedEvent : event
                            ),
                            relatedEvents: item.content.relatedEvents?.map((event) =>
                                event.eventID === updatedEvent.eventID ? updatedEvent : event
                            ),
                            otherEvents: item.content.otherEvents?.map((event) =>
                                event.eventID === updatedEvent.eventID ? updatedEvent : event
                            ),
                        },
                    };
                }
                return item;
            }),
        })),
    };
};