import { createContext, useContext } from "react";
import primaryLogo from "../assets/primary-logo.png";

const LogoContext = createContext<string>(primaryLogo);

export const LogoProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    return <LogoContext.Provider value={primaryLogo}>{children}</LogoContext.Provider>;
};

export const useLogo = () => useContext(LogoContext);
