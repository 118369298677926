import React, { useEffect } from "react";
import BaseView from "./BaseView"; // Assuming BaseView is implemented
import styles from "./CaretakerRequestConfirmationView.module.css";
import { Event as DigestEventType, DigestItem as DigestItemType, User } from "../../gql/types";
import { CaretakerRequestDetails } from "./types";
import DigestHeader from "../digest/DigestHeader";
import MainEventItem from "../digest/MainEventItem";
import ProfileDetailRow from "../profile/ProfileDetailRow";
import TimeRangeRowView from "../time/TimeRangeRowView";
import requestIcon from "../../assets/icons/request-confirmation.svg";

type CaretakerRequestConfirmationViewProps = {
    user: User;
    digestItem: DigestItemType;
    event: DigestEventType; // Event details
    requestDetails: CaretakerRequestDetails; // Using the created type
    onBack: () => void; // Back button handler
    onConfirm: (requestDetails: CaretakerRequestDetails, item?: DigestItemType, event?: DigestEventType) => Promise<void>; // Confirm handler
};

const CaretakerRequestConfirmationView: React.FC<CaretakerRequestConfirmationViewProps> = ({
    user,
    digestItem,
    event,
    requestDetails,
    onBack,
    onConfirm,
}) => {
    const { selectedCaretaker, startDate, endDate, message } = requestDetails;

    useEffect(() => {
        // Reset scroll to the top when this component mounts
        window.scrollTo(0, 0);
    }, []);

    return (
        <BaseView
            title="Caretaker Request"
            onBack={onBack}
            footer={
                <div className={styles.buttonContainer}>
                    <button
                        className={styles.submitButton}
                        onClick={() => onConfirm(requestDetails, digestItem, event)}
                    >
                        Confirm and Share Request
                    </button>
                    <button
                        className={styles.secondaryButton}
                        onClick={onBack}
                    >
                        Modify Request
                    </button>
                </div>
            }
        >
            <div className={styles.confirmationViewContainer}>
                <DigestHeader
                    topTitle={digestItem.itemHeader?.topTitle ?? ""}
                    mainTitle={digestItem.itemHeader?.mainTitle ?? ""}
                    attentionLevel={digestItem.attentionLevel}
                    description={digestItem.itemHeader?.description ?? undefined}
                />

                <MainEventItem event={event} isEventInteractable={false} />
                <div className={styles.bottomSheetContainer}>
                    <div className={styles.bottomSheet}>
                        <div className={styles.iconContainer}>
                            <img src={requestIcon} className={styles.confirmationIcon} />
                        </div>
                        <div className={styles.infoSection}>
                            Please review the details and confirm if you’re ready to share!
                        </div>
                        <div className={styles.sectionHeader}>From:</div>
                        <ProfileDetailRow
                            name={user.firstName + " " + user.lastName}
                            profilePictureUrl={user.profilePictureUrl ?? undefined}
                        />

                        <div className={styles.sectionHeader}>Caretaker:</div>
                        <ProfileDetailRow
                            name={selectedCaretaker.userSummary ? selectedCaretaker.userSummary.firstName + " " + selectedCaretaker.userSummary.lastName
                                : (selectedCaretaker.name ?? "")}
                            profilePictureUrl={selectedCaretaker.userSummary ? (selectedCaretaker.userSummary.profilePictureUrl ?? undefined) : undefined}
                        />

                        <div className={styles.section}>
                            <div className={styles.sectionHeader}>Start & End Time:</div>
                            <TimeRangeRowView
                                startTime={startDate}
                                endTime={endDate}
                            />
                        </div>

                        <div className={styles.sectionHeader}>Notes/Message:</div>
                        <div className={styles.notes}>
                            {message || <em>No message provided</em>}
                        </div>
                    </div>
                </div>
            </div>
        </BaseView >
    );
};

export default CaretakerRequestConfirmationView;
