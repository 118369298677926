import React, { useState, useEffect } from "react";
import BaseActionSheet, { ButtonGroup } from "./BaseActionSheet";
import { CaretakerSelectionValues } from "./types";
import { CaretakerDetails } from "../../gql/types";
import CaretakerAddSheet from "./CaretakerAddSheet";
import CaretakerOtherConfirmSheet from "./CaretakerOtherConfirmSheet";
import noUser from "../../assets/no-user-purple.svg";
import plusIcon from "../../assets/icons/plus-round.svg";
import { DropdownOption } from "./ActionSheetDropdown";

type CaretakerSelectionActionSheetProps = {
    isVisible: boolean;
    onClose: () => void;
    onSelect: (actionValue: CaretakerSelectionValues, selectedCaretaker?: CaretakerDetails) => void;
    headerText?: string;
    preloadData?: {
        loader?: () => Promise<CaretakerDetails[]>;
        onAddCaretaker?: (name: string, contactInfo: string) => Promise<CaretakerDetails | null>;
    };
};

const CaretakerSelectionActionSheet: React.FC<CaretakerSelectionActionSheetProps> = ({
    isVisible,
    onClose,
    onSelect,
    headerText,
    preloadData,
}) => {
    const [availableCaretakers, setAvailableCaretakers] = useState<CaretakerDetails[]>(
        []
    );
    const [selectedCaretaker, setSelectedCaretaker] = useState<CaretakerDetails | null>(null);
    const [loading, setLoading] = useState(false);
    const [isAddingCaretaker, setIsAddingCaretaker] = useState(false);
    const [isConfirmingCaretaker, setIsConfirmingCaretaker] = useState(false);
    const [shouldAnimate, setShouldAnimate] = useState(true);
    const [caretakerSelectorOptions, setCaretakerSelectorOptions] = useState<DropdownOption[]>([]);

    useEffect(() => {
        if (isVisible && preloadData?.loader) {
            setLoading(true);
            preloadData.loader()
                .then((data: CaretakerDetails[]) => {
                    setAvailableCaretakers(data);
                })
                .catch(() => console.error("Failed to load caretakers"))
                .finally(() => setLoading(false));
        }
    }, [isVisible]);

    const handleCaretakerChange = (value: string) => {
        if (value === "add") {
            // Change this to open CaretakerAddSheet
            setIsAddingCaretaker(true);
        } else {
            const selected = availableCaretakers.find(
                (caretaker) => caretaker.userID === value || caretaker.name === value
            );
            if (selected) {
                setSelectedCaretaker(selected || null);
                setIsConfirmingCaretaker(true);
            }
        }
    };

    const handleAddCaretaker = async (newCaretaker: { name: string, contactInfo: string }) => {
        if (preloadData?.onAddCaretaker && preloadData?.loader) {
            const newAddedCaretaker = await preloadData.onAddCaretaker(newCaretaker.name, newCaretaker.contactInfo);
            if (newAddedCaretaker == null) {
                // Add a error handling call here
                return;
            }
            setAvailableCaretakers((prev) => [...prev, newAddedCaretaker]);
            setSelectedCaretaker(newAddedCaretaker);
        }
        setIsAddingCaretaker(false);
    };

    const handleConfirmOtherSelection = (caretaker: CaretakerDetails) => {
        onSelect(CaretakerSelectionValues.OTHER, caretaker);
        setIsConfirmingCaretaker(false);
        onClose();
    };

    const handleSelection = (actionValue: string) => {
        if (actionValue == CaretakerSelectionValues.OTHER && selectedCaretaker) {
            onSelect(actionValue, selectedCaretaker);
            return;
        }
        onSelect(actionValue as CaretakerSelectionValues);
    };

    useEffect(() => {
        const options: DropdownOption[] = availableCaretakers.map((caretaker) => ({
            label: caretaker.name,
            value: caretaker.userID || caretaker.name, // Use `userID` if available, otherwise `name`
            profilePictureUrl: caretaker.userSummary?.profilePictureUrl ?? noUser, // Default profile picture
        }));

        setCaretakerSelectorOptions(options);
        if (preloadData?.onAddCaretaker) {
            setCaretakerSelectorOptions((prev) => [
                ...prev,
                {
                    label: "Add a New Caretaker",
                    value: "add",
                    icon: plusIcon,
                    topDivider: true,
                },
            ]);
        }
    }, [availableCaretakers]); // Dependency array ensures this effect runs when `availableCaretakers` changes

    const actions: ButtonGroup[] = [
        [
            { label: "Myself", value: CaretakerSelectionValues.SELF },
            { label: "My Spouse", value: CaretakerSelectionValues.SPOUSE },
        ],
        [
            {
                label: "Childcare Professional/Others",
                value: CaretakerSelectionValues.OTHER,
                selectorOptions: caretakerSelectorOptions,
                onSelectorChange: handleCaretakerChange,
            },
        ],
        [
            { label: "No, create caretaker request", value: CaretakerSelectionValues.REQUEST },
        ],
        [
            { label: "Skip for now, remind me later", value: CaretakerSelectionValues.SKIP, onClick: onClose },
        ],
    ];

    if (!isVisible || loading) return null;

    return (
        <>
            {isAddingCaretaker ? (
                <CaretakerAddSheet
                    isVisible={isAddingCaretaker}
                    onClose={() => { setIsAddingCaretaker(false); setShouldAnimate(false); }}
                    onSave={handleAddCaretaker}
                />
            ) : isConfirmingCaretaker && selectedCaretaker ? (
                <CaretakerOtherConfirmSheet
                    isVisible={isConfirmingCaretaker}
                    caretakers={availableCaretakers}
                    selectedCaretaker={selectedCaretaker}
                    onClose={() => setIsConfirmingCaretaker(false)}
                    onConfirm={handleConfirmOtherSelection}
                    onBack={() => { setIsConfirmingCaretaker(false); setShouldAnimate(false); }}
                    onCaretakerChange={(value) => {
                        const caretaker = availableCaretakers.find(
                            (c) => c.userID === value || c.name === value
                        );
                        if (caretaker) setSelectedCaretaker(caretaker);
                    }}
                />
            ) : (
                <BaseActionSheet
                    isVisible={isVisible}
                    onClose={onClose}
                    onSelect={handleSelection}
                    headerText={headerText || "Who’s handling childcare?"}
                    actions={actions}
                    animate={shouldAnimate}
                />
            )}
        </>
    );
};

export default CaretakerSelectionActionSheet;
