import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

const ActionRedirectScreen: React.FC = () => {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const mode = params.get("mode"); // Firebase action type
        const oobCode = params.get("oobCode"); // One-time operation code

        console.log(params);

        if (!mode || !oobCode) {
            navigate(`/auth/login?error=true&message=${encodeURIComponent("Invalid Access.")}`);
            return;
        }

        // Redirect to the appropriate page
        switch (mode) {
            case "verifyEmail":
                navigate(`/auth/email-verified?oobCode=${oobCode}&mode=${mode}`);
                break;
            case "resetPassword":
                navigate(`/auth/reset-password?oobCode=${oobCode}&mode=${mode}`);
                break;
            case "recoverEmail":
                navigate(`/auth/recover-email?oobCode=${oobCode}&mode=${mode}`);
                break;
            default:
                navigate(`/?error=true&message=${encodeURIComponent("Unsupported action.")}`);
        }
    }, [navigate, location]);

    return <p>Redirecting...</p>; // Optional: Show a loading spinner
};

export default ActionRedirectScreen;
