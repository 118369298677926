import React from "react";
import styles from "./CaretakerRequestRecipientView.module.css";
import DigestHeader from "../digest/DigestHeader";
import MainEventItem from "../digest/MainEventItem";
import ProfileDetailRow from "../profile/ProfileDetailRow";
import TimeRangeRowView from "../time/TimeRangeRowView";
import purpleLogo from "../../assets/icons/purple-logo.svg";
import Layout from "../layout/Layout";
import { CaretakerRequest } from "../../gql/types";
import { AttentionLevelType } from "../../gql/types";
import noUser from "../../assets/no-user-purple.svg";

type CaretakerRequestRecipientViewProps = {
    request: CaretakerRequest;
    onRespond: () => void; // Confirm handler
};

const CaretakerRequestRecipientView: React.FC<CaretakerRequestRecipientViewProps> = ({
    request,
    onRespond,
}) => {
    const itemHeader = request.requestContext?.contextHeader;
    const attentionLevel = request.requestContext?.contextHeader?.attentionLevel ?? AttentionLevelType.Informational;
    const eventDetails = request.requestContext?.eventDetails;
    const eventItem = {
        "title": eventDetails?.title ?? "",
        "startDate": eventDetails?.startDate ?? "",
        "endDate": eventDetails?.endDate ?? "",
        "eventID": "placeholderID",
    }

    return (
        <Layout
            overflow="auto" // Allow scrolling if content exceeds screen height
        >
            <div className={styles.recipientViewContainer}>
                <DigestHeader
                    topTitle={itemHeader?.topTitle ?? ""}
                    mainTitle={itemHeader?.mainTitle ?? ""}
                    attentionLevel={attentionLevel}
                    description={itemHeader?.description ?? undefined}
                />

                {eventDetails &&
                    <MainEventItem event={eventItem} isEventInteractable={false} />}
                <div className={styles.bottomSheetContainer}>
                    <div className={styles.bottomSheet}>
                        <div className={styles.iconContainer}>
                            <img src={purpleLogo} className={styles.confirmationIcon} />
                        </div>
                        <div className={styles.infoSection}>
                            Please review the request and respond!
                        </div>
                        <div className={styles.sectionHeader}>From:</div>
                        <ProfileDetailRow
                            name={request.requester.firstName + " " + request.requester.lastName}
                            profilePictureUrl={request.requester.profilePictureUrl ?? noUser}
                        />

                        <div className={styles.section}>
                            <div className={styles.sectionHeader}>Start & End Time:</div>
                            <TimeRangeRowView
                                startTime={request.timeRange.startDate}
                                endTime={request.timeRange.endDate}
                            />
                        </div>

                        <div className={styles.sectionHeader}>Notes/Message:</div>
                        <div className={styles.notes}>
                            {request.message || <em>No message provided</em>}
                        </div>
                        <div className={styles.buttonContainer}>
                            <button
                                className={styles.submitButton}
                                onClick={onRespond}
                            >
                                Respond
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Layout >
    );
};

export default CaretakerRequestRecipientView;
