import React from 'react';
import styles from './DigestViewFooter.module.css';
import listViewIcon from '../../assets/icons/listview.svg';
import stackViewIcon from '../../assets/icons/stackview.svg';
import PreviousCardIcon from '../../assets/icons/previous-card.svg';
import { useDigestView } from './DigestViewContext';
import { FooterButtonPosition } from './types';

export const DigestViewFooter: React.FC<{
    enablePrevButton: boolean;
    onPrevCard: () => void;
    onSwitchView: () => void;
    isListView: boolean;
    loading: boolean;
}> = ({ enablePrevButton, onPrevCard, onSwitchView, isListView, loading }) => {
    const { footerButtons } = useDigestView();

    return (
        <div className={styles.footerContainer}>
            {/* Left Button */}
            {footerButtons[FooterButtonPosition.LEFT] ? (
                <div className={styles.actionButton}>{footerButtons[FooterButtonPosition.LEFT]}</div>
            ) : (
                <button onClick={onPrevCard} className={styles.actionButton} disabled={loading || !enablePrevButton}>
                    <img src={PreviousCardIcon} alt="Previous" />
                </button>
            )}

            {/* Middle Button */}
            {footerButtons[FooterButtonPosition.MIDDLE] && <div className={styles.actionButton}>{footerButtons[FooterButtonPosition.MIDDLE]}</div>}

            {/* Right Button */}
            {footerButtons[FooterButtonPosition.RIGHT] ? (
                <div className={styles.actionButton}>{footerButtons[FooterButtonPosition.RIGHT]}</div>
            ) : (
                <button onClick={onSwitchView} className={styles.actionButton} disabled={loading}>
                    {isListView ? (
                        <img src={stackViewIcon} alt="Stack View" />
                    ) : (
                        <img src={listViewIcon} alt="List View" />
                    )}
                </button>
            )}
        </div>
    );
};
