import React, { createContext, useContext, useState, ReactNode } from "react";

type LayoutContextType = {
    isOverlayVisible: boolean;
    showOverlay: () => void;
    hideOverlay: () => void;
};

const LayoutContext = createContext<LayoutContextType | undefined>(undefined);

export const LayoutProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isOverlayVisible, setIsOverlayVisible] = useState(false);

    const showOverlay = () => setIsOverlayVisible(true);
    const hideOverlay = () => setIsOverlayVisible(false);

    return (
        <LayoutContext.Provider value={{ isOverlayVisible, showOverlay, hideOverlay }}>
            {children}
        </LayoutContext.Provider>
    );
};

export const useLayout = (): LayoutContextType => {
    const context = useContext(LayoutContext);
    if (!context) {
        throw new Error("useLayout must be used within a LayoutProvider");
    }
    return context;
};
