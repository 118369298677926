import React, { useState, useEffect } from 'react';
import OnboardingStep, { OnboardingContext } from './OnboardingStep';
import styles from './PartnerEmailStep.module.css';
import { useNavigate } from 'react-router-dom';
import TextInputField, { TextInputFieldStyle, TextInputFieldType } from '../input/TextInputField';

interface PartnerEmailStepProps {
    onboardingContext: OnboardingContext;
    onNext: (partnerEmail: string) => void;
    onSkip?: () => void;
    existingValue?: string;
}

const PartnerEmailStep: React.FC<PartnerEmailStepProps> = ({ onboardingContext, onNext, onSkip, existingValue }) => {
    const [email, setEmail] = useState<string | undefined>(existingValue);
    const navigate = useNavigate();
    const [buttonEnabled, setButtonEnabled] = useState(false); // Tracks button state
    const [isEmailValid, setIsEmailValid] = useState(false);

    // Update button state whenever count changes
    useEffect(() => {
        setButtonEnabled(isEmailValid); // Ensure button is enabled only for valid counts
    }, [email]);

    const handleContinue = () => {
        if (!email) {
            navigate(`?error=true&message=${encodeURIComponent('Please enter valid email address')}`);
            return;
        }
        onNext(email);
    };

    return (
        <OnboardingStep
            onboardingContext={onboardingContext}
            title="Please add your partner to keep your family in sync."
            subtitle="If your partner is already on Plii, we’ll handle the family details for you."
            onContinue={handleContinue}
            buttonEnabled={buttonEnabled}
            onSkip={onSkip}
        >
            <div className={styles.container}>
                <TextInputField
                    type={TextInputFieldType.EMAIL}
                    placeholder="Partner's Email"
                    style={TextInputFieldStyle.DEFAULT}
                    value={email ?? ""}
                    onChange={(value) => setEmail(value)}
                    onValidChange={(isValid) => setIsEmailValid(isValid)}
                />
            </div>
        </OnboardingStep>
    );
};

export default PartnerEmailStep;
