import React from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './Login.module.css';
import { AUTH_TYPE, handleLoginWithGoogle } from '../../google/loginHandler';
import ActionButton from '../button/ActionButton';
import Overlay from '../layout/Overlay';
import Layout from '../layout/Layout';
import primaryLogo from '../../assets/primary-logo.png';

interface LoginProps {
  loadingUser: boolean;
}

const Login: React.FC<LoginProps> = ({ loadingUser }) => {
  const navigate = useNavigate();
  const handleSignUpWithEmail = () => {
    // Handle email signup logic
    navigate('/signup');
  };

  const handleLogIn = () => {
    // Handle existing user login logic
    navigate('/auth/login/existing');
  };

  return (
    <>
      {loadingUser && <Overlay visible={loadingUser} />}
      <Layout
        overflow={'hidden'}
        footer={(
          <div className={styles.buttonContainer}>
            <ActionButton onClick={handleSignUpWithEmail}>
              Sign up with Email
            </ActionButton>
            <ActionButton iconType='Google' onClick={() => handleLoginWithGoogle(AUTH_TYPE.LOGIN, "/")} hasDataLoad={true}>
              Sign up with Google
            </ActionButton>
            <ActionButton colorPattern='Light Brown' onClick={handleLogIn}>
              Log in
            </ActionButton>
          </div>
        )}
        contentClassName={styles.loginPage}
      >
        <div className={styles.logoContainer}>
          <img src={primaryLogo} alt="Plii Logo" className={styles.logo} />
          <h2 className={styles.tagline}>Scheduling Assistant Made for Parents</h2>
        </div>
        <div className={styles.termsContainer}>
          <p className={styles.termsText}>
            By signing up, you agree to Plii's <a href="/terms-and-conditions" className={styles.linkDecoration}>Terms & Conditions</a> and <a href="/privacy-policy" className={styles.linkDecoration}>Privacy Policy</a>.
          </p>
        </div>
      </Layout>
    </>
  );
}

export default Login;
