import React, { useState, useEffect } from "react";
import CaretakerRequestCreateView from "../events/CaretakerRequestCreateView";
import { User, CaretakerDetails } from "src/gql/types";
import { useDigestView } from "./DigestViewContext";
import { CaretakerRequestDetails } from "../events/types";

const DigestViewCaretakerRequestCreateViewWrapper: React.FC<{ user: User }> = ({ user }) => {
    const { caretakerRequestConfig, hideCaretakerRequest, fetchCaretakers, showCaretakerConfirmation } = useDigestView();
    const [localCaretakers, setLocalCaretakers] = useState<CaretakerDetails[]>([]);
    const [isVisible, setIsVisible] = useState<boolean>(false);

    useEffect(() => {
        if (!caretakerRequestConfig.isVisible) {
            setIsVisible(false);
            return;
        }

        // Fetch caretakers if not already loaded
        const loadCaretakers = async () => {
            if (localCaretakers.length === 0) {
                const fetchedCaretakers = await fetchCaretakers();
                setLocalCaretakers(fetchedCaretakers);
            }
        };
        loadCaretakers();
        setIsVisible(true);
    }, [fetchCaretakers, caretakerRequestConfig.isVisible]);

    const handleRequestSubmit = (details: CaretakerRequestDetails) => {
        showCaretakerConfirmation(details, caretakerRequestConfig.item, caretakerRequestConfig.event);
    };

    return (
        <>
            {isVisible &&
                caretakerRequestConfig.event &&
                caretakerRequestConfig.item && <CaretakerRequestCreateView
                    event={caretakerRequestConfig.event}
                    digestItem={caretakerRequestConfig.item}
                    user={user}
                    caretakers={localCaretakers}
                    onBack={hideCaretakerRequest}
                    onSubmit={handleRequestSubmit}
                    caretakerRequestDetails={caretakerRequestConfig.caretakerRequestDetails}
                />}
        </>
    );
};

export default DigestViewCaretakerRequestCreateViewWrapper;
