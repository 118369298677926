import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import OnboardingStep from './OnboardingStep';
import { OnboardingContext } from './OnboardingStep';
import styles from './NameStep.module.css';
import { SAVE_USER_PROFILE } from '../../graphql/mutations/saveUserProfile';
import { LOCAL_MODE } from '../../config/local';
import { useNavigate } from 'react-router-dom';
import NameInputContainer from '../input/NameInputContainer';

interface NameStepProps {
  onboardingContext: OnboardingContext;
  onNext: (firstName: string, lastName: string) => void;
  existingValues?: { firstName: string; lastName: string };
}

const NameStep: React.FC<NameStepProps> = ({ onboardingContext, onNext, existingValues }) => {
  const [firstName, setFirstName] = useState(existingValues?.firstName || '');
  const [lastName, setLastName] = useState(existingValues?.lastName || '');
  const navigate = useNavigate();
  const [isValidFields, setIsValidFields] = useState(false);

  const [saveUserProfile, { loading }] = useMutation(SAVE_USER_PROFILE, {
    onCompleted: () => {
      onNext(firstName, lastName); // Move to the next step after successful mutation
    },
    onError: (error) => {
      console.error('Failed to save user profile:', error);
      navigate(`?error=true&message=${encodeURIComponent('Failed to save your profile. Please try again.')}`);
    },
  });

  const handleContinue = () => {
    if (LOCAL_MODE) {
      setTimeout(() => {
        onNext(firstName, lastName);
      }, 500); // 2-second delay for loading UI observation
      return;
    }

    if (firstName.trim() && lastName.trim()) {
      saveUserProfile({
        variables: {
          input: {
            firstName,
            lastName,
          },
        },
      });
    } else {
      navigate(`?error=true&message=${encodeURIComponent('Please fill out both fields.')}`);
    }
  };

  return (
    <OnboardingStep
      onboardingContext={onboardingContext}
      title="Great to see you here!"
      subtitle="What is your name?"
      buttonEnabled={isValidFields}
      onContinue={handleContinue}
      hasDataLoad={true}
    >
      <NameInputContainer
        firstName={firstName}
        lastName={lastName}
        firstNameSetter={setFirstName}
        lastNameSetter={setLastName}
        onNameValidChange={setIsValidFields}
      />
    </OnboardingStep>
  );
};

export default NameStep;
