import { ApolloLink, Observable } from "@apollo/client";
import { OperationDefinitionNode } from "graphql";
import { LOCAL_MODE } from "src/config/local";
import { CalendarConnectionInput, SyncType } from "src/gql/types";
import { generateMockCaretakerDetails } from "src/mock/caretakerDetails";
import { generateMockCaretakerRequestWithFields, getMockCaretakerRequest } from "src/mock/createCaretakerRequest";
import { generateMockAcceptedResponse, generateMockCaretakerResponseWithFields, generateResponseWithResponseType } from "src/mock/createCaretakerResponse";
import { generateMockDigest } from "src/mock/generateDigest";
import { generateMockCalendarConnections, mockRemovedData } from "src/mock/getCalendarConnections";
import { generateMockCalendarsList } from "src/mock/getCalendarsList";
import { generateMockSyncStatus } from "src/mock/getSyncStatus";
import { generateMockUser } from "src/mock/getUser";

export const mockGraphQLLink = new ApolloLink((operation, forward) => {
    if (!LOCAL_MODE) {
        return forward ? forward(operation) : new Observable(() => { });
    }

    return new Observable(observer => {
        const operationName = (operation.query.definitions.find(
            (def) => def.kind === "OperationDefinition"
        ) as OperationDefinitionNode)?.name?.value;

        if (!operationName) {
            console.warn("MockLink: No operation name found");
            if (forward) return forward(operation).subscribe(observer); // Use subscribe instead of returning forward
            return () => { }; // Always return a cleanup function
        }

        console.log(` MockLink intercepted: ${operationName}`, operation.variables);

        let response: { data: Record<string, any> } | null = null;

        switch (operationName) {
            case "GetSyncStatus":
                const syncType = operation.variables?.syncType;
                response = {
                    data: {
                        getSyncStatus: generateMockSyncStatus(syncType ?? SyncType.Initial),
                    }
                }
                break;
            case "GenerateDigest":
                response = {
                    data: {
                        generateDigest: generateMockDigest(),
                    }
                };
                break;

            case "GetUser":
                response = {
                    data: {
                        getUser: generateMockUser(),
                    }
                }
                break;

            case "GetCaretakerDetails":
                response = {
                    data: {
                        getCaretakerDetails: generateMockCaretakerDetails(),
                    }
                }
                break;

            case "CreateCaretakerRequest":
                const requestInput = operation.variables?.input;
                response = {
                    data: {
                        createCaretakerRequest: generateMockCaretakerRequestWithFields(requestInput),
                    }
                };
                break;

            case "CreateCaretakerResponse":
                const responseInput = operation.variables?.input;
                response = {
                    data: {
                        createCaretakerResponse: generateMockCaretakerResponseWithFields(responseInput),
                    }
                };
                break;

            case "UpdateCaretakerResponse":
                const updateResponseInput = operation.variables?.input;
                response = {
                    data: {
                        updateCaretakerResponse: generateResponseWithResponseType(updateResponseInput?.newResponseType),
                    }
                };
                break;

            case "GetCaretakerRequest":
                const token = operation.variables?.token;
                const request = getMockCaretakerRequest(token);
                const caretakerResponse = generateMockAcceptedResponse();
                response = {
                    data: {
                        getCaretakerRequest: {
                            ...request,
                            filteredResponses: request ? [caretakerResponse] : [],
                        }
                    }
                };
                break;

            case "GetCalendarConnections":
                response = {
                    data: {
                        getCalendarConnections: generateMockCalendarConnections(),
                    }
                }
                break;

            case "RemoveCalendarConnection":
                const removeConnInput = operation.variables?.calendar as CalendarConnectionInput;

                response = {
                    data: {
                        removeCalendarConnection: {
                            success: true,
                        }
                    },
                };
                mockRemovedData.push({
                    __typename: 'CalendarConnection',
                    source: removeConnInput.source,
                    identifier: removeConnInput.identifier,
                    label: removeConnInput.label,
                    expired: false,
                });
                break;

            case "AddExternalCalendars":
                response = {
                    data: {
                        addExternalCalendars: {
                            success: true,
                        }
                    }
                };
                break;

            case "GetCalendarsList":
                response = {
                    data: {
                        getUserExternalCalendarList: generateMockCalendarsList(),
                    }
                }
                break;

            default:
                console.warn(`No mock response defined for: ${operationName}`);
                if (forward) return forward(operation).subscribe(observer); // Ensure forward calls subscribe
                return () => { }; // Always return a valid function
        }

        // ✅ Ensure response is never null before calling `observer.next()`
        setTimeout(() => {
            if (response) {
                observer.next(response); // ✅ TypeScript now knows `response` is always defined
                observer.complete();
            } else {
                console.error(`🚨 MockLink Error: response is null for operation ${operationName}`);
            }
        }, 200);

        // ✅ Return a cleanup function to satisfy the expected type
        return () => console.log(`MockLink completed: ${operationName}`);
    });
});
