const ENV = process.env.REACT_APP_ENV;

if (!ENV && process.env.NODE_ENV === "production") {
    throw new Error("Missing REACT_APP_ENV. Use `npm run build:local` or `npm run build:prod`.");
}

export const LOCAL_MODE = process.env.REACT_APP_LOCAL_MODE === "true";
export const SKIP_ONBOARDING = process.env.REACT_APP_SKIP_ONBOARDING === "true";
export const SHOW_DIGEST_FLOW = process.env.REACT_APP_SHOW_DIGEST_FLOW === "true";
export const SKIP_DATA_SYNC = process.env.REACT_APP_SKIP_DATA_SYNC === "true";
export const SHOW_DATA_LOADING_SCREEN = process.env.REACT_APP_SHOW_DATA_LOADING_SCREEN === "true";
export const SHOW_SPLASH_SCREEN = process.env.REACT_APP_SHOW_SPLASH_SCREEN === "true";
export const SHOW_LOGIN_SCREEN = process.env.REACT_APP_SHOW_LOGIN_SCREEN === "true";

if (process.env.NODE_ENV === "production" && LOCAL_MODE) {
    throw new Error("LOCAL_MODE should not be enabled in production!");
}