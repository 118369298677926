import React from 'react';
import styles from './ActionSheet.module.css';
import closeButton from '../../assets/icons/close.svg';

interface ActionSheetCloseProps {
    onClose: () => void; // Function to handle the close action
}

const ActionSheetClose: React.FC<ActionSheetCloseProps> = ({ onClose }) => {
    return (
        <button className={styles.closeButton} onClick={onClose}>
            <img src={closeButton} />
        </button>
    );
};

export default ActionSheetClose;
