import React, { useState } from "react";
import Layout from "../layout/Layout";
import styles from "./NavigationMenu.module.css";
import { User } from "src/gql/types";
import ProfileMenuRow from "../profile/ProfileMenuRow";
import ProfileView from "../profile/ProfileView";
import { truncate } from "lodash";

interface NavigationMenuProps {
    onCloseMenu: () => void;
    user?: User;
    isHidden?: boolean;
}

const NavigationMenu: React.FC<NavigationMenuProps> = ({ onCloseMenu, user, isHidden }) => {
    const [profileViewVisible, setProfileViewVisible] = useState<boolean>(false);

    return (
        <>
            <Layout
                navbarConfig={{ title: "This is Menu", showLogo: true, hamburgerMenu: false, onBack: onCloseMenu, useCloseButton: true }}
                footer={(
                    <>
                        {user && <ProfileMenuRow
                            name={user.firstName + " " + user.lastName}
                            profilePictureUrl={user.profilePictureUrl ?? undefined}
                            onClick={() => setProfileViewVisible(true)}
                        />}
                    </>
                )
                }
                overflow="hidden"
                noFooterPadding={true}
                footerPaddingClassName={styles.fillPaddingProfileRow}
                isHidden={profileViewVisible || isHidden}
            >
                <></>
                {/* <div className={styles.navigationMenu}>
                <div className={styles.menuSection}>
                    <div className={styles.menuCategory}>Digest</div>
                    <div className={styles.menuItem} onClick={() => navigate("/digest")}>
                        Digest
                    </div>
                </div>

                <div className={styles.menuSection}>
                    <div className={styles.menuCategory}>Planning</div>
                    <div className={styles.menuItem} onClick={() => navigate("/planning/childcare")}>
                        Childcare Request
                    </div>
                    <div className={styles.menuItem} onClick={() => navigate("/planning/event")}>
                        Coordinate Event
                    </div>
                    <div className={styles.menuItem} onClick={() => navigate("/planning/activity")}>
                        Available Open Activity
                    </div>
                </div>
            </div> */}
            </Layout >
            {user && <ProfileView user={user} onBack={() => setProfileViewVisible(false)} isHidden={!profileViewVisible} />}
        </>
    );
};

export default NavigationMenu;
