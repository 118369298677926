import React from "react";
import Select from "react-select";
import styles from "./TimeRow.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { TimeSelectionRow } from "./types";

interface TimeOption {
    value: string; // Machine-readable value (e.g., "03:00")
    label: string; // Human-readable label (e.g., "03:00 AM")
}

interface TimeRowProps {
    id: number;
    date: string;
    startTime: string;
    endTime: string;
    timezone: string; // New timezone prop
    onRowChange: (id: number, field: keyof TimeSelectionRow, value: string) => void;
    timeOptions: TimeOption[];
    enableDateSelection?: boolean;
}

const TimeRow: React.FC<TimeRowProps> = ({
    id,
    date,
    startTime,
    endTime,
    timezone,
    onRowChange,
    timeOptions,
    enableDateSelection = false,
}) => {
    const extractTime = (datetime: string) => {
        const formatter = new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
            timeZone: timezone, // Use the specified timezone
        });

        const dateObj = new Date(datetime);
        const parts = formatter.formatToParts(dateObj);

        const hour = parts.find((part) => part.type === "hour")?.value.padStart(2, "0");
        const minute = parts.find((part) => part.type === "minute")?.value.padStart(2, "0");

        const formattedTime = `${hour}:${minute}`;
        return formattedTime;
    };

    // Extract HH:mm from startTime and endTime
    const startTimeValue = startTime.length > 0 ? extractTime(startTime) : "";
    const endTimeValue = endTime.length > 0 ? extractTime(endTime) : "";

    const customSelectStyles = {
        control: (base: any, state: any) => ({
            ...base,
            backgroundColor: state.isFocused ? "rgba(47, 28, 10, 0.1)" : "#fff",
            color: "#2F1C0A",
            border: "none", // No visible border
            borderRadius: "0.5rem",
            boxShadow: "none", // Gray box on focus
            padding: "0.1rem 0",
            fontSize: "0.875rem",
            width: "100%",
            boxSizing: "border-box",
        }),
        singleValue: (base: any) => ({
            ...base,
            marginLeft: "0", // Reduce left padding
            marginRight: "0", // Reduce right padding
            padding: "0",
        }),
        option: (base: any, { isFocused }: any) => ({
            ...base,
            backgroundColor: isFocused ? "#2F1C0A" : "#fff",
            color: isFocused ? "#fff" : "#2F1C0A",
            padding: "0.5rem",
            cursor: "pointer",
        }),
        menu: (base: any) => ({
            ...base,
            borderRadius: "0.5rem",
        }),
        dropdownIndicator: (base: any) => ({
            display: "none", // This hides the down arrow
        }),
        indicatorSeparator: () => ({
            display: "none", // This hides the separator line next to the down arrow
        }),
        input: (base: any) => ({
            ...base,
            caretColor: "transparent", // Hide the blinking text cursor
            margin: "0", // Remove margins
            padding: "0", // Remove padding
        }),
    };
    return (
        <div className={styles.timeContainer}>
            {enableDateSelection && (
                <div className={styles.datePickerContainer}>
                    <DatePicker
                        selected={date ? new Date(`${date}T00:00:00`) : null}
                        onChange={(selectedDate) => {
                            if (selectedDate) {
                                // Format the date manually to YYYY-MM-DD without timezone
                                const year = selectedDate.getFullYear();
                                const month = (selectedDate.getMonth() + 1).toString().padStart(2, "0");
                                const day = selectedDate.getDate().toString().padStart(2, "0");

                                const dateWithoutTimezone = `${year}-${month}-${day}`;
                                onRowChange(id, "date", dateWithoutTimezone);
                            }
                        }}
                        onClickOutside={() => {
                            const activeElement = document.activeElement as HTMLElement | null;
                            activeElement?.blur(); // Close the picker
                        }}
                        onSelect={() => {
                            const activeElement = document.activeElement as HTMLElement | null;
                            activeElement?.blur(); // Close the picker after date selection
                        }}
                        dateFormat="EEE, MMM d" // Format as "Tue, Dec 7"
                        placeholderText="Select Date"
                        popperPlacement="bottom-end" // Align popup to the right
                        className={styles.dateInput}
                        popperClassName={styles.datePickerPopper}
                    />
                </div>
            )}
            <Select<TimeOption>
                className={styles.timeInput}
                styles={customSelectStyles}
                options={timeOptions}
                placeholder="Start Time"
                onChange={(option) =>
                    onRowChange(id, "startTime", option?.value || "")
                }
                value={timeOptions.find((option) => option.value === startTimeValue)}
            />
            <span className={styles.timeDivider}>to</span>
            <Select<TimeOption>
                className={styles.timeInput}
                styles={customSelectStyles}
                options={timeOptions}
                placeholder="End Time"
                onChange={(option) =>
                    onRowChange(id, "endTime", option?.value || "")
                }
                value={timeOptions.find((option) => option.value === endTimeValue)}
            />
        </div>
    );
};

export default TimeRow;
